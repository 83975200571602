import type { AxisScaleOutput } from "@visx/axis";
import type { ScaleConfig } from "@visx/scale";
import { ReactNode, lazy } from "react";

import { buildDynamicImportError } from "../data";
import type { ChartProps, SeriesProps } from "./chart.lazy";

export const Chart = lazy(async () => {
  try {
    const { Chart } = await import("./chart.lazy");
    return { default: Chart };
  } catch (error) {
    throw buildDynamicImportError(error);
  }
}) as <
  XScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  YScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  Datum extends object,
>(
  props: ChartProps<XScaleConfig, YScaleConfig, Datum>,
) => ReactNode;

export type { ChartProps, SeriesProps };
