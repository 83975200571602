import { Textarea, TextareaProps } from "@headlessui/react";
import { IconExclamationCircle } from "@tabler/icons-react";
import clsx from "clsx";

import { specialChars } from "@joy/shared-utils";

import { TextInputProps, inputKinds, inputParts, inputVariants } from "./text";

export const TextAreaInput = ({
  className,
  icon: Icon,
  addon,
  kind = "standard",
  variant = "action",
  ...props
}: TextInputProps & TextareaProps) => {
  return (
    <label
      className={clsx(
        inputKinds[kind],
        inputVariants[variant],
        props.invalid && inputVariants.error,
        className,
      )}
    >
      {Icon && <Icon className={inputParts.icon} />}
      {addon && <div className={inputParts.addon}>{addon}</div>}
      <div className={"grid w-full border-0 bg-transparent px-0 py-2"}>
        <Textarea
          className={clsx(
            "border-0 bg-transparent focus:ring-0 focus:outline-hidden",
            "resize-none appearance-none [grid-area:1/1/2/2]",
          )}
          {...props}
        />
        <pre className="invisible break-all whitespace-pre-wrap [grid-area:1/1/2/2]">
          {props.value}
          {specialChars.nbsp}
        </pre>
      </div>
      {props.invalid && (
        <IconExclamationCircle
          className={clsx(inputParts.icon, "text-red-700")}
        />
      )}
    </label>
  );
};
