import { ComboField, SlideContent, TextField } from "../../components";
import {
  GaugeEditContentProps,
  account,
  customer,
  customerAccountsQuery,
  customersQuery,
  useAuth,
  useUpdateGauge,
} from "../../data";
import { useComboQuery } from "../../hooks";

const GaugeEditAccount = ({
  customerId,
  form,
  validators,
}: GaugeEditContentProps & { customerId: string }) => {
  const accounts = useComboQuery(customerAccountsQuery(customerId), {
    filter: (o) => o.product === "JoyGauge",
    word: account,
  });

  return (
    <form.Field
      name="account"
      validators={{
        ...validators.account,
        onChangeListenTo: ["customer"],
        onChangeAsync: async ({ value, fieldApi }) => {
          const customer = fieldApi.form.getFieldValue("customer");
          if (value?.customerId !== customer?.id) {
            fieldApi.setValue(null);
          }

          return undefined;
        },
      }}
      children={(field) => (
        <ComboField
          field={field}
          label="Account"
          immediate
          accessors={[(o) => o.name]}
          optionLabel={(o) => o.name}
          optionKey={(o) => o.id}
          {...accounts.combo}
        />
      )}
    />
  );
};

export const GaugeEditContent = ({ gaugeId }: { gaugeId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { data, error, form, validators } = useUpdateGauge(gaugeId);
  const customers = useComboQuery(customersQuery(hasTeamPermission("admin")), {
    word: customer,
  });

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the gauge information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="name"
        validators={validators.name}
        children={(field) => (
          <TextField field={field} label="Gauge name" autoFocus />
        )}
      />
      {hasTeamPermission("admin") && (
        <form.Field
          name="customer"
          validators={validators.customer}
          children={(field) => (
            <ComboField
              field={field}
              label="Customer"
              accessors={[(o) => o.name]}
              optionKey={(o) => o.id}
              optionLabel={(o) => o.name}
              immediate
              {...customers.combo}
            />
          )}
        />
      )}
      <form.Subscribe
        selector={(s) => s.values.customer?.id}
        children={(customerId) =>
          customerId ? (
            <GaugeEditAccount
              customerId={customerId}
              form={form}
              validators={validators}
            />
          ) : null
        }
      />
    </SlideContent>
  );
};
