export const downloadDataUrl = (dataUrl: string, name: string) => {
  const anchor = document.createElement("a");
  anchor.href = dataUrl;
  anchor.download = name;

  document.body.appendChild(anchor);
  anchor.click();
  setTimeout(() => {
    document.body.removeChild(anchor);
  }, 0);
};

export const downloadFile = (file: Blob, name: string) => {
  const url = URL.createObjectURL(file);

  downloadDataUrl(url, name);
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 0);
};
