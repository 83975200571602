import { useQuery } from "@tanstack/react-query";
import { Link, LinkProps, MatchRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { ReactNode } from "react";

import { tw } from "@joy/shared-utils";

import {
  RolePermission,
  TeamPermission,
  permissionsQuery,
  useAuth,
} from "../../data";

export const sidebarItem = tw`flex cursor-pointer items-center gap-x-2 overflow-hidden rounded-md p-2 text-gray-400 transition hover:bg-emerald-900 hover:text-gray-100 aria-page:bg-emerald-900 aria-page:text-gray-100`;
export const subItem = tw`ml-2 aria-hidden:hidden`;

type PageIcon = (props: { className: string; stroke?: number }) => ReactNode;

export type Page = LinkProps & {
  Icon: PageIcon;
  label: string;
  teamPermission?: TeamPermission;
  rolePermission?: RolePermission;
  subPages?: (LinkProps & { Icon: PageIcon; label: string })[];
};

export const SidebarItem = ({
  Icon,
  label,
  teamPermission,
  rolePermission,
  subPages,
  ...props
}: Page) => {
  const { hasTeamPermission } = useAuth();
  const { data: permissions } = useQuery(permissionsQuery());

  if (teamPermission && !hasTeamPermission(teamPermission)) return null;
  if (rolePermission && !permissions?.[rolePermission]) return null;

  return (
    <>
      <Link {...props} className={sidebarItem}>
        <Icon className="size-6 flex-none" aria-label={label} />
        <span className="truncate text-sm font-semibold">{label}</span>
      </Link>
      <MatchRoute
        fuzzy
        to={props.to}
        children={(match) =>
          subPages?.map(({ Icon, label, ...props }) => (
            <Link
              key={label}
              {...props}
              aria-hidden={!match}
              className={clsx(sidebarItem, subItem)}
            >
              <Icon className="size-4 flex-none" aria-label={label} />
              <span className="truncate text-xs font-semibold">{label}</span>
            </Link>
          ))
        }
      />
    </>
  );
};
