import { brandIcons } from "../../assets";
import {
  ComboField,
  RadioField,
  SlideContent,
  TextField,
} from "../../components";
import {
  customer,
  customersQuery,
  plan,
  plansQuery,
  useCreateAccount,
} from "../../data";
import { useComboQuery } from "../../hooks";

export const AccountCreateContent = ({ entityId }: { entityId?: string }) => {
  const customers = useComboQuery(customersQuery(!entityId), {
    word: customer,
  });
  const plans = useComboQuery(plansQuery(), { word: plan });
  const { error, form, validators } = useCreateAccount({
    customerId: entityId,
  });

  return (
    <SlideContent
      title="Add account"
      description="Get started by filling in the information below to create your new acccount."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Creating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Create", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="name"
        validators={validators.name}
        children={(field) => (
          <TextField field={field} label="Account name" autoFocus />
        )}
      />
      {!entityId && (
        <form.Field
          name="customer"
          validators={validators.customer}
          children={(field) => (
            <ComboField
              field={field}
              label="Customer"
              accessors={[(o) => o.name]}
              optionKey={(o) => o.id}
              optionLabel={(o) => o.name}
              immediate
              {...customers.combo}
            />
          )}
        />
      )}
      <form.Field
        name="plan"
        validators={validators.plan}
        children={(field) => (
          <ComboField
            field={field}
            label="Plan"
            accessors={[(o) => [o.code, o.description || o.code]]}
            optionKey={(o) => o.id}
            optionLabel={(o) =>
              `${o.code}${o.description ? ` (${o.description})` : ""}`
            }
            immediate
            {...plans.combo}
          />
        )}
      />
      <form.Field
        name="product"
        validators={validators.product}
        children={(field) => (
          <RadioField
            field={field}
            label="Product"
            options={validators.product.onSubmit._def.values}
            optionLabel={(o) => (
              <div className="flex items-center gap-3 saturate-0 group-hover/radio:saturate-100 group-data-checked/radio:saturate-100">
                <img
                  className="h-5 w-7 object-contain"
                  src={o ? brandIcons[o] : ""}
                  alt=""
                />
                <span>{o}</span>
              </div>
            )}
          />
        )}
      />
    </SlideContent>
  );
};
