import { IconKey, IconMail, IconUserScreen } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { userName } from "@joy/shared-utils";

import { backgrounds } from "../../../assets";
import { ItemHeader, NotFound, Tabs, pageParts } from "../../../components";
import { singleItemLoader, userQuery } from "../../../data";
import {
  ApiKeysPanel,
  UserActionPanel,
  UserEmailsPanel,
  UserInfoPanel,
  UserRolesPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["add", "edit", "delete", "impersonate"]).optional(),
  entity: z.enum(["role", "apiKey"]).optional(),
  role: z.string().optional(),
  apiKey: z.string().optional(),
});

export const Route = createFileRoute("/_admin/users/$userId")({
  validateSearch,
  loader: async ({ context, params }) =>
    singleItemLoader(context.queryClient, userQuery(params.userId)),
  head: ({ loaderData }) => ({ meta: [{ title: userName(loaderData) }] }),
  component: Component,
  notFoundComponent: () => (
    <NotFound
      item="User"
      action={{ to: "/users", search: { action: "invite" } }}
      actionLabel="Invite user"
    />
  ),
});

export function Component() {
  const userId = Route.useParams({ select: (p) => p.userId });
  const { data } = useSuspenseQuery(userQuery(userId));

  return (
    <div className={pageParts.page}>
      <ItemHeader
        image={data.picture || backgrounds.userFallback}
        title={userName(data, { hideEmail: true })}
        subtitle={data.email}
      />
      <Tabs
        name="user"
        tabs={[
          {
            name: "Permissions",
            icon: IconUserScreen,
            panel: <UserRolesPanel userId={userId} />,
          },
          {
            name: "Emails",
            icon: IconMail,
            panel: <UserEmailsPanel userId={userId} />,
          },
          {
            name: "Keys & Codes",
            icon: IconKey,
            panel: <ApiKeysPanel entityId={userId} entityType="user" />,
          },
        ]}
        info={<UserInfoPanel />}
      />
      <UserActionPanel />
    </div>
  );
}
