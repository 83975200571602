import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

const validateSearch = z.object({
  email: z.string().optional(),
});

export const Route = createFileRoute("/_public/login")({
  validateSearch,
  beforeLoad: async ({ context: { auth }, search }) => {
    if (auth.user) throw redirect({ to: "/" });

    await auth.login({ email: search.email });
  },
});
