import { SearchParamError } from "@tanstack/react-router";
import { GraphQLError } from "graphql";
import { HTTPError } from "ky";
import posthog from "posthog-js";
import { ZodIssue, z } from "zod";

import { useAsync } from "../../hooks";

const errorSchema = z.object({
  message: z.string().optional(),
  error: z.string().optional(),
});

export const buildErrorMessage = async (error: unknown) => {
  posthog.captureException(error);

  let message = "An error occurred";
  let code = 500;
  if (error instanceof HTTPError) {
    code = error.response.status;
    message = error.message;
    try {
      const body = await error.response.json();
      const parsed = errorSchema.safeParse(body);
      if (parsed.success && (parsed.data.message || parsed.data.error)) {
        message = parsed.data.message || parsed.data.error || "";
      }
    } catch {
      /* Empty */
    }
  } else if (error instanceof GraphQLError) {
    if (error.extensions.code === "UNAUTHORIZED") code = 403;
    message = error.extensions.message
      ? `${error.extensions.message}`
      : error.message;
  } else if (error instanceof SearchParamError && error.cause) {
    const { issues } = error.cause as { issues: ZodIssue[] };
    message = issues.map((i) => i.message).join(", ");
  } else if (error instanceof Error) {
    message = error.message;
  }

  return { message, code };
};

export const useDisplayError = (error: unknown) => {
  const [displayError, setDisplayError] = useAsync(
    () => buildErrorMessage(error),
    [error],
  );

  return { ...displayError, clear: () => setDisplayError(null) };
};

export const buildAssociationError = (
  items: {
    data: any[];
    hasNextPage?: boolean;
  },
  { label, item, action }: { label: string; item: string; action: string },
) => {
  return items.data.length
    ? new Error(
        `${label} is associated with ${items.data?.length}${items.hasNextPage ? "+" : ""} ${item}${items.data.length === 1 && !items.hasNextPage ? "" : "s"} and cannot be ${action}.`,
      )
    : null;
};

export const buildDynamicImportError = (error: unknown) => {
  if (
    error instanceof Error &&
    error.message.includes("error loading dynamically imported module")
  ) {
    window.location.reload();
  }

  return error;
};
