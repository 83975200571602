import {
  DependencyList,
  RefCallback,
  useCallback,
  useEffect,
  useState,
} from "react";

export const useVisibleItem = (
  fn: () => void,
  {
    rootMargin,
    threshold,
  }: Pick<IntersectionObserverInit, "rootMargin" | "threshold">,
  dependencies: DependencyList,
): {
  ref: RefCallback<HTMLDivElement>;
  itemRef: RefCallback<HTMLTableRowElement | HTMLDivElement>;
} => {
  const [root, setRoot] = useState<HTMLDivElement | null>(null);
  const [item, setItem] = useState<HTMLTableRowElement | HTMLDivElement | null>(
    null,
  );

  const runFn = useCallback<IntersectionObserverCallback>((entries) => {
    if (entries[0]?.isIntersecting) fn();
  }, dependencies);

  useEffect(() => {
    const observer = new IntersectionObserver(runFn, {
      root,
      rootMargin,
      threshold,
    });
    if (item) observer.observe(item);

    return () => observer.disconnect();
  }, [runFn, root, item, rootMargin, threshold]);

  return {
    ref: (el: HTMLDivElement) => setRoot(el),
    itemRef: (el: HTMLTableRowElement | HTMLDivElement) => setItem(el),
  };
};
