import { IconDots } from "@tabler/icons-react";
import clsx from "clsx";

import {
  Menu,
  SlideContent,
  TextInput,
  TextInputField,
  cellKinds,
  cellVariants,
  tableParts,
  text,
} from "../../components";
import { useUpdateGaugeAdditionalSettings } from "../../data";

const strapFieldProps = {
  cell: {
    className: clsx(cellVariants.base, "px-3"),
  },
  key: {
    kind: "simple" as const,
    placeholder: "key",
  },
  value: {
    kind: "simple" as const,
    placeholder: "value",
  },
};

export const GaugeAdditionalContent = ({ gaugeId }: { gaugeId: string }) => {
  const { data, error, form, validators } =
    useUpdateGaugeAdditionalSettings(gaugeId);

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description={
        <>
          Add additional key value pairs when the server responds to the gauge.
          Use with caution!
        </>
      }
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <table className={tableParts.table}>
        <thead className={tableParts.head}>
          <tr>
            <th className={clsx(text, cellKinds.title)}>Key</th>
            <th className={clsx(text, cellKinds.title)}>Value</th>
            <th />
          </tr>
        </thead>
        <form.Field
          name="additionalSettings"
          mode="array"
          children={(list) => (
            <tbody>
              {list.state.value.map((_, i) => (
                <tr key={i}>
                  <td {...strapFieldProps.cell}>
                    <form.Field
                      name={`additionalSettings[${i}].key`}
                      validators={validators.setting}
                      children={(field) => (
                        <TextInputField
                          field={field}
                          {...strapFieldProps.key}
                        />
                      )}
                    />
                  </td>
                  <td {...strapFieldProps.cell}>
                    <form.Field
                      name={`additionalSettings[${i}].value`}
                      validators={validators.setting}
                      children={(field) => (
                        <TextInputField
                          field={field}
                          {...strapFieldProps.value}
                        />
                      )}
                    />
                  </td>
                  <td {...strapFieldProps.cell}>
                    <Menu
                      anchor="bottom end"
                      button={{
                        icon: IconDots,
                        kind: "menu",
                        variant: "standard",
                      }}
                      items={[
                        {
                          text: "Remove row",
                          onClick: () => list.removeValue(i),
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
              <tr key={list.state.value.length}>
                <td {...strapFieldProps.cell}>
                  <TextInput
                    {...strapFieldProps.key}
                    id="additionalSettings.next.key"
                    onBlur={(e) => {
                      if (e.target.value) {
                        const i = list.state.value.length;
                        list.pushValue({ key: e.target.value, value: "" });
                        setTimeout(() => {
                          document
                            .getElementById(`additionalSettings[${i}].value`)
                            ?.focus();
                        });
                      }
                    }}
                  />
                </td>
                <td {...strapFieldProps.cell}>
                  <TextInput
                    {...strapFieldProps.value}
                    id="additionalSettings.next.value"
                    onBlur={(e) => {
                      if (e.target.value) {
                        list.pushValue({ key: "", value: e.target.value });
                        setTimeout(() => {
                          document
                            .getElementById(`additionalSettings.next.key`)
                            ?.focus();
                        });
                      }
                    }}
                  />
                </td>
                <td {...strapFieldProps.cell} />
              </tr>
            </tbody>
          )}
        />
      </table>
    </SlideContent>
  );
};
