import { useMatch } from "@tanstack/react-router";

import { ActionsMatch } from "../../components";
import { useAuth } from "../../data";
import { ApiKeyCreateContent, ApiKeyDeleteContent } from "../api-keys";
import { ProfileEditContent } from "./edit";

export const ProfileActionPanel = () => {
  const { user } = useAuth();
  const actions = useMatch({
    from: "/_user/profile",
    select: (s) => {
      const props = {
        apiKeyId: s.search.apiKey || "",
        apiKeyEntityId: user?.id || "",
        apiKeyEntityType: "user" as const,
      };

      switch (s.search.action) {
        case "add":
          if (s.search.entity === "apiKey")
            return { Slide: ApiKeyCreateContent, props };
          return { props };
        case "delete":
          if (s.search.apiKey) return { Modal: ApiKeyDeleteContent, props };
          return { props };
        case "edit":
          return { Slide: ProfileEditContent, props };
      }

      return { props };
    },
  });

  return <ActionsMatch actions={actions} />;
};
