import clsx from "clsx";

import { DisplayUser, userColour, userInitials } from "@joy/shared-utils";

export type AvatarProps = {
  user: DisplayUser;
  image?: string | null;
  small?: boolean;
};

export const Avatar = ({ user, image, small }: AvatarProps) => (
  <div
    className={clsx(
      "relative flex flex-none items-center justify-center overflow-hidden rounded-full ring-white/40",
      small ? "size-6" : "size-8",
    )}
  >
    <div className={clsx("absolute inset-0 size-full", userColour(user))} />
    <div
      className={clsx(
        "relative font-bold text-white",
        small ? "text-[9px]" : "text-xs",
      )}
    >
      {userInitials(user)}
    </div>
    {image ? (
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${image})` }}
      />
    ) : null}
  </div>
);
