import { IconHistory, IconHistoryToggle } from "@tabler/icons-react";
import { format, isBefore } from "date-fns";

import { nextUpdate } from "@joy/shared-calculator";
import {
  displayDays,
  mapOrEmpty,
  pluralize,
  specialChars,
  toTitle,
} from "@joy/shared-utils";

import { Time, Tooltip, settingParts } from "../../components";
import {
  ApnSetting,
  ControlSetting,
  DeliverySetting,
  DynamicAlarmSetting,
  GaugeSettingsItem,
  LoggerSetting,
  MeasurementSetting,
  NetworkSetting,
  QualitySetting,
  ScheduleSetting,
  ServerSetting,
  StaticAlarmSetting,
} from "../../data";

export const statusInfo = ({
  deactivatedAt,
  response,
  setting,
}: GaugeSettingsItem) => {
  const left = {
    Icon: IconHistoryToggle,
    prefix: "Updated",
    date: response?.updatedAt,
    fallback: "",
  };
  const right = {
    Icon: IconHistory,
    prefix: "Updating",
    date: nextUpdate({
      now: new Date(),
      schedule: response?.settings.schedule,
      timezone: response?.timezone,
    }),
    fallback: "on next contact",
  };

  if (setting?.active === false) {
    if (deactivatedAt) {
      left.prefix = "Disabled";
      left.date = deactivatedAt;
      right.date = undefined;
      right.prefix = "";
      right.fallback = "";
    } else {
      right.prefix = "Disabling";
    }
  } else {
    if (!response) {
      left.prefix = "";
    }
    if (
      response &&
      (!setting || isBefore(setting.updatedAt, response.updatedAt))
    ) {
      right.date = undefined;
      right.prefix = "";
      right.fallback = "Up to date";
    }
  }

  return { left, right };
};

export const statusRenderer = ({
  Icon,
  prefix,
  date,
  fallback,
}: ReturnType<typeof statusInfo>["left"]) => (
  <>
    <Icon className="size-5 flex-none opacity-60" />
    <Time
      className="truncate"
      prefix={prefix}
      date={date}
      options={{ fallback }}
    />
  </>
);

export const scheduleRenderer = (
  schedule: ScheduleSetting & { timezone?: string },
) => {
  const startTime = format(
    new Date(1, 1, 1, schedule.startTime.hour, schedule.startTime.minute, 0, 0),
    "h:mm aaa",
  );

  let days, hours;
  switch (schedule.every) {
    case "Month":
      days = (
        <p className={settingParts.sentence}>
          On the <strong>21st</strong>, <strong>Monthly</strong>
        </p>
      );
      hours = (
        <p className={settingParts.sentence}>
          At <strong>{startTime}</strong> ({schedule.timezone || "UTC"})
        </p>
      );
      break;
    case "Fortnight":
      days = (
        <p className={settingParts.sentence}>
          On the <strong>1st</strong> and <strong>14th</strong>,{" "}
          <strong>Fortnightly</strong>
        </p>
      );
      hours = (
        <p className={settingParts.sentence}>
          At <strong>{startTime}</strong> ({schedule.timezone || "UTC"})
        </p>
      );
      break;
    case "Week":
      days = (
        <div className={settingParts.sentence}>
          Weekly, <strong>{displayDays(schedule.days)}</strong>
        </div>
      );
      hours = (
        <p className={settingParts.sentence}>
          Starting at <strong>{startTime}</strong> ({schedule.timezone || "UTC"}
          )
        </p>
      );
      break;
  }

  return (
    <>
      {days}
      {hours}
      {schedule.every === "Week" && schedule.frequency < 24 && (
        <p>
          Every <strong>{schedule.frequency}</strong> hours
          {schedule.endTime && (
            <>
              , until{" "}
              <strong>
                {schedule.endTime === 12
                  ? "midday"
                  : `${schedule.endTime % 12} ${schedule.endTime >= 12 ? "pm" : "am"}`}
              </strong>
            </>
          )}
        </p>
      )}
    </>
  );
};

const defaultStaticAlarm: StaticAlarmSetting = {
  enabled: false,
  polarity: null,
  threshold: null,
  error: null,
};
const defaultDynamicAlarm: DynamicAlarmSetting = {
  enabled: false,
  polarity: null,
  rate: null,
};
type AlarmSetting = {
  delay?: number | null | undefined;
  staticAlarms?: StaticAlarmSetting[] | null | undefined;
  dynamicAlarms?: DynamicAlarmSetting[] | null | undefined;
};

export const buildAlarmsSetting = (
  setting: AlarmSetting | null | undefined,
  response?: AlarmSetting | null | undefined,
): AlarmSetting => ({
  delay: setting?.delay || response?.delay,
  staticAlarms: [
    setting?.staticAlarms?.[0] || defaultStaticAlarm,
    setting?.staticAlarms?.[1] || defaultStaticAlarm,
    setting?.staticAlarms?.[2] || defaultStaticAlarm,
  ],
  dynamicAlarms: [
    setting?.dynamicAlarms?.[0] || defaultDynamicAlarm,
    setting?.dynamicAlarms?.[1] || defaultDynamicAlarm,
  ],
});

export const alarmRenderer = ({
  delay,
  staticAlarms,
  dynamicAlarms,
}: AlarmSetting) => (
  <>
    {delay && (
      <p className={settingParts.sentence}>
        <strong>Delay: </strong>
        {delay} seconds
      </p>
    )}
    {mapOrEmpty(
      (staticAlarms || []).filter((alarm) => alarm.enabled),
      ({ polarity, threshold, error }, idx) => (
        <p key={`static-${idx}`} className={settingParts.sentence}>
          When reading is <strong>{polarity}</strong> than{" "}
          <strong>
            {threshold} {specialChars.plusMinus}
            {error}
          </strong>
        </p>
      ),
      <p className={settingParts.sentence}>No static alarms</p>,
    )}
    {mapOrEmpty(
      (dynamicAlarms || []).filter((alarm) => alarm.enabled),
      ({ polarity, rate }, idx) => (
        <p key={`dynamic-${idx}`} className={settingParts.sentence}>
          When reading is <strong>{polarity}</strong> more than{" "}
          <strong>{rate}</strong>
        </p>
      ),
      <p className={settingParts.sentence}>No dynamic alarms</p>,
    )}
  </>
);

type SonicQualitySetting = {
  measurement: MeasurementSetting | null | undefined;
  quality: QualitySetting | null | undefined;
  height: number | null | undefined;
};

export const buildSonicQualitySetting = (
  setting: SonicQualitySetting | object | null | undefined,
) =>
  setting &&
  "measurement" in setting &&
  "quality" in setting &&
  (setting.measurement || setting.quality || setting.height)
    ? {
        measurement: setting.measurement,
        quality: setting.quality,
        height: setting.height,
      }
    : null;

export const sonicQualityRenderer = ({
  measurement,
  quality,
  height,
}: SonicQualitySetting) => (
  <>
    {measurement && (
      <p className={settingParts.sentence}>{toTitle(measurement)} Setup</p>
    )}
    {quality && (
      <p className={settingParts.sentence}>
        <strong>RSSI: </strong>
        {quality.rssi}
      </p>
    )}
    {quality && (
      <p className={settingParts.sentence}>
        <strong>SRC: </strong>
        {quality.src}
      </p>
    )}
    {height && (
      <p className={settingParts.sentence}>
        <strong>Tank Height: </strong>
        {height}cm
      </p>
    )}
  </>
);

type DigitalSetting = {
  battery: number | null | undefined;
  fStop: number | null | undefined;
  eStop: number | null | undefined;
};

export const buildDigitalSetting = (
  setting: DigitalSetting | object | null | undefined,
) =>
  setting &&
  "battery" in setting &&
  "fStop" in setting &&
  "eStop" in setting &&
  (setting.battery || setting.fStop || setting.eStop)
    ? {
        battery: setting.battery,
        fStop: setting.fStop,
        eStop: setting.eStop,
      }
    : null;

export const digitalRenderer = ({ battery, fStop, eStop }: DigitalSetting) => (
  <>
    {battery && (
      <p className={settingParts.sentence}>
        <strong>Capacity: </strong>
        {battery}mA hrs
      </p>
    )}
    <p className={settingParts.sentence}>
      Range: {eStop} - {fStop}
    </p>
  </>
);

export const networkRenderer = ({
  apn,
  server,
  control,
  network,
  fallbackPhone,
}: {
  apn: ApnSetting | null | undefined;
  server: ServerSetting | null | undefined;
  control: ControlSetting | null | undefined;
  network: NetworkSetting | null | undefined;
  fallbackPhone: string | null | undefined;
}) => (
  <>
    {control && (
      <p className={settingParts.sentence}>
        <strong>Communications: </strong>
        {control.network}
        {network?.operator || network?.band
          ? ` (${network.operator || "?"}, ${network.band || "?"})`
          : ""}
      </p>
    )}
    {control && (
      <p className={settingParts.sentence}>
        <strong>CRC Check: </strong>
        {control.crcCheck ? "Enabled" : "Disabled"}
      </p>
    )}
    {apn && (
      <p className={settingParts.sentence}>
        <strong>Gateway: </strong>
        {apn.gateway}
        {apn.username || apn.password
          ? ` (${apn.username || "?"}:${apn.password || "?"})`
          : ""}
        {apn.authenticated ? " (PAP or CHAP)" : ""}
      </p>
    )}
    {server && (
      <p className={settingParts.sentence}>
        <strong>Server: </strong>
        {server.host}:{server.port}
      </p>
    )}
    {fallbackPhone && (
      <p className={settingParts.sentence}>
        <strong>Fallback Phone: </strong>
        {fallbackPhone}
      </p>
    )}
  </>
);

export const dataRenderer = ({
  logger,
  delivery,
}: {
  logger: LoggerSetting | null | undefined;
  delivery: DeliverySetting | null | undefined;
}) => (
  <>
    {logger && (
      <>
        <p className={settingParts.sentence}>
          Samples data every{" "}
          <strong>{pluralize(logger.sampling, "minute")}</strong>
        </p>
        <p className={settingParts.sentence}>
          Stores data every{" "}
          <strong>{pluralize(logger.frequency, "hour")}</strong>
        </p>
      </>
    )}
    {delivery && (
      <p className={settingParts.sentence}>
        Try every <strong>{delivery.period}</strong> seconds, up to{" "}
        <strong>{delivery.attempts}</strong> times
      </p>
    )}
  </>
);

export const passwordRenderer = ({
  password,
}: {
  password: string | null | undefined;
}) => (
  <Tooltip
    className={settingParts.sentence}
    label={password || specialChars.endash}
  >
    {Array.from(
      { length: password?.length || 5 },
      () => specialChars.masked,
    ).join("")}
  </Tooltip>
);
