import clsx from "clsx";
import { ReactNode } from "react";

import { Header } from "./header";

export type StatProps = {
  label: string;
  icon?: ReactNode;
  delta?: ReactNode;
  value: ReactNode;
  className?: string;
  onClick?: () => void;
};

export const Stat = ({
  label,
  icon,
  delta,
  value,
  className,
  onClick,
}: StatProps) => (
  <div
    className={clsx(
      "flex flex-col gap-0.5 border-l border-gray-900/10 px-4 py-2 lg:w-40",
      onClick && "cursor-pointer",
      className,
    )}
    onClick={onClick}
  >
    <div className="flex items-center justify-between gap-2">
      <div className="truncate text-sm font-medium text-gray-500">
        {icon || label}
      </div>
      {delta && (
        <div className="truncate text-sm font-medium text-gray-900">
          {delta}
        </div>
      )}
    </div>
    <div className="w-full flex-none truncate text-xl font-medium text-gray-900 md:text-2xl">
      {value}
    </div>
  </div>
);

export const StatsHeader = ({ stats }: { stats: StatProps[] }) => (
  <Header>
    <div className="flex max-w-full items-center overflow-auto">
      {stats.map((stat) => (
        <Stat key={stat.label} {...stat} />
      ))}
    </div>
  </Header>
);

export const StatsFilter = ({ stats }: { stats: StatProps[] }) => (
  <div className="flex items-center gap-1 p-1">
    {stats.map((stat) => (
      <p
        key={stat.label}
        className="rounded-full bg-blue-100 px-3 py-0.5 text-sm"
      >
        <strong>{stat.value}</strong> {stat.label}
      </p>
    ))}
  </div>
);
