import { useMatch } from "@tanstack/react-router";

import { ActionsMatch } from "../../components";
import { CustomerCreateContent } from "./create";

export const CustomersActionPanel = () => {
  const actions = useMatch({
    from: "/_admin/customers/",
    select: (s) => {
      const props = {};

      switch (s.search.action) {
        case "create":
          return { Slide: CustomerCreateContent, props };
      }

      return { props };
    },
  });

  return <ActionsMatch actions={actions} />;
};
