import { ReactNode } from "react";

import { ButtonProps, SlideContent } from "../../components";
import { UploadGaugesContentProps, useUploadGauges } from "../../data";
import { GaugeUploadFile } from "./upload.file";
import { GaugeUploadItems } from "./upload.items";
import { GaugeUploadResult } from "./upload.result";

const stepLoadingtext: Record<number, string> = {
  1: "Preparing...",
  2: "Uploading...",
  3: "Uploading...",
};

const stepFields: Record<
  number,
  (props: UploadGaugesContentProps) => ReactNode
> = {
  1: GaugeUploadFile,
  2: GaugeUploadItems,
  3: GaugeUploadResult,
};

const noop = () => null;

export const GaugeUploadContent = () => {
  const { form, gauges, mutations, step, setStep, validators } =
    useUploadGauges();

  const StepFields = stepFields[step] || noop;
  const stepButtons: Record<number, ButtonProps[]> = {
    1: [
      { text: "Cancel", variant: "cancel", type: "reset" },
      { text: "Next", variant: "action", type: "submit" },
    ],
    2: [
      {
        text: "Back",
        variant: "cancel",
        type: "button",
        onClick: () => setStep(1),
      },
      { text: "Upload", variant: "action", type: "submit" },
    ],
    3: [{ text: "Close", variant: "cancel", type: "reset" }],
  };

  return (
    <SlideContent
      title="Add gauges"
      description="Upload a file to add gauges to a customer."
      onSubmit={form.handleSubmit}
      loading={{
        show: form.state.isSubmitting,
        text: stepLoadingtext[step] || "",
      }}
      buttons={stepButtons[step]}
    >
      <StepFields
        form={form}
        gauges={gauges}
        mutations={mutations}
        validators={validators}
      />
    </SlideContent>
  );
};
