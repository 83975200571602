import { Radio, RadioGroup, RadioGroupProps } from "@headlessui/react";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { FieldApi } from "@tanstack/react-form";
import clsx from "clsx";
import { ReactNode } from "react";

import { FieldError } from "./error";
import { Field, Label, fieldKinds } from "./parts";

type RadioInputProps<D> = {
  id?: string;
  placeholder?: string;
  optionKey?: (option: D) => string;
  optionLabel?: (option: D) => ReactNode;
  options: D[];
};

export const RadioInput = <D,>({
  optionKey = (o) => `${o}`,
  optionLabel = (o) => `${o}`,
  options,
  ...props
}: RadioInputProps<D> & RadioGroupProps<any, D>) => (
  <RadioGroup
    {...props}
    className={clsx(props.className, "flex w-full flex-col gap-3")}
  >
    {options.map((option) => (
      <Radio
        key={optionKey(option)}
        value={option}
        className="group/radio flex cursor-pointer items-center gap-3 rounded-md p-3 ring-1 ring-gray-300 outline-hidden transition-all ring-inset focus-within:ring-sky-600 hover:ring-sky-600 data-disabled:opacity-50"
      >
        <div className="flex-1 truncate">{optionLabel(option)}</div>
        <IconCircleCheckFilled className="size-6 text-sky-600 opacity-0 transition-opacity group-data-checked/radio:opacity-100" />
      </Radio>
    ))}
  </RadioGroup>
);

export const RadioInputField = <D,>({
  field,
  ...props
}: {
  field: FieldApi<any, any, any, any, D>;
} & RadioInputProps<D> &
  RadioGroupProps<any, D>) => (
  <RadioInput
    id={field.name}
    name={field.name}
    value={field.state.value}
    onBlur={field.handleBlur}
    onChange={(e) => field.handleChange(e)}
    invalid={(field.state.meta.errors.length > 0).toString()}
    {...props}
  />
);

export const RadioField = <D,>({
  field,
  label,
  fieldKind,
  ...rest
}: {
  field: FieldApi<any, any, any, any, D>;
  label: string;
  fieldKind?: keyof typeof fieldKinds;
} & RadioInputProps<D> &
  RadioGroupProps<any, D>) => (
  <Field kind={fieldKind}>
    <Label htmlFor={field.name.toString()}>{label}</Label>
    <RadioInputField field={field} {...rest} />
    <FieldError field={field} />
  </Field>
);
