import { createContext, useContext } from "react";

import { TeamPermission } from "@joy/shared-utils";

export type AuthUser = {
  id: string;
  email: string;
  jlteam?: TeamPermission | undefined | null;
  firstName?: string | undefined | null;
  lastName?: string | undefined | null;
  picture?: string | undefined | null;
};

export type AuthClient = {
  user: AuthUser | undefined;
  login(params: { email?: string }): Promise<void>;
  logout(): Promise<void>;
  token: () => Promise<string | undefined>;
};

export type AuthContextValue = {
  user: AuthUser | undefined;
  hasTeamPermission: (permission: TeamPermission) => boolean;
  impersonating: boolean;
  impersonate: (
    props: { token: string; user: AuthUser; expires: Date } | undefined,
  ) => void;
  login: (params: { email?: string }) => Promise<void>;
  logout: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextValue>({
  user: undefined,
  impersonating: false,
  hasTeamPermission: () => false,
  impersonate: () => {},
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const useAuth = () => useContext(AuthContext);
