import {
  IconArrowNarrowRight,
  IconCheck,
  IconEdit,
  IconTrashX,
} from "@tabler/icons-react";
import { NavigateOptions, useNavigate } from "@tanstack/react-router";
import { ColumnHelper } from "@tanstack/react-table";
import clsx from "clsx";

import { cellKinds, dateCell, menuCell, stackCell } from "../../components";
import {
  RoleItem,
  RolePermission,
  permissionFields,
  useAuth,
  usePrefetchRole,
} from "../../data";

export const permissionColumns = <V extends { permissions: RolePermission[] }>(
  c: ColumnHelper<V>,
) => [
  c.accessor((v) => v.permissions, {
    header: "Permissions",
    meta: {
      className: cellKinds.appears,
    },
    sortingFn: (a, b) =>
      a.original.permissions.length - b.original.permissions.length,
    cell: (v) => (
      <div className="flex gap-3 truncate">
        {permissionFields.map((p) => (
          <div
            key={p.name}
            className={clsx(
              "flex items-center gap-0.5 truncate font-medium text-slate-700",
              !v.getValue().includes(p.name) && "opacity-30",
            )}
          >
            <IconCheck className="size-4" />
            <div className="truncate">{p.label}</div>
          </div>
        ))}
      </div>
    ),
  }),
];

export const roleColumns =
  ({
    prefetchRole,
    hasTeamPermission,
    navigate,
    current,
    permissions,
  }: {
    prefetchRole: ReturnType<typeof usePrefetchRole>;
    hasTeamPermission: ReturnType<typeof useAuth>["hasTeamPermission"];
    navigate: ReturnType<typeof useNavigate>;
    current: NavigateOptions;
    permissions: RolePermission[];
  }) =>
  (c: ColumnHelper<RoleItem>) => [
    c.accessor(
      "user.name",
      stackCell({
        header: "Name",
        detail: (data) => [data.user.email],
        smallDetail: (data) => [
          permissionFields
            .filter((f) => data.permissions.includes(f.name))
            .map((f) => f.label),
        ],
        always: true,
      }),
    ),
    c.accessor(
      "user.lastActiveAt",
      dateCell({
        header: "Active",
      }),
    ),
    ...permissionColumns(c),
    c.display(
      menuCell({
        onClick: ({ id }) => prefetchRole(id),
        items: [
          hasTeamPermission("admin")
            ? {
                icon: IconArrowNarrowRight,
                text: "View User",
                variant: "standard",
                onClick: (r) =>
                  navigate({
                    to: "/users/$userId",
                    params: { userId: r.user.id },
                  }),
              }
            : undefined,
          permissions.includes("manage")
            ? {
                icon: IconEdit,
                text: "Update Permissions",
                variant: "standard",
                onClick: (r) =>
                  navigate({
                    ...current,
                    search: { action: "edit", role: r.id },
                    replace: true,
                  }),
              }
            : undefined,
          permissions.includes("manage")
            ? {
                icon: IconTrashX,
                text: "Remove Role",
                variant: "danger",
                onClick: (r) =>
                  navigate({
                    ...current,
                    search: { action: "delete", role: r.id },
                    replace: true,
                  }),
              }
            : undefined,
        ],
      }),
    ),
  ];
