import { toTitle, userName } from "@joy/shared-utils";

import { ListField, SlideContent, TextField } from "../../components";
import { useAuth, useUpdateUser } from "../../data";
import { UserConfirmContent } from "./confirm";

export const UserEditContent = ({ userId }: { userId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { confirming, setConfirming, data, error, form, validators } =
    useUpdateUser(userId);

  return (
    <>
      <UserConfirmContent
        show={confirming}
        user={userName(data)}
        role={form.getFieldValue("jlteam")}
        onSubmit={() => {
          setConfirming(false);
          form.setFieldValue("confirmed", true);
          form.handleSubmit();
        }}
        onClose={() => setConfirming(false)}
      />
      <SlideContent
        title={`Update ${userName(data)}`}
        description="Update and save the user information below."
        error={error}
        onSubmit={form.handleSubmit}
        loading={{ show: form.state.isSubmitting, text: "Updating..." }}
        buttons={[
          { text: "Cancel", variant: "cancel", type: "reset" },
          { text: "Update", type: "submit", variant: "action" },
        ]}
      >
        <form.Field
          name="jlteam"
          validators={validators.jlteam}
          children={(field) => (
            <ListField
              field={field}
              label="Role"
              options={validators.jlteam.onSubmit._def.values}
              optionLabel={(o) => toTitle(o)}
              optionVisible={(o) => {
                if (o === "god") return false;
                if (o === "super") return hasTeamPermission("god");
                if (o === "admin") return hasTeamPermission("super");
                return true;
              }}
            />
          )}
        />
        <form.Field
          name="firstName"
          children={(field) => (
            <TextField field={field} label="First name" autoFocus />
          )}
        />
        <form.Field
          name="lastName"
          children={(field) => <TextField field={field} label="Last name" />}
        />
      </SlideContent>
    </>
  );
};
