export const timedId = (id: string, date: Date, precise?: boolean) =>
  `${id}-${Math.floor(precise ? date.getTime() : date.getTime() / 1000)}`;

export const hashedId = async (id: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(id);
  const hash = await crypto.subtle.digest("SHA-256", data);

  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};
