import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { displayPrice, specialChars } from "@joy/shared-utils";

import {
  Button,
  ListInput,
  TablePage,
  cellKinds,
  stackCell,
} from "../../../components";
import {
  invoice,
  invoicesQuery,
  listLoader,
  useInvoicesCalendar,
} from "../../../data";
import { useTable } from "../../../hooks";

const validateSearch = z.object({
  action: z.enum(["generate"]).optional(),
});

export const Route = createFileRoute("/_super/invoices/$monthYear/")({
  validateSearch,
  loader: async ({ params, context }) =>
    listLoader(context.queryClient, invoicesQuery(params.monthYear)),
  component: Component,
});

export function Component() {
  const monthYear = Route.useParams({ select: (p) => p.monthYear });
  const navigate = Route.useNavigate();
  const { month, year, adjust } = useInvoicesCalendar(monthYear, (updated) =>
    navigate({ params: { monthYear: updated } }),
  );
  const invoices = useTable(invoicesQuery(monthYear), {
    columnDefs: (c) => [
      c.accessor(
        "customer.name",
        stackCell({
          header: "Customer",
          detail: (data) => [
            [
              data.linkedInvoice?.number || specialChars.endash,
              data.linkedInvoice?.status,
            ],
          ],
        }),
      ),
      c.accessor("linkedInvoice", {
        header: "Invoice",
        meta: { className: cellKinds.appearsxl },
        cell: (c) => {
          const invoice = c.getValue();
          return invoice ? `${invoice.number} (${invoice.status})` : "";
        },
      }),
      c.accessor((c) => ({ count: c.fees.fees.length, more: !!c.fees.next }), {
        id: "count",
        header: "Line Items",
        cell: (c) => `${c.getValue().count}${c.getValue().more ? "+" : ""}`,
      }),
      c.accessor(
        (c) => ({
          total: c.fees.fees.reduce(
            (agg, f) =>
              agg +
              f.amount * (f.quantity - (f.prepaid || 0)) -
              (f.discount || 0),
            c.adjustment || 0,
          ),
          more: !!c.fees.next,
        }),
        {
          id: "total",
          header: "Total",
          cell: (c) =>
            `${c.getValue().more ? "Over " : ""}${displayPrice(c.getValue().total)}`,
        },
      ),
    ],
    select: (invoiceId, data) => ({
      to: "/invoices/$monthYear/$invoiceId",
      params: { invoiceId, monthYear: { month: data.month, year: data.year } },
    }),
    statLocation: "header",
    create: {
      to: "/invoices/$monthYear",
      params: { monthYear },
      search: { action: "generate" },
      replace: true,
      text: "Generate Invoices",
    },
    word: invoice,
  });

  return (
    <TablePage
      {...invoices.page}
      actions={[
        <div key="month-year" className="flex gap-1">
          <Button
            icon={IconChevronLeft}
            kind="menu"
            variant="standard"
            onClick={() => adjust(-1)}
          />
          <ListInput className="min-w-[86px]" {...month} />
          <ListInput className="min-w-[92px]" {...year} />
          <Button
            icon={IconChevronRight}
            kind="menu"
            variant="standard"
            onClick={() => adjust(1)}
          />
        </div>,
        ...invoices.page.actions,
      ]}
    />
  );
}
