import { SettingDisplay } from "../../components";
import { useAuth } from "../../data";

export const SettingsDebug = ({
  gaugeId,
  items,
  additionalSettings,
}: {
  gaugeId: string;
  items: string | undefined;
  additionalSettings: string[][] | null | undefined;
}) => {
  const { hasTeamPermission } = useAuth();
  let parsedItems = items || "No Items Available";
  if (items) {
    try {
      parsedItems = JSON.parse(items)
        .map((i: string[]) => i.join(" = "))
        .join("\n");
    } catch {
      /* empty */
    }
  }

  let parsedAdditionalSettings = additionalSettings
    ? JSON.stringify(additionalSettings)
    : "No Additional Settings";
  if (additionalSettings) {
    try {
      parsedAdditionalSettings = additionalSettings
        .map((i) => i.join(" = "))
        .join("\n");
    } catch {
      /* empty */
    }
  }
  return (
    <SettingDisplay
      title="Debug Information"
      edit={
        hasTeamPermission("god")
          ? {
              to: `/gauges/$gaugeId`,
              params: { gaugeId },
              search: { action: "additional" },
              text: "Edit Additional Settings",
            }
          : undefined
      }
      left={
        <>
          <span className="font-mono text-sm text-wrap break-all whitespace-pre-line">
            {parsedItems}
          </span>
        </>
      }
      right={
        <>
          <span className="font-mono text-sm text-wrap break-all whitespace-pre-line">
            {parsedAdditionalSettings}
          </span>
        </>
      }
    />
  );
};
