import {
  NavigateOptions,
  RegisteredRouter,
  useNavigate,
} from "@tanstack/react-router";

import { Error } from "../error";

export type NotFoundProps<TFrom extends string, TTo extends string> = {
  item: string;
  fix?: string;
  action: NavigateOptions<RegisteredRouter, TFrom, TTo>;
  actionLabel: string;
};

export const NotFound = <TFrom extends string, TTo extends string>({
  item,
  fix = `Or click below to get started`,
  action,
  actionLabel,
}: NotFoundProps<TFrom, TTo>) => {
  const navigate = useNavigate();

  return (
    <Error
      code="404"
      heading={`${item} not found.`}
      description={`Sorry, we can't find that ${item.toLocaleLowerCase()}. If something went wrong, plase let us know. ${fix}.`}
      action={{
        onClick: () => navigate(action),
        label: actionLabel,
      }}
    />
  );
};
