import {
  IconDownload,
  IconTrashX,
  IconZoomQuestion,
} from "@tabler/icons-react";
import { LinkProps, useNavigate } from "@tanstack/react-router";

import { downloadDataUrl } from "@joy/shared-utils";

import {
  ExternalLink,
  TablePage,
  cellKinds,
  dateCell,
  menuCell,
  stackCell,
} from "../../components";
import { ApiKeyEntityType, apiKey, apiKeysQuery } from "../../data";
import { useTable } from "../../hooks";

export const ApiKeysPanel = ({
  entityId,
  entityType,
  to = "/users/$userId",
}: {
  entityId: string;
  entityType: ApiKeyEntityType;
  to?: LinkProps["to"];
}) => {
  const navigate = useNavigate();
  const keys = useTable(apiKeysQuery(entityId, entityType), {
    columnDefs: (c) => [
      c.accessor(
        "label",
        stackCell({
          header: "Purpose",
          detail: (a) => [[a.maskedValue]],
        }),
      ),
      c.accessor("maskedValue", {
        header: "Key",
        meta: {
          className: cellKinds.appearsxl,
        },
      }),
      c.accessor(
        "lastUsed",
        dateCell({
          header: "Last Used",
        }),
      ),
      c.display(
        menuCell({
          items: [
            {
              icon: IconDownload,
              text: "Download QR Code",
              variant: "action",
              hidden: (r) => !r.qrCode,
              onClick: (r) => downloadDataUrl(r.qrCode || "", `${r.label}.png`),
            },
            {
              icon: IconTrashX,
              text: "Revoke API Key",
              variant: "danger",
              onClick: (r) =>
                navigate({
                  to,
                  search: { action: "delete", apiKey: r.id },
                  replace: true,
                }),
            },
          ],
        }),
      ),
    ],
    create: {
      to,
      search: { action: "add", entity: "apiKey" },
      replace: true,
    },
    statLocation: "filters",
    word: apiKey,
  });

  return (
    <TablePage
      {...keys.page}
      actions={[
        <ExternalLink
          key="docs"
          icon={IconZoomQuestion}
          kind="menu"
          variant="action"
          text="Documentation"
          href="/documentation.html"
          target="_blank"
        />,
        ...keys.page.actions,
      ]}
    />
  );
};
