import { useNavigate } from "@tanstack/react-router";

import { TablePage } from "../../components";
import { rolesQuery, useAuth, usePrefetchRole, user } from "../../data";
import { useTable } from "../../hooks";
import { roleColumns } from "../role";

export const CustomerUsersPanel = ({ customerId }: { customerId: string }) => {
  const navigate = useNavigate();
  const prefetchRole = usePrefetchRole();
  const { hasTeamPermission } = useAuth();

  const roles = useTable(rolesQuery(customerId, "customer"), {
    columnDefs: roleColumns({
      prefetchRole,
      hasTeamPermission,
      navigate,
      current: { to: "/customers/$customerId", params: { customerId } },
      permissions: ["read", "write", "install", "manage"],
    }),
    initialSort: [{ id: "Manage", desc: true }],
    create: {
      to: "/customers/$customerId",
      params: { customerId },
      search: { action: "add", entity: "user" },
    },
    statLocation: "filters",
    word: user,
  });

  return <TablePage {...roles.page} />;
};
