import { IconAlertTriangle } from "@tabler/icons-react";

import { userName } from "@joy/shared-utils";

import { ModalContent } from "../../components";
import { useAuth, useDeleteRole } from "../../data";

export const RoleDeleteContent = ({ roleId }: { roleId: string }) => {
  const { data, error, isPending, onDelete } = useDeleteRole(roleId);
  const { user } = useAuth();
  const selfError =
    data.user?.email === user?.email
      ? new Error("You cannot remove your own access.")
      : undefined;

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Remove access for ${userName(data.user)}?`}
      description={`Are you sure you want to remove ${userName(data.user)}'s access to ${data.entity.name}? This action cannot be undone.`}
      error={error || selfError}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Removing..." }}
      buttons={[
        {
          text: "Remove",
          type: "submit",
          variant: "danger",
          autoFocus: true,
          disabled: !!selfError,
        },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
