import { Button as HeadlessButton } from "@headlessui/react";
import {
  IconCpu2,
  IconDevicesCode,
  IconLibrary,
  IconLogout,
  IconMessageReport,
} from "@tabler/icons-react";
import { Link } from "@tanstack/react-router";
import clsx from "clsx";

import { toTitle } from "@joy/shared-utils";

import {
  AuthContextValue,
  account,
  customer,
  document,
  gauge,
  invoice,
  log,
  plan,
  product,
  tank,
  user,
} from "../../data";
import { Avatar } from "../avatar";
import { Page, SidebarItem, sidebarItem } from "./sidebar.item";

const pages: Page[] = [
  { Icon: tank.icon, label: toTitle(tank.plural), to: "/tanks" },
  {
    Icon: gauge.icon,
    label: toTitle(gauge.plural),
    to: "/gauges",
    rolePermission: "install",
  },
  {
    Icon: account.icon,
    label: toTitle(account.plural),
    to: "/accounts",
    rolePermission: "manage",
  },
  {
    Icon: customer.icon,
    label: toTitle(customer.plural),
    to: "/customers",
    teamPermission: "admin",
  },
  {
    Icon: IconLibrary,
    label: "Library",
    to: "/library",
    teamPermission: "admin",
    subPages: [
      {
        Icon: document.icon,
        label: toTitle(document.plural),
        to: "/library/documents",
      },
      {
        Icon: plan.icon,
        label: toTitle(plan.plural),
        to: "/library/plans",
      },
      {
        Icon: product.icon,
        label: toTitle(product.plural),
        to: "/library/products",
      },
    ],
  },
  {
    Icon: invoice.icon,
    label: "Invoices",
    to: "/invoices",
    teamPermission: "super",
  },
  {
    Icon: IconDevicesCode,
    label: "Dev Tools",
    to: "/dev",
    teamPermission: "god",
    subPages: [
      {
        Icon: log.icon,
        label: "Log Search",
        to: "/dev/logs",
      },
      {
        Icon: IconCpu2,
        label: "Parsers",
        to: "/dev/parse",
      },
    ],
  },
  {
    Icon: user.icon,
    label: toTitle(user.plural),
    to: "/users",
    teamPermission: "admin",
  },
];

type SidebarContentProps = {
  user: AuthContextValue["user"];
  logout: () => void;
  openFeedback: () => void;
};

export const SidebarContent = ({
  user,
  logout,
  openFeedback,
}: SidebarContentProps) => (
  <>
    <nav aria-label="pages" className="flex flex-1 flex-col gap-y-2">
      {pages.map((page) => (
        <SidebarItem key={page.label} {...page} />
      ))}
    </nav>
    <nav aria-label="account" className="my-2 flex flex-col gap-y-2">
      <HeadlessButton className={sidebarItem} onClick={openFeedback}>
        <IconMessageReport className="size-6 flex-none" stroke={2.4} />
        <span className="truncate text-sm font-semibold">
          Support & Feedback
        </span>
      </HeadlessButton>
      <Link to="/profile" className={clsx(sidebarItem, "py-1.5 pl-1")}>
        <Avatar user={user} image={user?.picture} />
        <span className="truncate text-sm font-semibold">
          {user?.email || "Profile"}
        </span>
      </Link>
      <HeadlessButton className={sidebarItem} onClick={logout}>
        <IconLogout className="size-6 flex-none" />
        <span className="truncate text-sm font-semibold">Sign out</span>
      </HeadlessButton>
    </nav>
  </>
);
