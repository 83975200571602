import { CellContext } from "@tanstack/react-table";
import clsx from "clsx";
import { ReactNode } from "react";

import { cellKinds } from "./parts";

export type CellDetail = ReactNode[] | ReactNode;

export const CellStack = ({
  value,
  detail,
  smallDetail,
  always,
}: {
  value: string | null | undefined;
  detail: CellDetail[];
  smallDetail?: CellDetail[];
  always?: boolean;
}) => (
  <>
    <div className="truncate">{value}</div>
    {detail
      .filter((i) => !!i)
      .map((item, idx) => (
        <div
          className={clsx(
            "mt-1 flex items-center divide-x divide-gray-300 truncate text-xs font-semibold",
            !always && "xl:hidden",
          )}
          key={idx}
        >
          {(Array.isArray(item) ? item : [item])
            .filter((i) => !!i)
            .map((i, idx) => (
              <span className="truncate px-1 first-of-type:pl-0" key={idx}>
                {i}
              </span>
            ))}
        </div>
      ))}
    {smallDetail
      ?.filter((i) => !!i)
      .map((item, idx) => (
        <div
          className={clsx(
            "mt-1 flex items-center divide-x divide-gray-300 truncate text-xs font-semibold",
            "md:hidden",
          )}
          key={idx}
        >
          {(Array.isArray(item) ? item : [item])
            .filter((i) => !!i)
            .map((i, idx) => (
              <span className="px-1 first-of-type:pl-0" key={idx}>
                {i}
              </span>
            ))}
        </div>
      ))}
  </>
);

export const stackCell = <D, V extends Date | string | null | undefined>({
  header,
  value,
  detail,
  smallDetail,
  always,
}: {
  header: string;
  value?: (data: D) => string | null | undefined;
  detail: (data: D) => CellDetail[];
  smallDetail?: (data: D) => CellDetail[];
  always?: boolean;
}) => ({
  header,
  meta: { className: cellKinds.stacks },
  cell: (v: CellContext<D, V>) => (
    <CellStack
      value={value ? value(v.row.original) : v.getValue()?.toString()}
      detail={detail(v.row.original)}
      smallDetail={smallDetail ? smallDetail(v.row.original) : undefined}
      always={always}
    />
  ),
});
