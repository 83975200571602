import { useMatch } from "@tanstack/react-router";

import { ActionsMatch } from "../../components";
import { ApiKeyCreateContent, ApiKeyDeleteContent } from "../api-keys";
import { CommentDeleteContent } from "../comments";
import { RoleAddContent, RoleDeleteContent, RoleEditContent } from "../role";
import { AccountConfigContent } from "./config";
import { AccountDeleteContent } from "./delete";
import { AccountEditContent } from "./edit";
import { AccountScheduleContent } from "./schedule";

export const AccountActionPanel = () => {
  const actions = useMatch({
    from: "/_user/accounts/$accountId",
    select: (s) => {
      const props = {
        accountId: s.params.accountId,
        apiKeyId: s.search.apiKey || "",
        commentId: s.search.comment,
        roleId: s.search.role || "",
        entityId: s.params.accountId,
        entityType: "account" as const,
        apiKeyEntityId: s.params.accountId,
        apiKeyEntityType: "account" as const,
      };

      switch (s.search.action) {
        case "add":
          if (s.search.entity === "apiKey")
            return { Slide: ApiKeyCreateContent, props };
          if (s.search.entity === "role")
            return { Slide: RoleAddContent, props };
          return { props };
        case "config":
          return {
            Slide: AccountConfigContent,
            props,
          };
        case "schedule":
          return { Slide: AccountScheduleContent, props };
        case "delete":
          if (s.search.apiKey) return { Modal: ApiKeyDeleteContent, props };
          if (s.search.comment) return { Modal: CommentDeleteContent, props };
          if (s.search.role) return { Modal: RoleDeleteContent, props };
          return { Modal: AccountDeleteContent, props };
        case "edit":
          if (s.search.role) return { Slide: RoleEditContent, props };
          return { Slide: AccountEditContent, props };
      }

      return { props };
    },
  });

  return <ActionsMatch actions={actions} />;
};
