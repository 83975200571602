import { ReactNode } from "react";

import { useClearParams } from "../hooks";
import { Defined } from "./defined";
import { Modal } from "./modal";
import { Slide } from "./slide";

export type ActionsProps = {
  modal?: ReactNode;
  slide?: ReactNode;
  onClose?: () => void;
};

export const Actions = ({ slide, modal, onClose }: ActionsProps) => {
  const onClear = useClearParams();
  return (
    <>
      <Slide show={!!slide} onClose={onClose || onClear}>
        <Defined>{slide}</Defined>
      </Slide>
      <Modal show={!!modal} onClose={onClose || onClear}>
        <Defined>{modal}</Defined>
      </Modal>
    </>
  );
};

export type ActionsMatchProps<P> = {
  actions: {
    props: P;
    Modal?: (props: P) => ReactNode;
    Slide?: (props: P) => ReactNode;
  };
  onClose?: () => void;
};

export const ActionsMatch = <P extends object>({
  actions: { props, Modal, Slide },
  onClose,
}: ActionsMatchProps<P>) => (
  <Actions
    modal={Modal && <Modal {...props} />}
    slide={Slide && <Slide {...props} />}
    onClose={onClose}
  />
);
