import {
  displayPercent,
  displayTemperature,
  displayVolume,
  specialChars,
} from "@joy/shared-utils";

import {
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  displayBattery,
  stackCell,
} from "../../components";
import { accountTanksQuery, tank } from "../../data";
import { useTable } from "../../hooks";

export const AccountTanksPanel = ({ accountId }: { accountId: string }) => {
  const tanks = useTable(accountTanksQuery(accountId), {
    columnDefs: (c) => [
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          always: true,
          detail: (data) => [
            [
              displayVolume(data.level?.value, data.unit),
              displayTemperature(data.level?.temperature),
              displayBattery(data.level?.voltage, "-my-1"),
            ],
          ],
        }),
      ),
      c.accessor("level.percent", {
        header: "Volume",
        meta: { className: cellKinds.appearsxl },
        cell: (r) => displayPercent(r.getValue()) || specialChars.endash,
      }),
      c.accessor(
        "level.updatedAt",
        dateCell({
          header: "Last Active",
        }),
      ),
      c.display(
        actionCell({
          select: (tank) => ({
            to: "/tanks/$tankId",
            params: { tankId: tank.id },
          }),
        }),
      ),
    ],
    select: (tankId) => ({
      to: "/tanks/$tankId",
      params: { tankId },
    }),
    statLocation: "filters",
    word: tank,
  });

  return <TablePage {...tanks.page} />;
};
