import {
  DefaultError,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";

import { ComboInputProps } from "../components";
import { Word } from "../data";
import { useVisibleItem } from "./visible";

const emptyArray: never[] = [];

type ComboQueryProps<TData> = Pick<
  ComboInputProps<TData>,
  "loading" | "empty" | "options" | "container"
>;

export const useComboQuery = <
  TQueryFnData,
  TError = DefaultError,
  TData extends object = object,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
>(
  query: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData[],
    TQueryFnData,
    TQueryKey,
    TPageParam
  >,
  {
    filter,
    word,
  }: {
    filter?: (data: TData) => boolean;
    word: Word;
  },
): {
  combo: ComboQueryProps<TData>;
} => {
  const { data, isFetching, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery(query);
  const filtered = filter ? data?.filter(filter) : data;

  const { ref, itemRef } = useVisibleItem(
    () => {
      if (hasNextPage && !isFetchingNextPage) fetchNextPage();
    },
    {
      rootMargin: "10px",
      threshold: 0,
    },
    [hasNextPage, isFetchingNextPage],
  );

  return {
    combo: {
      loading: isFetching,
      empty: isFetching
        ? `Loading ${word.plural}...`
        : `No ${word.plural} available`,
      options: filtered || emptyArray,
      container: {
        ref,
        itemRef,
      },
    },
  };
};
