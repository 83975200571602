import clsx from "clsx";
import { ReactNode } from "react";

import { tw } from "@joy/shared-utils";

import { LinkButton, LinkButtonProps } from "./button";

const boxVariants = {
  standard: tw`rounded-sm pt-2 ring-1 shadow-xs ring-gray-900/10`,
  info: tw`rounded-sm bg-white ring-1 shadow-xs ring-gray-900/10`,
};

const boxParts = {
  header: {
    standard: tw`mx-3 justify-between border-b border-gray-300 pr-2`,
    info: tw`justify-between px-3`,
  },
  title: {
    standard: tw`flex items-center self-stretch border-y border-sky-700 border-t-transparent px-3 py-1 font-semibold`,
    info: tw`py-3 font-semibold`,
  },
};

export type BoxHeaderProps<
  TFrom extends string,
  TTo extends string,
  E = LinkButtonProps<TFrom, TTo>,
> = {
  title: string;
  edit?: E;
  variant?: keyof typeof boxVariants;
};

export const BoxHeader = <
  TFrom extends string,
  TTo extends string,
  E = LinkButtonProps<TFrom, TTo>,
>({
  title,
  edit,
  variant = "standard",
}: BoxHeaderProps<TFrom, TTo, E>) => (
  <h2
    className={clsx(
      "flex items-center gap-1 text-sm",
      boxParts.header[variant],
    )}
  >
    {title && (
      <div className={boxParts.title[variant]}>
        <span>{title}</span>
      </div>
    )}
    {edit && <LinkButton kind="link" {...edit} />}
  </h2>
);

export type BoxProps<
  TFrom extends string,
  TTo extends string,
  E = LinkButtonProps<TFrom, TTo>,
> = {
  header?: Omit<BoxHeaderProps<TFrom, TTo, E>, "variant">;
  variant?: keyof typeof boxVariants;
  children: ReactNode;
};

export const Box = <
  TFrom extends string,
  TTo extends string,
  E = LinkButtonProps<TFrom, TTo>,
>({
  header,
  variant = "standard",
  children,
}: BoxProps<TFrom, TTo, E>) => (
  <div className={clsx("min-w-0", boxVariants[variant])}>
    {header && <BoxHeader {...header} variant={variant} />}
    <div className="p-1 lg:px-2">{children}</div>
  </div>
);
