import {
  cippusHeight,
  cippusVolume,
  strappingMax,
} from "@joy/shared-calculator";
import { displayPercent, roundFloat, specialChars } from "@joy/shared-utils";

import { GaugeCalculatorProps } from "../../data";

export const CippusCalculator = ({
  observation,
  config,
  strappingTable,
}: GaugeCalculatorProps<"Cippus">) => {
  const reading =
    observation?.reading && roundFloat(observation.reading, { precision: 3 });
  const height =
    observation?.reading &&
    roundFloat(cippusHeight(observation.reading, { config, strappingTable }), {
      precision: 3,
    });
  const volume =
    observation?.reading &&
    cippusVolume(observation.reading, { config, strappingTable });
  const maximum = strappingMax(strappingTable, "l");
  const percent = height && maximum && (height / maximum) * 100;

  return (
    <div className="flex flex-col gap-3 px-2.5 pt-3 pb-4">
      <math>
        <mstyle className="font-sans text-base">
          <mn>H</mn>
          <mo>=</mo>
          <mrow>
            <mo>(</mo>
            <mn>{reading || "R"}</mn>
            <mo>{specialChars.dot}</mo>
            <mn>1000</mn>
            <mo>{specialChars.dot}</mo>
            <mfrac>
              <mn>{config.specificGravityProbe}</mn>
              <mn>{config.specificGravityActual}</mn>
            </mfrac>
            <mo>)</mo>
          </mrow>
          <mo>{config.offsetHeight < 0 ? "-" : "+"}</mo>
          <mn>{Math.abs(config.offsetHeight)}</mn>
          {!!height && (
            <>
              <mo>=</mo>
              <mn className="font-bold">{height} mm</mn>
            </>
          )}
        </mstyle>
      </math>
      <math>
        <mstyle className="font-sans text-base">
          <mn>V</mn>
          <mo>=</mo>
          <mrow>
            <mo>(</mo>
            <mn>{height || "H"}</mn>
            <mo>{specialChars.dot}</mo>
            <mfrac>
              <mn>Strapping mm</mn>
              <mn>Strapping l</mn>
            </mfrac>
            <mo>)</mo>
          </mrow>
          <mo>{config.offsetVolume < 0 ? "-" : "+"}</mo>
          <mn>{Math.abs(config.offsetVolume)}</mn>
          {!!volume && (
            <>
              <mo>=</mo>
              <mn className="font-bold">
                {roundFloat(volume, { precision: 3 })} l
              </mn>
            </>
          )}
          {!!percent && (
            <>
              <mo>=</mo>
              <mn className="font-bold">{displayPercent(percent)}</mn>
            </>
          )}
        </mstyle>
      </math>
    </div>
  );
};
