import { ReactNode } from "react";

import { mapOrEmpty, specialChars } from "@joy/shared-utils";

import { Box } from "./box";
import { LinkButton, LinkButtonProps } from "./button";

export type EntityPropDetail<I> =
  | [
      icon: (props: { className: string }) => ReactNode,
      key: string,
      value: (item: I) => ReactNode,
      show?: (item: I) => boolean,
    ]
  | undefined;

const EntityDetail = <I,>({
  item,
  detail: [Icon, label, getter, show],
}: {
  item: I;
  detail: NonNullable<EntityPropDetail<I>>;
}) =>
  !show || show(item) ? (
    <div className="flex w-full min-w-0 items-start gap-2 text-sm">
      <Icon className="size-5 flex-none opacity-60" />
      <span className="sr-only">{label}</span>
      <span className="self-center truncate">
        {getter(item) || specialChars.endash}
      </span>
    </div>
  ) : null;

export type EntityProps<
  I,
  TFrom extends string,
  TTo extends string,
  E extends LinkButtonProps<TFrom, TTo> = LinkButtonProps<TFrom, TTo>,
  A extends (LinkButtonProps<TFrom, TTo> | undefined)[] = (
    | LinkButtonProps<TFrom, TTo>
    | undefined
  )[],
> = {
  title: string;
  edit?: E;
  item: I;
  details: EntityPropDetail<I>[][];
  actions?: A;
};

export const Entity = <
  I extends object | null | undefined,
  TFrom extends string,
  TTo extends string,
  E extends LinkButtonProps<TFrom, TTo>,
  A extends (LinkButtonProps<TFrom, TTo> | undefined)[],
>({
  title,
  edit,
  item,
  details,
  actions,
}: EntityProps<I, TFrom, TTo, E, A>) => (
  <Box
    variant="info"
    header={{ title, edit: edit && { ...edit, kind: "link", text: "Edit" } }}
  >
    <div className="min-w-0 divide-y divide-gray-200">
      {details.map((group, i) => (
        <div
          key={`group-${i}`}
          className="flex min-w-0 flex-col gap-4 px-2 py-3"
        >
          {group
            .filter((i) => !!i)
            .map((detail) => (
              <EntityDetail key={detail[1]} item={item} detail={detail} />
            ))}
        </div>
      ))}
      {actions?.filter((a) => !!a).length ? (
        <div className="flex flex-col gap-4 px-2.5 py-4 text-sm">
          {actions
            .filter((a) => !!a)
            .map((a) => (
              <LinkButton key={a.text} {...a} />
            ))}
        </div>
      ) : undefined}
    </div>
  </Box>
);

export type EntityArrayProps<
  I,
  TFrom extends string,
  TTo extends string = "",
  E extends LinkButtonProps<TFrom, TTo> = LinkButtonProps<TFrom, TTo>,
> = {
  title: string;
  edit?: E;
  items: I[];
  detail: NonNullable<EntityPropDetail<I>>;
};

export const EntityArray = <
  I extends object | null | undefined,
  TFrom extends string,
  TTo extends string,
  E extends LinkButtonProps<TFrom, TTo>,
>({
  title,
  edit,
  items,
  detail,
}: EntityArrayProps<I, TFrom, TTo, E>) => (
  <Box
    variant="info"
    header={{ title, edit: edit && { ...edit, kind: "link", text: "Edit" } }}
  >
    <div className="min-w-0 divide-y divide-gray-200">
      <div className="flex min-w-0 flex-col gap-4 px-2 py-3">
        {mapOrEmpty(
          items,
          (item, i) => (
            <EntityDetail key={i} item={item} detail={detail} />
          ),
          <EntityDetail
            item={undefined}
            detail={[detail[0], detail[1], () => specialChars.endash]}
          />,
        )}
      </div>
    </div>
  </Box>
);
