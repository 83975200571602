import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import clsx from "clsx";
import { ReactNode, Suspense, createContext, useContext } from "react";

import { tw } from "@joy/shared-utils";

import { Button, ButtonProps } from "./button";
import { Form, FormError } from "./forms";
import { Loading } from "./navigation";
import { transitions } from "./transitions";

export type ModalContextProps = {
  show: boolean;
  onClose: () => void;
};

export const ModalContext = createContext<ModalContextProps>({
  show: true,
  onClose: () => {},
});

export type ModalProps = ModalContextProps & {
  children: ReactNode;
};

export const Modal = ({ children, show, onClose }: ModalProps) => (
  <ModalContext value={{ show, onClose }}>
    <Dialog className="relative z-10" open={show} onClose={onClose}>
      <DialogBackdrop
        transition
        className={clsx("fixed inset-0 bg-gray-500/75", transitions.fade)}
      />
      <Suspense>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            {children}
          </div>
        </div>
      </Suspense>
    </Dialog>
  </ModalContext>
);

const modalVariants = {
  action: tw`bg-sky-100 text-sky-600`,
  warning: tw`bg-orange-100 text-orange-600`,
  danger: tw`bg-red-100 text-red-600`,
};

export type ModalContentProps = {
  icon?: (props: { className: string }) => ReactNode;
  variant?: keyof typeof modalVariants;
  title?: string;
  description?: string;
  error?: Error | null;
  children?: ReactNode;
  onSubmit?: () => void;
  loading?: { show: boolean; text: string };
  buttons?: ButtonProps[];
};

export const ModalContent = ({
  icon: Icon,
  variant = "action",
  title,
  description,
  error,
  children,
  onSubmit,
  loading,
  buttons,
}: ModalContentProps) => {
  const { onClose } = useContext(ModalContext);

  return (
    <DialogPanel
      transition
      className="relative overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all data-closed:translate-y-4 data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-closed:sm:translate-y-0 data-closed:sm:scale-95"
    >
      <div className="sm:flex sm:items-start">
        <div
          className={clsx(
            "mx-auto flex size-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:size-10",
            modalVariants[variant],
          )}
        >
          {Icon && <Icon className="size-6" />}
        </div>
        <Form
          onSubmit={onSubmit}
          onReset={onClose}
          className="mt-3 min-w-0 flex-1 text-center sm:mt-0 sm:ml-4 sm:text-left"
        >
          <DialogTitle
            as="h3"
            className="text-base leading-6 font-semibold text-gray-900"
          >
            {title}
          </DialogTitle>
          {description && (
            <Description className="text-sm text-gray-500">
              {description}
            </Description>
          )}
          {children}
          <FormError error={error} />
          {!!buttons?.length && (
            <div className="mt-5 flex flex-wrap gap-x-3 gap-y-2 sm:mt-4 sm:flex-row-reverse">
              {buttons?.map((props) => (
                <Button
                  key={props.text}
                  {...props}
                  disabled={props.disabled || loading?.show}
                  className="w-full sm:w-auto"
                />
              ))}
            </div>
          )}
        </Form>
      </div>
      {loading && <Loading {...loading} />}
    </DialogPanel>
  );
};
