import { toTitle } from "@joy/shared-utils";

import { ListField, SlideContent, TextField } from "../../components";
import { useAuth, useCreateUser } from "../../data";
import { UserConfirmContent } from "./confirm";

export const InviteContent = () => {
  const { hasTeamPermission } = useAuth();
  const { confirming, setConfirming, error, form, validators } =
    useCreateUser();

  return (
    <>
      <UserConfirmContent
        show={confirming}
        user={form.getFieldValue("email")}
        role={form.getFieldValue("jlteam")}
        onSubmit={() => {
          setConfirming(false);
          form.setFieldValue("confirmed", true);
          form.handleSubmit();
        }}
        onClose={() => setConfirming(false)}
      />
      <SlideContent
        title="Invite user"
        description="Enter an email address to invite a user to Joylevel."
        error={error}
        onSubmit={form.handleSubmit}
        loading={{ show: form.state.isSubmitting, text: "Creating..." }}
        buttons={[
          { text: "Cancel", variant: "cancel", type: "reset" },
          { text: "Invite", type: "submit", variant: "action" },
        ]}
      >
        <form.Field
          name="email"
          validators={validators.email}
          children={(field) => (
            <TextField
              field={field}
              label="Email address"
              inputMode="email"
              autoFocus
            />
          )}
        />
        <form.Field
          name="firstName"
          children={(field) => (
            <TextField field={field} label="First name" autoFocus />
          )}
        />
        <form.Field
          name="lastName"
          children={(field) => <TextField field={field} label="Last name" />}
        />
        <form.Field
          name="jlteam"
          validators={validators.jlteam}
          children={(field) => (
            <ListField
              field={field}
              label="Role"
              options={validators.jlteam.onSubmit._def.values}
              optionLabel={(o) => toTitle(o)}
              optionVisible={(o) => {
                if (o === "god") return false;
                if (o === "super") return hasTeamPermission("god");
                if (o === "admin") return hasTeamPermission("super");
                return true;
              }}
            />
          )}
        />
      </SlideContent>
    </>
  );
};
