import ADC from "./ADC.svg";
import Colibri from "./Colibri.webp";
import google from "./Google.svg";
import JoyGauge from "./JoyGauge.webp";
import Joylevel from "./JoyLevel.svg";
import MarkerPoint from "./MarkerPoint.svg";
import microsoft from "./Microsoft.svg";
import Pressure from "./Pressure.svg";
import ProGauge from "./ProGauge.webp";
import RMSDelta from "./RMSDelta.webp";
import Radar from "./Radar.svg";
import SmartFill from "./SmartFill.webp";
import Temperature from "./Temperature.svg";
import TemperatureAndPressure from "./TemperatureAndPressure.svg";
import Ultrasonic from "./Ultrasonic.svg";
import Unknown from "./Unknown.svg";

export const brandIcons = {
  Colibri,
  ProGauge,
  JoyGauge,
  RMSDelta,
  SmartFill,
};

export const logo = Joylevel;

export const loginIcons = {
  google,
  microsoft,
};

export const icons = {
  MarkerPoint,
};

export const technologyIcons = {
  ADC,
  Pressure,
  Radar,
  Temperature,
  TemperatureAndPressure,
  Ultrasonic,
  Unknown,
};
