import {
  IconBuildingFactory,
  IconDroplets,
  IconFileInvoice,
  IconHistory,
  IconUsers,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import {
  EntityUpdated,
  ItemHeader,
  NotFound,
  Tabs,
  pageParts,
} from "../../../components";
import { customerQuery, singleItemLoader, useAuth } from "../../../data";
import {
  ChangeHistoryPanel,
  CustomerAccountsPanel,
  CustomerActionPanel,
  CustomerInfoPanel,
  CustomerProductsPanel,
  CustomerUsersPanel,
} from "../../../panels";
import { CustomerInvoicesPanel } from "../../../panels/customer/invoices";

const validateSearch = z.object({
  action: z.enum(["add", "edit", "delete"]).optional(),
  entity: z.enum(["user", "account", "product"]).optional(),
  role: z.string().optional(),
  product: z.string().optional(),
});

export const Route = createFileRoute("/_admin/customers/$customerId")({
  validateSearch,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, customerQuery(params.customerId)),
  component: Component,
  notFoundComponent: () => (
    <NotFound
      item="Customer"
      action={{ to: "/customers", search: { action: "create" } }}
      actionLabel="Add customer"
    />
  ),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData?.name,
      },
    ],
  }),
});

export function Component() {
  const customerId = Route.useParams({ select: (p) => p.customerId });
  const { data } = useSuspenseQuery(customerQuery(customerId));
  const { hasTeamPermission } = useAuth();

  return (
    <div className={pageParts.page}>
      <ItemHeader title={data.name} subtitle={<EntityUpdated {...data} />} />
      <Tabs
        name="customer"
        tabs={[
          {
            name: "Accounts",
            icon: IconBuildingFactory,
            panel: <CustomerAccountsPanel customerId={customerId} />,
          },
          {
            name: "Users",
            icon: IconUsers,
            panel: <CustomerUsersPanel customerId={customerId} />,
          },
          {
            name: "Invoices",
            icon: IconFileInvoice,
            panel: <CustomerInvoicesPanel customerId={customerId} />,
          },
          {
            name: "Products",
            icon: IconDroplets,
            panel: <CustomerProductsPanel customerId={customerId} />,
          },
          {
            icon: IconHistory,
            name: "History",
            hidden: !hasTeamPermission("admin"),
            panel: (
              <ChangeHistoryPanel entityType="customer" entityId={customerId} />
            ),
          },
        ]}
        info={<CustomerInfoPanel customerId={customerId} />}
      />
      <CustomerActionPanel />
    </div>
  );
}
