import { useNavigate } from "@tanstack/react-router";

import { TablePage } from "../../components";
import { product, productsQuery, usePrefetchProduct } from "../../data";
import { useTable } from "../../hooks";
import { productColumns } from "../product";

export const CustomerProductsPanel = ({
  customerId,
}: {
  customerId: string;
}) => {
  const navigate = useNavigate();
  const prefetchProduct = usePrefetchProduct();

  const products = useTable(productsQuery(customerId), {
    columnDefs: productColumns({ customerId, prefetchProduct, navigate }),
    initialSort: [{ id: "Manage", desc: true }],
    create: {
      to: "/customers/$customerId",
      params: { customerId },
      search: { action: "add", entity: "product" },
      replace: true,
    },
    statLocation: "filters",
    word: product,
  });

  return <TablePage {...products.page} />;
};
