import {
  DefaultError,
  InfiniteData,
  QueryClient,
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { notFound } from "@tanstack/react-router";
import { GraphQLError } from "graphql";

export const singleItemLoader = async <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryClient: QueryClient,
  query: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) => {
  try {
    const result = await queryClient.ensureQueryData(query);
    const data = query.select ? query.select(result) : result;

    if (!data || !(data as { id?: string }).id) throw notFound();

    return data as TData;
  } catch (e) {
    if (e instanceof GraphQLError && e.extensions?.code === "NOT_FOUND")
      throw notFound();
    throw e;
  }
};

export const listLoader = <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
>(
  queryClient: QueryClient,
  query: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryData,
    TQueryKey,
    TPageParam
  >,
): Promise<InfiniteData<TQueryData, TPageParam>> =>
  queryClient.getQueryData(query.queryKey) ??
  queryClient.fetchInfiniteQuery(query);
