import { createContext, useContext, useEffect, useState } from "react";
import { z } from "zod";

import { useStorage } from "./storage";

export type TabContextProps = {
  debugging: boolean;
  selectedIndex: number;
  infoDisabled: boolean;
};

export const TabContext = createContext<TabContextProps>({
  debugging: false,
  selectedIndex: 0,
  infoDisabled: false,
});

export const useTabContext = () => useContext(TabContext);

const tabSchema = z.number();

export const useSetupTab = ({
  name,
  tabs,
}: {
  name: string;
  tabs: { hidden?: boolean }[];
}): {
  context: TabContextProps;
  setDebugging: (debugging: boolean) => void;
  setSelectedIndex: (selectedIndex: number) => void;
  setInfoDisabled: (infoDisabled: boolean) => void;
} => {
  const [debugging, setDebugging] = useState(false);
  const [selectedIndex, setSelectedIndex] = useStorage({
    defaultValue: 0,
    schema: tabSchema,
    key: `tabs:${name}`,
    storage: "session",
  });

  const [infoDisabled, setInfoDisabled] = useState(false);
  useEffect(() => {
    if (tabs[selectedIndex]?.hidden) setSelectedIndex(0);

    const isLarge = window.matchMedia("(min-width: 1024px)");
    const listener = () => {
      setInfoDisabled(isLarge.matches);
      if (isLarge.matches)
        setSelectedIndex((prev) => (prev >= tabs.length ? 0 : prev));
    };
    listener();

    isLarge.addEventListener("change", listener);
    return () => isLarge.removeEventListener("change", listener);
  }, []);

  return {
    context: {
      debugging,
      selectedIndex,
      infoDisabled,
    },
    setDebugging,
    setSelectedIndex,
    setInfoDisabled,
  };
};
