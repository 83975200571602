import { IconFileText } from "@tabler/icons-react";
import { standardSchemaValidator, useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { convertLexical } from "../../components";
import { useValidators } from "../../hooks";
import { Word } from "../helpers";
import { request, requestFn } from "./base";
import {
  CreateDocumentDocument,
  DeleteDocumentDocument,
  DocumentDocument,
  DocumentsDocument,
  UpdateDocumentDocument,
} from "./operations.generated";

export const document: Word = {
  icon: IconFileText,
  article: "a",
  singular: "document",
  plural: "documents",
};

export const documentsQuery = () =>
  infiniteQueryOptions({
    queryKey: ["documents"],
    queryFn: ({ pageParam }) =>
      request(DocumentsDocument, { limit: 50, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.documents.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.documents.documents),
  });

export const documentQuery = (id: string) =>
  queryOptions({
    queryKey: ["document", id],
    queryFn: () => request(DocumentDocument, { id }),
    select: (data) => data.document!,
  });

const validation = {
  id: z.string().regex(/^[a-z-]+$/, "Must be lowercase letters and dashes"),
  title: z.string().min(1, "Please enter a document title"),
};

const createDocumentFn = requestFn(CreateDocumentDocument);

export const useCreateDocument = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: createDocumentFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        documentQuery(result.createDocument.id).queryKey,
        {
          __typename: "Query",
          document: result.createDocument,
        },
      );
      await navigate({
        to: "/library/documents/$documentId",
        params: { documentId: result.createDocument.id },
      });
      queryClient.invalidateQueries(documentsQuery());
    },
  });
  const form = useForm({
    defaultValues: {
      id: "",
      title: "",
    },
    onSubmit: ({ value: { id, title } }) =>
      mutateAsync({ id, fields: { title } }),
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { error, form, validators };
};

const updateDocumentFn = requestFn(UpdateDocumentDocument);

export const useUpdateDocument = (documentId: string) => {
  const queryClient = useQueryClient();
  const { data } = useSuspenseQuery(documentQuery(documentId));
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateDocumentFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        documentQuery(result.updateDocument.id).queryKey,
        {
          __typename: "Query",
          document: result.updateDocument,
        },
      );
      queryClient.setQueryData(documentsQuery().queryKey, (existing) => {
        if (!existing) return undefined;

        return {
          ...existing,
          pages: existing.pages.map((p) => ({
            ...p,
            documents: {
              ...p.documents,
              documents: p.documents.documents.map((u) => {
                if (u.id === result.updateDocument?.id)
                  return result.updateDocument;
                return u;
              }),
            },
          })),
        };
      });
    },
  });
  const form = useForm({
    defaultValues: {
      title: data.title,
      content: data.content || "",
    },
    onSubmit: async ({ value }) => {
      const html = await convertLexical(value.content, "html");
      await mutateAsync({ id: documentId, fields: { ...value, html } });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

const deleteDocumentFn = requestFn(DeleteDocumentDocument);

export const useDeleteDocument = (documentId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(documentQuery(documentId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteDocumentFn,
    onSuccess: async (result, { id }) => {
      if (result.deleteDocument) {
        queryClient.setQueryData(documentsQuery().queryKey, (existing) => {
          if (!existing) return undefined;

          return {
            ...existing,
            pages: existing.pages.map((p) => ({
              ...p,
              documents: {
                ...p.documents,
                documents: p.documents.documents.filter((u) => u.id !== id),
              },
            })),
          };
        });

        await navigate({ to: "/library/documents" });
        queryClient.removeQueries(documentQuery(id));
      }
    },
  });

  return {
    data,
    error,
    isPending,
    onDelete: () => mutateAsync({ id: documentId }),
  };
};
