import {
  IconAsterisk,
  IconBarcode,
  IconBasket,
  IconBriefcase,
  IconBuildingFactory,
  IconBusinessplan,
  IconCalendar,
  IconGauge,
  IconHistory,
  IconKey,
  IconLink,
  IconSquareRoundedPlus,
  IconTimezone,
  IconTrashX,
  IconUsers,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { format } from "date-fns";

import { displayDays, pluralize } from "@joy/shared-utils";

import {
  Entity,
  EntityArray,
  EntityCreated,
  EntityPropDetail,
  EntityProps,
  EntityUpdated,
  LinkButton,
  pageParts,
} from "../../components";
import {
  AccountProduct,
  AccountQuery,
  accountQuery,
  useAuth,
} from "../../data";
import { CommentsPanel } from "../comments";

type AccountConfig = NonNullable<AccountQuery["account"]>["config"];

const configDetails: {
  [k in AccountProduct]: EntityPropDetail<
    AccountConfig & { __typename: `${k}Config` }
  >[][];
} = {
  Colibri: [[[IconLink, "url", (c) => c.url]]],
  JoyGauge: [
    [
      [
        IconGauge,
        "gauges",
        (c) => pluralize(c.gauges.gauges.length, "1 gauge", "gauges"),
      ],
    ],
  ],
  ProGauge: [
    [
      [IconUsers, "user", (c) => c.userId],
      [IconTimezone, "zone", (c) => c.timezone],
    ],
  ],
  RMSDelta: [[[IconBarcode, "serials", (c) => c.serialNumbers?.join(", ")]]],
  SmartFill: [
    [
      [IconKey, "reference", (c) => c.reference],
      [IconAsterisk, "secret", () => "**** **** ****"],
    ],
  ],
};

export const AccountInfoPanel = ({ accountId }: { accountId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { data } = useSuspenseQuery(accountQuery(accountId));

  return (
    <div className={pageParts.page}>
      <Entity
        title="Account Info"
        edit={
          hasTeamPermission("admin")
            ? {
                to: "/accounts/$accountId",
                params: { accountId },
                search: { action: "edit" },
                replace: true,
              }
            : undefined
        }
        item={data}
        details={[
          [
            [IconBuildingFactory, "Name", (c) => c.name],
            [IconBasket, "Product", (c) => c.product],
            hasTeamPermission("admin")
              ? [
                  IconBriefcase,
                  "Customer",
                  (c) => (
                    <LinkButton
                      kind="link"
                      variant="standard"
                      to="/customers/$customerId"
                      params={{ customerId: c.customer?.id || "" }}
                      text={c.customer?.name}
                    />
                  ),
                ]
              : undefined,
            hasTeamPermission("admin")
              ? [
                  IconBusinessplan,
                  "Plan",
                  (c) => (
                    <LinkButton
                      kind="link"
                      variant="standard"
                      to="/library/plans/$planId"
                      params={{ planId: c.plan?.id || "" }}
                      text={c.plan?.code}
                    />
                  ),
                ]
              : undefined,
          ],
          [
            [IconSquareRoundedPlus, "Created at", EntityCreated],
            [IconHistory, "Updated", EntityUpdated],
          ],
        ]}
        actions={[
          hasTeamPermission("admin")
            ? {
                kind: "link",
                variant: "danger",
                search: { action: "delete" },
                replace: true,
                icon: IconTrashX,
                text: "Delete Account",
              }
            : undefined,
        ]}
      />
      <EntityArray
        title="Scheduled Emails"
        edit={
          data.permissions.includes("manage")
            ? {
                to: "/accounts/$accountId",
                params: { accountId },
                search: { action: "schedule" },
                replace: true,
              }
            : undefined
        }
        items={data.schedules}
        detail={[
          IconCalendar,
          "schedule",
          (s) => {
            switch (s.interval) {
              case "Week":
                return `${displayDays(s.days, { asTitle: true })} at ${format(
                  new Date(1, 1, 1, s.startTime.hour, s.startTime.minute, 0, 0),
                  "h:mm aaa",
                )}`;
              case "Month":
                return format(
                  new Date(
                    1,
                    1,
                    s.date || 1,
                    s.startTime.hour,
                    s.startTime.minute,
                    0,
                    0,
                  ),
                  "do 'of the month at' h:mm aaa",
                );
            }
          },
        ]}
      />
      {hasTeamPermission("admin") && data.config && (
        <Entity
          title="Account Config"
          edit={{
            to: "/accounts/$accountId",
            params: { accountId },
            search: { action: "config" },
            replace: true,
          }}
          item={data.config}
          details={
            configDetails[data.product] as EntityProps<
              AccountConfig,
              string,
              string
            >["details"]
          }
        />
      )}
      {hasTeamPermission("admin") && (
        <CommentsPanel
          entityType="account"
          entityId={accountId}
          onDelete={{ to: "/accounts/$accountId", params: { accountId } }}
        />
      )}
    </div>
  );
};
