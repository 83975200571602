import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { IconInfoCircle } from "@tabler/icons-react";
import clsx from "clsx";
import { ReactNode, Suspense } from "react";

import { tw } from "@joy/shared-utils";

import { TabContext, useSetupTab } from "../hooks";

export type TabsProps = {
  name: string;
  tabs: {
    name: string;
    hidden?: boolean;
    icon: (props: { className: string }) => ReactNode;
    panel: ReactNode;
  }[];
  info: ReactNode;
};

const parts = {
  tab: tw`group flex cursor-pointer items-center gap-3 rounded-md px-3 py-2 text-sm font-medium whitespace-nowrap text-gray-500 outline-hidden transition-colors hover:bg-gray-100 hover:text-gray-700 data-selected:bg-emerald-200/30 data-selected:text-emerald-700`,
  panel: tw`min-h-0 flex-1`,
  icon: tw`size-6 flex-none`,
};

export const Tabs = ({ name, tabs, info }: TabsProps) => {
  const { context, setDebugging, setSelectedIndex } = useSetupTab({
    name,
    tabs,
  });

  return (
    <TabContext value={context}>
      <TabGroup
        className="flex min-h-0 flex-1 flex-col"
        selectedIndex={context.selectedIndex}
        onChange={setSelectedIndex}
      >
        <TabList className="container mx-auto flex shrink-0 gap-3 overflow-x-auto py-4">
          {tabs.map(({ name, hidden, icon: Icon }) => (
            <Tab
              disabled={hidden}
              key={name}
              className={clsx(parts.tab, hidden && "hidden")}
              onClick={(e) => {
                setDebugging(!!e.shiftKey);
              }}
            >
              <Icon className={parts.icon} />
              <span>{name}</span>
            </Tab>
          ))}
          <Tab
            disabled={context.infoDisabled}
            className={clsx(parts.tab, "lg:hidden")}
          >
            <IconInfoCircle className={parts.icon} />
            <span>Info</span>
          </Tab>
        </TabList>
        <div className="container mx-auto grid min-h-0 flex-1 grid-cols-3 items-stretch gap-4">
          <TabPanels className="col-span-3 flex min-h-0 flex-col lg:col-span-2">
            {tabs.map(({ name, panel }) => (
              <TabPanel className={parts.panel} key={name}>
                <Suspense>{panel}</Suspense>
              </TabPanel>
            ))}
            <TabPanel className={clsx(parts.panel, "lg:hidden")}>
              {info}
            </TabPanel>
          </TabPanels>
          <div className="hidden lg:col-span-1 lg:block">{info}</div>
        </div>
      </TabGroup>
    </TabContext>
  );
};
