import { IconLogs } from "@tabler/icons-react";
import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import { subHours } from "date-fns";

import { Word } from "../helpers";
import { request } from "./base";
import {
  LogGroupsDocument,
  LogsDocument,
  ParseDocument,
} from "./operations.generated";

export const log: Word = {
  article: "a",
  icon: IconLogs,
  plural: "logs",
  singular: "log",
};

export const logGroupsQuery = () =>
  infiniteQueryOptions({
    queryKey: ["logGroups"],
    queryFn: ({ pageParam }) =>
      request(LogGroupsDocument, {
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.logGroups.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.logGroups.logGroups),
  });

export const logsQuery = (
  group: string | undefined,
  { hoursAgo, filter }: { hoursAgo: number; filter?: string },
) =>
  infiniteQueryOptions({
    enabled: !!group,
    queryKey: ["logs", group, hoursAgo, filter],
    queryFn: ({ pageParam }) =>
      request(LogsDocument, {
        filter,
        from: subHours(new Date(), hoursAgo).toISOString(),
        group: group || "",
        cursor: pageParam || null,
        limit: 20,
      }),
    getNextPageParam: (lastPage) => lastPage?.logs.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.logs.logs),
  });

export const parseQuery = (type: string, data: string | undefined) =>
  queryOptions({
    enabled: !!data,
    queryKey: ["parse", type, data],
    queryFn: () => request(ParseDocument, { type, data: data || "" }),
  });
