import {
  Field as HeadlessField,
  FieldProps as HeadlessFieldProps,
  Label as HeadlessLabel,
  LabelProps as HeadlessLabelProps,
} from "@headlessui/react";
import clsx from "clsx";
import { Ref } from "react";

import { tw } from "@joy/shared-utils";

export const fieldParts = {
  row: tw`flex items-center gap-2`,
  checks: tw`flex flex-col gap-3 p-2`,
  group: tw`relative flex flex-col gap-3 overflow-hidden rounded-md bg-gray-100 p-3`,
};

export const fieldKinds = {
  column: tw`flex flex-1 flex-col items-stretch gap-2`,
  sub: tw`flex flex-1 flex-col items-stretch gap-1`,
};

type FieldProps = {
  kind?: keyof typeof fieldKinds;
};

export const Field = ({
  kind = "column",
  className,
  ...props
}: FieldProps & HeadlessFieldProps) => (
  <HeadlessField className={clsx(fieldKinds[kind], className)} {...props} />
);

export const labelKinds = {
  standard: tw`block self-start text-sm font-medium text-gray-900`,
  error: tw`block text-left text-sm font-normal text-red-700`,
  hint: tw`block text-left text-sm font-normal text-gray-500`,
};

type LabelProps = {
  ref?: Ref<HTMLLabelElement>;
  kind?: keyof typeof labelKinds;
};

export const Label = ({
  kind = "standard",
  className,
  ...props
}: LabelProps & HeadlessLabelProps) => (
  <HeadlessLabel className={clsx(labelKinds[kind], className)} {...props} />
);
