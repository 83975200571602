import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { format } from "date-fns";
import { z } from "zod";

import { downloadCSV, userName } from "@joy/shared-utils";

import { technologyIcons } from "../../../assets";
import {
  ListInput,
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  imageCell,
  stackCell,
} from "../../../components";
import { gauge, gaugesQuery, listLoader, useAuth } from "../../../data";
import { useTable } from "../../../hooks";
import { GaugesActionPanel } from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["upload"]).optional(),
});

export const Route = createFileRoute("/_user/gauges/")({
  validateSearch,
  loader: async ({ context }) => listLoader(context.queryClient, gaugesQuery()),
  component: Component,
});

const assignedOptions = {
  admin: ["All Gauges", "Unassigned", "Customer Only", "Assigned"],
  user: ["All Gauges", "Unassigned", "Assigned"],
} as const;

export function Component() {
  const { hasTeamPermission } = useAuth();
  const gauges = useTable(gaugesQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "technology",
        imageCell({ header: "", icons: technologyIcons }),
      ),
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: (g) => [[g.account?.name || "Unassigned", g.customer?.name]],
        }),
      ),
      c.accessor((g) => g.account?.name, {
        id: "assigned",
        header: "Account",
        meta: {
          className: clsx(
            cellKinds.appearsxl,
            "divide-x divide-gray-400 truncate",
          ),
        },
        filterFn: (
          row,
          _,
          filterValue: (typeof assignedOptions)[keyof typeof assignedOptions][number],
        ) => {
          if (hasTeamPermission("admin")) {
            if (filterValue === "Unassigned") return !row.original.customer;
            if (filterValue === "Customer Only")
              return !!row.original.customer && !row.original.account;
          } else {
            if (filterValue === "Unassigned") return !row.original.account;
          }

          if (filterValue === "Assigned") return !!row.original.account;

          return true;
        },
        cell: (v) =>
          [v.getValue() || "Unassigned", v.row.original.customer?.name]
            .filter((i) => !!i)
            .map((i, idx) => (
              <span className="px-1 first-of-type:pl-0" key={idx}>
                {i}
              </span>
            )),
      }),
      c.accessor(
        "observation.updatedAt",
        dateCell({
          header: "Last Active",
          sortUndefined: -1,
        }),
      ),
      c.accessor("updatedBy", {
        header: "By",
        meta: { className: cellKinds.appears },
        cell: (v) => userName(v.getValue()),
      }),
      c.display(
        actionCell({
          select: (gauge) => ({
            to: "/gauges/$gaugeId",
            params: { gaugeId: gauge.id },
          }),
        }),
      ),
      c.accessor((r) => r.account?.name || "", {
        id: "account_name",
      }),
      c.accessor("customer.name", {}),
    ],
    select: (gaugeId) => ({
      to: "/gauges/$gaugeId",
      params: { gaugeId },
    }),
    download: (filtered) =>
      downloadCSV(filtered, {
        cells: [
          "id",
          "name",
          "detail.model",
          "technology",
          "customer.name",
          "account.name",
          "observation.updatedAt",
          "observation.reading",
          "observation.voltage",
          "observation.temperature",
          "observation.signal",
          "observation.rssi",
          "observation.src",
        ],
        headers: {
          id: "ID",
          name: "Gauge",
          "detail.model": "Gauge Model",
          technology: "Technology",
          "customer.name": "Customer",
          "account.name": "Account",
          "observation.updatedAt": "Latest Update",
          "observation.reading": "Reading",
          "observation.voltage": "Voltage",
          "observation.temperature": "Temperature",
          "observation.signal": "Signal",
          "observation.rssi": "RSSI",
          "observation.src": "SRC",
        },
        formats: {
          "observation.updatedAt": "dateTime",
        },
        visibility: {
          "customer.name": hasTeamPermission("admin"),
          "observation.rssi": hasTeamPermission("admin"),
          "observation.src": hasTeamPermission("admin"),
        },
        file: `gauges-${format(new Date(), "yyyy-MM-dd")}`,
      }),
    initialVisibility: {
      account_name: false,
      customer_name: false,
    },
    statLocation: "header",
    statFns: [
      (filtered) => ({
        label: "Unassigned",
        value: filtered.filter((g) => !g.account).length,
      }),
    ],
    create: hasTeamPermission("admin")
      ? {
          to: "/gauges",
          search: { action: "upload" },
          replace: true,
          text: "Upload gauges",
        }
      : undefined,
    word: gauge,
  });

  return (
    <TablePage
      {...gauges.page}
      filters={[
        ...gauges.page.filters,
        <ListInput
          key="assigned"
          kind="menu"
          className="min-w-44"
          onChange={(v) =>
            gauges.columnFilter.onChange([{ id: "assigned", value: v }])
          }
          options={
            assignedOptions[hasTeamPermission("admin") ? "admin" : "user"]
          }
          value={
            gauges.columnFilter.value.find((c) => c.id === "assigned")?.value ||
            "All Gauges"
          }
        />,
      ]}
      panel={<GaugesActionPanel />}
    />
  );
}
