import {
  FunctionComponent,
  LazyExoticComponent,
  ReactNode,
  Suspense,
} from "react";

import { tw } from "@joy/shared-utils";

import { StatProps, StatsFilter, StatsHeader } from "./stat";
import { Table, TableProps } from "./table";

export const pageParts = {
  page: tw`flex h-full min-h-0 flex-col gap-3`,
  menu: tw`container mx-auto flex flex-wrap items-start justify-between gap-1`,
  actions: tw`flex min-w-0 flex-wrap gap-1`,
  contents: tw`container mx-auto min-h-0 flex-1`,
};

export type ViewComponent<TData> =
  | FunctionComponent<TableProps<TData>>
  | LazyExoticComponent<FunctionComponent<TableProps<TData>>>;

const Views = <TData, TView extends string>({
  table,
  value,
  variant,
  views,
}: {
  table: TableProps<TData>;
  value: TView;
  variant?: TableProps<TData>["variant"];
  views?: Record<TView, ViewComponent<TData>>;
}) => {
  const View = (views && views[value]) || Table;

  return (
    <Suspense>
      <View {...table} variant={variant} />
    </Suspense>
  );
};

export type TablePageProps<TData, TView extends string = "table"> = {
  actions: ReactNode[];
  filters: ReactNode[];
  panel?: ReactNode;
  table: TableProps<TData>;
  view: TView;
  views?: Record<TView, ViewComponent<TData>>;
  variant?: TableProps<TData>["variant"];
  stats: StatProps[];
  statLocation: "header" | "filters";
};

export const TablePage = <TData, TView extends string = "table">({
  actions,
  filters,
  panel,
  table,
  view,
  views,
  variant,
  stats,
  statLocation,
}: TablePageProps<TData, TView>) => (
  <div className={pageParts.page}>
    {statLocation === "header" ? <StatsHeader stats={stats} /> : null}
    {filters?.length || statLocation === "filters" || actions?.length ? (
      <div className={pageParts.menu}>
        <div className={pageParts.actions}>
          {filters?.length ? filters : null}
          {statLocation === "filters" ? <StatsFilter stats={stats} /> : null}
        </div>
        <div className={pageParts.actions}>
          {actions?.length ? actions : null}
        </div>
      </div>
    ) : null}
    <div className={pageParts.contents}>
      <Views table={table} views={views} value={view} variant={variant} />
    </div>
    {panel}
  </div>
);
