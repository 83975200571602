import { brandIcons } from "../../assets";
import {
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  imageCell,
  stackCell,
} from "../../components";
import { account, customerAccountsQuery } from "../../data";
import { useTable } from "../../hooks";

export const CustomerAccountsPanel = ({
  customerId,
}: {
  customerId: string;
}) => {
  const accounts = useTable(customerAccountsQuery(customerId), {
    columnDefs: (c) => [
      c.accessor(
        "product",
        imageCell({
          header: "",
          icons: brandIcons,
        }),
      ),
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: (a) => [a.plan?.code],
        }),
      ),
      c.accessor("plan.code", {
        header: "Plan",
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor(
        "updatedAt",
        dateCell({
          header: "Updated",
        }),
      ),
      c.display(
        actionCell({
          select: (account) => ({
            to: "/accounts/$accountId",
            params: { accountId: account.id },
          }),
        }),
      ),
    ],
    select: (accountId) => ({
      to: "/accounts/$accountId",
      params: { accountId },
    }),
    create: {
      to: "/customers/$customerId",
      params: { customerId },
      search: { action: "add", entity: "account" },
    },
    statLocation: "filters",
    word: account,
  });

  return <TablePage {...accounts.page} />;
};
