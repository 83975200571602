import { ComboField, SlideContent, TextField } from "../../components";
import {
  customer,
  customersQuery,
  plan,
  plansQuery,
  useUpdateAccount,
} from "../../data";
import { useComboQuery } from "../../hooks";

export const AccountEditContent = ({ accountId }: { accountId: string }) => {
  const { data, error, form, validators } = useUpdateAccount(accountId);
  const customers = useComboQuery(customersQuery(), { word: customer });
  const plans = useComboQuery(plansQuery(), { word: plan });

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the account information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="name"
        validators={validators.name}
        children={(field) => (
          <TextField field={field} label="Account name" autoFocus />
        )}
      />
      <form.Field
        name="customer"
        validators={validators.customer}
        children={(field) => (
          <ComboField
            field={field}
            label="Customer"
            accessors={[(o) => o.name]}
            optionKey={(o) => o.id}
            optionLabel={(o) => o.name}
            immediate
            {...customers.combo}
          />
        )}
      />
      <form.Field
        name="plan"
        validators={validators.plan}
        children={(field) => (
          <ComboField
            field={field}
            label="Plan"
            accessors={[(o) => [o.code, o.description || o.code]]}
            optionKey={(o) => o.id}
            optionLabel={(o) =>
              `${o.code}${o.description ? ` (${o.description})` : ""}`
            }
            immediate
            {...plans.combo}
          />
        )}
      />
    </SlideContent>
  );
};
