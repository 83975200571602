import { IconArrowNarrowRight } from "@tabler/icons-react";
import { CellContext } from "@tanstack/react-table";

import { tw } from "@joy/shared-utils";

import { LinkButton, LinkNavigateProps } from "../button";

export const actionCell = <
  TData,
  TValue,
  TSelectFrom extends string,
  TSelectTo extends string,
>({
  select,
}: {
  select: (data: TData) => LinkNavigateProps<TSelectFrom, TSelectTo>;
}) => ({
  id: "actions",
  meta: { className: tw`w-0` },
  cell: (v: CellContext<TData, TValue>) => (
    <div onClick={(e) => e.stopPropagation()}>
      <LinkButton
        kind="menu"
        variant="action"
        icon={IconArrowNarrowRight}
        {...(select(v.row.original) as any)}
      />
    </div>
  ),
});
