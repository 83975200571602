import { IconKey } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { backgrounds } from "../../assets";
import { ItemHeader, Tabs, pageParts } from "../../components";
import { singleItemLoader, useAuth, userQuery } from "../../data";
import {
  ApiKeysPanel,
  ProfileActionPanel,
  ProfileInfoPanel,
} from "../../panels";

const validateSearch = z.object({
  action: z.enum(["add", "edit", "delete"]).optional(),
  entity: z.enum(["apiKey"]).optional(),
  apiKey: z.string().optional(),
});

export const Route = createFileRoute("/_user/profile")({
  validateSearch,
  loader: async ({ context }) =>
    singleItemLoader(context.queryClient, userQuery(context.auth.user?.id)),
  head: () => ({ meta: [{ title: "My Profile" }] }),
  component: Component,
});

export function Component() {
  const { user } = useAuth();
  const { data } = useSuspenseQuery(userQuery(user?.id));

  return (
    <div className={pageParts.page}>
      <ItemHeader
        image={data.picture || backgrounds.userFallback}
        title="Profile"
        subtitle={data.email}
      />
      <Tabs
        name="profile"
        tabs={[
          {
            name: "Keys & Codes",
            icon: IconKey,
            panel: (
              <ApiKeysPanel
                to="/profile"
                entityId={data.id}
                entityType="user"
              />
            ),
          },
        ]}
        info={<ProfileInfoPanel />}
      />
      <ProfileActionPanel />
    </div>
  );
}
