import { IconBucketDroplet, IconTemperatureSun } from "@tabler/icons-react";
import { useInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";
import { useMemo } from "react";

import {
  displayPercent,
  displaySignal,
  displayTemperature,
  roundFloat,
  specialChars,
} from "@joy/shared-utils";

import {
  Box,
  Chart,
  IndicatorRow,
  displayBattery,
  displaySignalStrength,
  pageParts,
} from "../../components";
import { gaugeMax, gaugeObservationQuery, gaugeQuery } from "../../data";
import { useChart } from "../../hooks";

export const GaugeObservationsPanel = ({ gaugeId }: { gaugeId: string }) => {
  const { data, isLoading } = useInfiniteQuery(
    gaugeObservationQuery(gaugeId, 14),
  );
  const {
    data: { technology, detail, observation },
  } = useSuspenseQuery(gaugeQuery(gaugeId));

  const maxReading = useMemo(
    () =>
      data?.length
        ? Math.max(gaugeMax[technology], ...data.map((d) => d.reading))
        : 100,
    [data, technology],
  );

  const { indicators, series, tooltip } = useChart({
    data,
    latest: observation,
    deps: [maxReading, technology, detail.unit],
    elements: (c) => [
      c.accessor((d) => d.reading, {
        label: "Reading",
        default: true,
        variant: "emerald",
        type: "area",
        format: (value) =>
          `${roundFloat(value, { precision: 3 })} ${detail.unit}`,
        chartValue: (value) => (value / maxReading) * 100,
        fallback: `${specialChars.endash} ${detail.unit}`,
        icon: <IconBucketDroplet />,
      }),
      c.accessor((d) => d.signal, {
        label: "Signal",
        default: true,
        variant: "purple",
        format: (value) => displaySignal(value),
        icon: (value) => displaySignalStrength(value),
        chartValue: (value) => (value ? (value / 30) * 100 : undefined),
      }),
      c.accessor((d) => d.temperature, {
        label: "Temperature",
        variant: "red",
        format: (value) => displayTemperature(value),
        icon: <IconTemperatureSun />,
      }),
      c.accessor((d) => d.voltage, {
        label: "Battery",
        variant: "sky",
        format: (value) => displayPercent(value),
        icon: (value) => displayBattery(value),
      }),
      c.accessor((d) => d.rssi, {
        label: "RSSI",
        teamPermission: "admin",
        variant: "indigo",
        format: (value) => value,
        icon: undefined,
      }),
      c.accessor((d) => d.src, {
        label: "SRC",
        teamPermission: "admin",
        variant: "slate",
        format: (value) => value,
        icon: undefined,
      }),
    ],
  });

  return (
    <div className={pageParts.page}>
      <Box header={{ title: "Current Status" }}>
        <IndicatorRow indicators={indicators} />
      </Box>
      <Box header={{ title: "History" }}>
        <Chart
          xScale={{
            type: "time",
            clamp: true,
          }}
          yScale={{ type: "linear", clamp: true, domain: [0, 100] }}
          yFormat={(d) => `${d}`}
          data={data}
          loading={isLoading}
          mock={() => {
            const end = new Date();
            return [
              {
                id: "",
                reading: 20,
                updatedAt: subDays(end, 14),
              },
              {
                id: "",
                reading: 60,
                updatedAt: subDays(end, 11),
              },
              {
                id: "",
                reading: 50,
                updatedAt: subDays(end, 6),
              },
              {
                id: "",
                reading: 85,
                updatedAt: subDays(end, 2),
              },
              {
                id: "",
                reading: 80,
                updatedAt: end,
              },
            ];
          }}
          series={series}
          tooltip={tooltip}
        />
      </Box>
    </div>
  );
};
