import ky from "ky";
import { z } from "zod";

import { teamPermissions } from "@joy/shared-utils";

import { AuthClient } from "./context";

export const tokenSchema = z.object({
  loggedIn: z
    .object({
      expiry: z.number(),
      token: z.string(),
      user: z.object({
        id: z.string(),
        email: z.string(),
        firstName: z.string().optional(),
        lastName: z.string().optional(),
        picture: z.string().optional(),
        jlteam: z.enum(teamPermissions).nullable(),
      }),
    })
    .optional()
    .nullable(),
});

export const setupLiveAuth = async (): Promise<AuthClient> => {
  const result = await ky.get("/api/auth").json();
  const { loggedIn } = tokenSchema.parse(result);
  let token = loggedIn?.token;
  let expiry = loggedIn?.expiry;

  return {
    user: loggedIn?.user,
    login: async (params) => {
      const query = new URLSearchParams({
        action: "login",
        host: window.location.host,
      });
      if (params.email) query.set("email", params.email);

      window.location.href = `/api/auth?${query.toString()}`;
    },
    logout: async () => {
      const query = new URLSearchParams({
        action: "logout",
        host: window.location.host,
      });

      window.location.href = `/api/auth?${query.toString()}`;
    },
    token: async () => {
      if (!token) return undefined;

      if (expiry && expiry > Math.floor(Date.now() / 1000)) return token;

      const attempt = await ky.get("/api/auth").json();
      const parsed = tokenSchema.parse(attempt);
      token = parsed.loggedIn?.token;
      expiry = parsed.loggedIn?.expiry;

      return token;
    },
  };
};
