import { IconAlertTriangle } from "@tabler/icons-react";

import { Modal, ModalContent } from "../../components";

export const UserConfirmContent = ({
  show,
  user,
  role,
  onSubmit,
  onClose,
}: {
  user: string;
  role: string;
  show: boolean;
  onSubmit: () => void;
  onClose: () => void;
}) => (
  <Modal show={show} onClose={onClose}>
    <ModalContent
      icon={IconAlertTriangle}
      variant="warning"
      title={`Set ${user} as ${role}?`}
      description={`Are you sure you want to give ${user} the role of ${role}? They will have access to all tanks, accounts, customers, plans and documents.`}
      onSubmit={onSubmit}
      buttons={[
        {
          text: "Update",
          type: "submit",
          variant: "action",
        },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  </Modal>
);
