import { toSubdomain } from "@joy/shared-utils";

declare const api_url: string;
declare const map_token: string;
declare const tracking_key: string;

declare global {
  const is_local: boolean | undefined;
}

export const config = {
  apiUrl: is_local ? api_url : `https://${toSubdomain("api")}`,
  mapToken: map_token,
  trackingKey: tracking_key,
};
