import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { IconAlignLeft, IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { SetStateAction } from "react";
import { z } from "zod";

import { useAuth } from "../../data";
import { useGlobalPanel, useStorage } from "../../hooks";
import { transitions } from "../transitions";
import { SidebarContent } from "./sidebar.content";

const expandedSchema = z.boolean();

export type SidebarProps = {
  open: boolean;
  setOpen: (open: SetStateAction<boolean>) => void;
};

export const Sidebar = ({ open, setOpen }: SidebarProps) => {
  const { logout, user } = useAuth();
  const { captureScreenshot, setPanel } = useGlobalPanel();
  const [expanded, setExpanded] = useStorage({
    defaultValue: false,
    key: "sidebar-expanded",
    schema: expandedSchema,
    storage: "local",
  });

  if (!user) return null;

  const contentOptions = {
    logout,
    openFeedback: () => {
      setOpen(false);
      captureScreenshot();
      setPanel("feedback");
    },
    user,
  };

  return (
    <>
      {/* Static Desktop Sidebar */}
      <aside
        aria-expanded={expanded}
        className="relative hidden h-full w-64 max-w-14 flex-col gap-y-5 overflow-x-hidden overflow-y-auto bg-emerald-950 p-2 transition-all aria-expanded:max-w-64 lg:flex"
      >
        <button
          className="flex size-10 shrink-0 cursor-pointer items-center justify-center text-gray-100"
          onClick={() => setExpanded((o) => !o)}
        >
          <IconAlignLeft size={30} aria-label="Toggle Sidebar" />
          <div className="sr-only">Toggle Sidebar</div>
        </button>
        <SidebarContent {...contentOptions} />
      </aside>

      {/* Overlay Mobile Sidebar */}
      <Dialog className="relative z-50 lg:hidden" open={open} onClose={setOpen}>
        <DialogBackdrop
          transition
          className={clsx("fixed inset-0 bg-gray-900/80", transitions.fade)}
        />
        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className={clsx(
              "group relative mr-16 flex w-full max-w-64 flex-1",
              transitions.sidebar,
            )}
          >
            <div
              className={clsx(
                "absolute top-0 left-full flex w-16 justify-center pt-5 duration-100 group-data-closed:opacity-0",
              )}
            >
              <button
                type="button"
                className="-m-2.5 p-2.5"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close sidebar</span>
                <IconX size={24} className="text-gray-100" />
              </button>
            </div>
            <aside
              aria-expanded={open}
              className="flex flex-1 flex-col gap-y-5 overflow-x-hidden overflow-y-auto bg-emerald-950 p-4 inset-shadow-sm transition-all"
            >
              <SidebarContent {...contentOptions} />
            </aside>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};
