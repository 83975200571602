import { useSuspenseQuery } from "@tanstack/react-query";

import { displayPrice, specialChars } from "@joy/shared-utils";

import { CellStack, TablePage, cellKinds } from "../../components";
import { fee, invoiceFeesQuery, invoiceQuery } from "../../data";
import { useTable } from "../../hooks";

export const InvoiceFeesPanel = ({ invoiceId }: { invoiceId: string }) => {
  const { data } = useSuspenseQuery(invoiceQuery(invoiceId));
  const fees = useTable(invoiceFeesQuery(invoiceId), {
    columnDefs: (c) => [
      c.accessor(
        (f) => [f.account.name, f.tank?.name].filter(Boolean).join(" - "),
        {
          id: "name",
          header: "Name",
          meta: { className: cellKinds.stacks },
          cell: (c) => (
            <CellStack
              value={c.getValue()}
              detail={[
                `${c.row.original.quantity - (c.row.original.prepaid || 0)} ${specialChars.multiply} ${displayPrice(c.row.original.amount)}`,
              ]}
            />
          ),
        },
      ),
      c.accessor("amount", {
        header: "Price",
        meta: { className: cellKinds.appearsxl },
        cell: (c) => displayPrice(c.getValue()),
      }),
      c.accessor("quantity", {
        header: "Quantity",
        meta: { className: cellKinds.appearsxl },
        cell: (c) =>
          `${c.getValue()} ${c.row.original.prepaid ? `(-${c.row.original.prepaid})` : ""}`,
      }),
      c.accessor("discount", {
        header: "Discount",
        meta: { className: cellKinds.appearsxl },
        cell: (c) => displayPrice(c.getValue()) || specialChars.endash,
        footer: displayPrice(data.adjustment) || specialChars.endash,
      }),
      c.accessor(
        (f) => f.amount * (f.quantity - (f.prepaid || 0)) - (f.discount || 0),
        {
          id: "total",
          header: "Total",
          cell: (c) => displayPrice(c.getValue()),
          footer: ({ table }) =>
            displayPrice(
              table
                .getRowModel()
                .flatRows.reduce(
                  (agg, r) => agg + (r.getValue("total") as number),
                  data.adjustment || 0,
                ),
            ),
        },
      ),
    ],
    canFilter: false,
    statLocation: "filters",
    word: fee,
  });

  return <TablePage {...fees.page} />;
};
