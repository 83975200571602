import { IconGridDots, IconMap, IconTable } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { format } from "date-fns";
import { z } from "zod";

import { downloadCSV, specialChars, sumVolume } from "@joy/shared-utils";

import { Grid, Locations, Table, TablePage } from "../../../components";
import { listLoader, tank, tanksQuery, useAuth } from "../../../data";
import { useTable } from "../../../hooks";
import { tankColumns } from "../../../panels";

export const Route = createFileRoute("/_user/tanks/")({
  loader: async ({ context }) => listLoader(context.queryClient, tanksQuery()),
  component: Component,
});

const viewSchema = z.enum(["grid", "table", "map"]);

export function Component() {
  const { hasTeamPermission } = useAuth();
  const tanks = useTable(tanksQuery(), {
    columnDefs: tankColumns,
    initialSort: [{ id: "level_updatedAt", desc: true }],
    statFns: [
      (filtered) => ({
        label: "Volume",
        value:
          sumVolume(filtered, (i) => [i.level?.value, i.unit]) ||
          specialChars.endash,
      }),
      (filtered) => ({
        label: "Ullage",
        value:
          sumVolume(filtered, (i) => [
            i.level ? i.level.maximum - i.level.value : undefined,
            i.unit,
          ]) || specialChars.endash,
      }),
    ],
    select: (tankId) => ({ to: "/tanks/$tankId", params: { tankId } }),
    download: (filtered) =>
      downloadCSV(filtered, {
        cells: [
          "id",
          "name",
          "account.name",
          "activatedAt",
          "account.customer.name",
          "account.plan.code",
          "tankProduct.label",
          "level.updatedAt",
          "level.value",
          "level.maximum",
          "level.percent",
          "level.voltage",
          "level.temperature",
        ],
        headers: {
          id: "ID",
          name: "Tank",
          "account.name": "Account",
          activatedAt: "Activation Date",
          "account.customer.name": "Customer",
          "account.plan.code": "Plan",
          "tankProduct.label": "Contents",
          "level.updatedAt": "Latest Update",
          "level.value": "Level",
          "level.maximum": "SFL",
          "level.percent": "Level Percent",
          "level.voltage": "Battery Percent",
          "level.temperature": "Temperature",
        },
        formats: {
          activatedAt: "date",
          "level.updatedAt": "dateTime",
        },
        visibility: {
          "account.customer.name": hasTeamPermission("admin"),
          "account.plan.code": hasTeamPermission("admin"),
        },
        file: `tanks-${format(new Date(), "yyyy-MM-dd")}`,
      }),
    initialVisibility: {
      customer: false,
      latitude: false,
      longitude: false,
    },
    statLocation: "header",
    view: {
      components: {
        table: Table,
        grid: Grid,
        map: Locations,
      },
      options: viewSchema,
      optionLabel: (o) => {
        const Icon = {
          table: IconTable,
          grid: IconGridDots,
          map: IconMap,
        }[o];
        return <Icon className="size-6 py-0.5" aria-label={o} />;
      },
      sortable: {
        grid: true,
      },
    },
    word: tank,
  });

  return <TablePage {...tanks.page} />;
}
