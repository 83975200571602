import { IconChartArea } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";

import { Box, Chart, pageParts } from "../../components";
import { GaugeCalculatorProps, GaugeProduct, gaugeQuery } from "../../data";
import { CippusCalculator } from "./calculator.cippus";
import { TekADCCalculator } from "./calculator.tekadc";
import { TekRadarCalculator } from "./calculator.tekradar";
import { TekUltrasonicCalculator } from "./calculator.tekultrasonic";

const Calculator = (props: GaugeCalculatorProps<GaugeProduct>) => {
  switch (props.config.__typename) {
    case "CippusConfig":
      return (
        <CippusCalculator {...(props as GaugeCalculatorProps<"Cippus">)} />
      );
    case "TekADCConfig":
      return (
        <TekADCCalculator {...(props as GaugeCalculatorProps<"TekADC">)} />
      );
    case "TekRadarConfig":
      return (
        <TekRadarCalculator {...(props as GaugeCalculatorProps<"TekRadar">)} />
      );
    case "TekUltrasonicConfig":
      return (
        <TekUltrasonicCalculator
          {...(props as GaugeCalculatorProps<"TekUltrasonic">)}
        />
      );
    default:
      return <></>;
  }
};

export const GaugeCalculatorPanel = ({ gaugeId }: { gaugeId: string }) => {
  const { data, isLoading } = useSuspenseQuery(gaugeQuery(gaugeId));

  return (
    <div className={pageParts.page}>
      <Box
        header={{
          title: "Volume Calculation",
          edit: {
            to: `/gauges/$gaugeId`,
            params: { gaugeId },
            search: { action: "config" },
            text: "Edit Configuration",
          },
        }}
      >
        <Calculator
          observation={data.observations.observations[0]}
          config={data.config}
          detail={data.detail}
          strappingTable={data.strappingTable}
        />
      </Box>
      <Box
        header={{
          title: "Strapping Table",
          edit: {
            to: `/gauges/$gaugeId`,
            params: { gaugeId },
            search: { action: "strapping" },
            text: "Edit Rows",
          },
        }}
      >
        <Chart
          xScale={{ type: "linear", zero: true }}
          xFormat={(d) => `${d} l`}
          yScale={{ type: "linear", zero: true }}
          yFormat={(d) => `${d} mm`}
          mock={() => [
            { mm: 0, l: 0 },
            { mm: 50, l: 400 },
            { mm: 100, l: 1000 },
          ]}
          loading={isLoading}
          data={data.strappingTable}
          empty={{
            icon: IconChartArea,
            text: "Strapping tables require at least two points to display.",
          }}
          series={[
            {
              key: "strap",
              label: "Strapping Table",
              default: true,
              variant: "sky",
              type: "area",
              xAccessor: (d) => d.l,
              yAccessor: (d) => d.mm,
            },
          ]}
          tooltip={({ item }) => (
            <p>
              <span className="font-semibold">{item.mm}</span> mm ={" "}
              <span className="font-semibold">{item.l}</span> l
            </p>
          )}
        />
      </Box>
    </div>
  );
};
