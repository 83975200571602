import { IconCheck, IconCopy } from "@tabler/icons-react";
import { useState } from "react";

import { delay } from "@joy/shared-utils";

import { useAsync } from "../hooks";
import { Button } from "./button";

export const Code = ({ value }: { value: string }) => {
  const [copied, setCopied] = useState(false);
  useAsync(async () => {
    await delay(1000);
    if (copied) setCopied(false);
  }, [copied]);

  return (
    <div className="relative rounded-md border border-gray-100 bg-gray-100 p-2 text-base">
      {value}
      <Button
        className="absolute right-1 top-1"
        kind="menu"
        variant={copied ? "brand" : "standard"}
        icon={copied ? IconCheck : IconCopy}
        onClick={() => {
          navigator.clipboard.writeText(value);
          setCopied(true);
        }}
      />
    </div>
  );
};
