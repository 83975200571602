import { IconAlertTriangle } from "@tabler/icons-react";

import { ModalContent } from "../../components";
import { ApiKeyEntityType, useDeleteApiKey } from "../../data";

export const ApiKeyDeleteContent = ({
  apiKeyId,
  apiKeyEntityId,
  apiKeyEntityType,
}: {
  apiKeyId: string;
  apiKeyEntityId: string;
  apiKeyEntityType: ApiKeyEntityType;
}) => {
  const { error, isPending, onDelete } = useDeleteApiKey({
    apiKeyId,
    entityId: apiKeyEntityId,
    entityType: apiKeyEntityType,
  });

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Revoke API Key?`}
      description={`Are you sure you want to revoke this api key? The api key will stop functioning immediately and this action cannot be undone.`}
      error={error}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Revoking..." }}
      buttons={[
        {
          text: "Remove",
          type: "submit",
          variant: "danger",
          autoFocus: true,
        },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
