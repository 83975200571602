import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxProps,
} from "@headlessui/react";
import {
  IconCheck,
  IconChevronDown,
  IconExclamationCircle,
} from "@tabler/icons-react";
import { FieldApi } from "@tanstack/react-form";
import clsx from "clsx";
import { ReactNode } from "react";

import {
  isNotNullOrUndefined,
  mapOrEmpty,
  specialChars,
} from "@joy/shared-utils";

import { transitions } from "../transitions";
import { FieldError } from "./error";
import { Field, Label, fieldKinds } from "./parts";
import { TextInputProps, inputKinds, inputParts, inputVariants } from "./text";

export type ListInputProps<D> = TextInputProps & {
  id?: string;
  placeholder?: string;
  empty?: string;
  optionKey?: (option: NonNullable<D>) => string;
  optionLabel?: (option: NonNullable<D>) => ReactNode;
  optionVisible?: (option: NonNullable<D>) => boolean;
  options: NonNullable<D>[] | readonly NonNullable<D>[];
  maxDisplay?: number;
};

export const ListInput = <D,>({
  id,
  className,
  icon: Icon,
  kind = "standard",
  variant = "action",
  placeholder,
  maxDisplay,
  empty,
  optionKey = (o) => `${o}`,
  optionLabel = (o) => `${o}`,
  optionVisible,
  options,
  ...props
}: ListInputProps<D> & ListboxProps<any, D>) => (
  <Listbox {...props}>
    <ListboxButton
      id={id}
      className={clsx(
        inputKinds[kind],
        inputVariants[variant],
        props.invalid && inputVariants.error,
        className,
      )}
    >
      {Icon && <Icon className={inputParts.icon} />}
      <div className={clsx(inputParts.wrapped, "truncate text-left")}>
        {props.multiple ? (
          <div className="flex gap-1">
            {mapOrEmpty(
              ((props.value as NonNullable<D>[]) || []).slice(0, maxDisplay),
              (v) => (
                <span className="rounded-md bg-gray-300 px-1">
                  {optionLabel(v)}
                </span>
              ),
              <span className="text-gray-400">
                {placeholder || specialChars.nbsp}
              </span>,
            )}
            {maxDisplay &&
              (props.value as NonNullable<D>[])?.length > maxDisplay && (
                <span className="text-gray-400">
                  +
                  {((props.value as NonNullable<D>[])?.length || 0) -
                    maxDisplay}
                </span>
              )}
          </div>
        ) : isNotNullOrUndefined(props.value) ? (
          optionLabel(props.value)
        ) : (
          <span className="text-gray-400">
            {placeholder || specialChars.nbsp}
          </span>
        )}
      </div>
      {props.invalid && (
        <IconExclamationCircle
          className={clsx(inputParts.icon, "text-red-700")}
        />
      )}
      <IconChevronDown className={inputParts.icon} />
    </ListboxButton>
    <ListboxOptions
      transition
      anchor="bottom"
      className={clsx(
        "z-20 w-[var(--button-width)] rounded-md border-0 bg-white ring-1 shadow-md ring-gray-200 transition-colors [--anchor-gap:4px] ring-inset focus:outline-hidden",
        transitions.fade,
      )}
    >
      {mapOrEmpty(
        options.filter((option) => !optionVisible || optionVisible(option)),
        (option) => (
          <ListboxOption
            key={optionKey(option)}
            value={option}
            className="group flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 select-none data-focus:bg-gray-300/20"
          >
            <IconCheck className="invisible size-4 fill-white group-data-selected:visible" />
            {optionLabel(option)}
          </ListboxOption>
        ),
        empty ? (
          <div className="flex items-center gap-2 rounded-md px-3 py-2 text-sm text-gray-500 italic">
            {empty}
          </div>
        ) : null,
      )}
    </ListboxOptions>
  </Listbox>
);

export const ListInputField = <D,>({
  field,
  ...props
}: {
  field: FieldApi<any, any, any, any, D>;
} & ListInputProps<D> &
  ListboxProps<any, D>) => (
  <ListInput
    id={field.name}
    name={field.name}
    value={field.state.value}
    onChange={(e) => field.handleChange(e)}
    invalid={field.state.meta.errors.length > 0}
    {...props}
  />
);

export const ListField = <D,>({
  field,
  label,
  fieldKind,
  ...rest
}: {
  field: FieldApi<any, any, any, any, D>;
  label: string;
  fieldKind?: keyof typeof fieldKinds;
} & ListInputProps<D> &
  ListboxProps<any, D>) => (
  <Field kind={fieldKind}>
    <Label htmlFor={field.name.toString()}>{label}</Label>
    <ListInputField field={field} {...rest} />
    <FieldError field={field} />
  </Field>
);
