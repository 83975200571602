import { Input } from "@headlessui/react";
import { FieldApi } from "@tanstack/react-form";
import clsx from "clsx";
import { useMemo } from "react";

import { useAsync } from "../../hooks";
import { ComboInput } from "./combo";
import { FieldError } from "./error";
import { Field, Label, fieldKinds } from "./parts";
import { inputKinds, inputParts, inputVariants } from "./text";

const ColourList = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const [colours] = useAsync(async () => {
    const response = await import("../../assets/data/colours.as2700.json");
    return response.default;
  }, []);

  const selectedValue = useMemo(
    () =>
      colours?.find(
        (c) => c.value.toLocaleUpperCase() === value.toLocaleUpperCase(),
      ) || null,
    [colours, value],
  );

  return (
    <ComboInput
      placeholder="Select an AS2700 colour"
      multiple={false}
      options={colours || []}
      accessors={[(c) => c.label]}
      optionKey={(c) => c.value}
      optionLabel={(c) => c.label}
      value={selectedValue}
      onChange={(c) => onChange(c?.value.toLocaleUpperCase() ?? "")}
    />
  );
};

export const ColourInput = ({
  id,
  name,
  value,
  onChange,
  kind = "standard",
  variant = "action",
}: {
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  kind?: keyof typeof inputKinds;
  variant?: keyof typeof inputVariants;
}) => (
  <div className="flex flex-col gap-1">
    <ColourList value={value} onChange={onChange} />
    <label className={clsx(inputKinds[kind], inputVariants[variant], "flex-1")}>
      <div className={inputParts.addon}>HEX</div>
      <Input
        id={id}
        name={name}
        className={clsx(inputParts.wrapped)}
        value={value}
        onChange={(e) =>
          onChange(
            `#${e.target.value
              .replace(/[^0-9a-f]/gi, "")
              .slice(0, 6)
              .toLocaleUpperCase()}`,
          )
        }
      />
      <input
        className="-mr-2 rounded-md"
        type="color"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </label>
  </div>
);

export const ColourInputField = ({
  field,
}: {
  field: FieldApi<any, any, any, any, string>;
}) => (
  <ColourInput
    id={field.name}
    name={field.name}
    value={field.state.value}
    onChange={(v) => field.handleChange(v)}
  />
);

export const ColourField = ({
  field,
  label,
  fieldKind,
}: {
  field: FieldApi<any, any, any, any, string>;
  label: string;
  fieldKind?: keyof typeof fieldKinds;
}) => {
  return (
    <Field kind={fieldKind}>
      <Label htmlFor={field.name.toString()}>{label}</Label>
      <ColourInputField field={field} />
      <FieldError field={field} />
    </Field>
  );
};
