import { IconDownload, IconKey, IconQrcode } from "@tabler/icons-react";

import { downloadDataUrl } from "@joy/shared-utils";

import {
  Button,
  Code,
  Field,
  Label,
  RadioField,
  SlideContent,
  TextField,
} from "../../components";
import { ApiKeyEntityType, useCreateApiKey } from "../../data";

const usageOptions = {
  API: {
    label: "API Key",
    icon: <IconKey className="size-5 text-blue-500" />,
  },
  QR: {
    label: "QR Code",
    icon: <IconQrcode className="size-5 text-blue-500" />,
  },
};

export const ApiKeyCreateContent = ({
  apiKeyEntityId,
  apiKeyEntityType,
}: {
  apiKeyEntityId: string;
  apiKeyEntityType: ApiKeyEntityType;
}) => {
  const { saved, error, form, validators } = useCreateApiKey({
    entityId: apiKeyEntityId,
    entityType: apiKeyEntityType,
  });

  return (
    <SlideContent
      title={`Add API Key`}
      description={`Create a new API key below.`}
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Adding..." }}
      buttons={
        saved
          ? [{ text: "Close", variant: "cancel", type: "reset" }]
          : [
              { text: "Cancel", variant: "cancel", type: "reset" },
              { text: "Add", type: "submit", variant: "action" },
            ]
      }
    >
      <form.Field
        name="label"
        validators={validators.label}
        children={(field) => (
          <TextField
            field={field}
            label="Purpose"
            readOnly={!!saved}
            disabled={!!saved}
          />
        )}
      />
      {!saved && (
        <form.Field
          name="usage"
          validators={validators.usage}
          children={(field) => (
            <RadioField
              field={field}
              label="Usage"
              options={validators.usage.onSubmit._def.values}
              optionLabel={(o) => (
                <div className="flex items-center gap-3 saturate-0 group-hover/radio:saturate-100 group-data-checked/radio:saturate-100">
                  {usageOptions[o].icon}
                  <span>{usageOptions[o].label}</span>
                </div>
              )}
            />
          )}
        />
      )}
      {saved?.qrCode && (
        <div className="flex flex-col items-center gap-2">
          <img src={saved.qrCode} alt="QR Code" className="w-2xs" />
          <Button
            kind="link"
            variant="action"
            icon={IconDownload}
            text="Save QR Code"
            onClick={() =>
              downloadDataUrl(saved.qrCode || "", `${saved.label}.png`)
            }
          />
        </div>
      )}
      {saved?.apiKey && !saved?.qrCode && (
        <Field>
          <Label>API Key</Label>
          <Code value={saved.apiKey} />
          <Label kind="hint">
            Please copy this key and keep it in a secure location. Once you
            close this panel, you will no longer have access to the api key.
          </Label>
        </Field>
      )}
    </SlideContent>
  );
};
