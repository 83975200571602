import { IconLink, IconMail, IconUser } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";

import { isKeyOf, userName } from "@joy/shared-utils";

import { loginIcons } from "../../assets";
import { Entity } from "../../components";
import { useAuth, userQuery } from "../../data";

export const ProfileInfoPanel = () => {
  const { user } = useAuth();
  const { data } = useSuspenseQuery(userQuery(user?.id));

  return (
    <Entity
      title="User Info"
      edit={{
        search: { action: "edit" },
        replace: true,
      }}
      item={data}
      details={[
        [
          [IconMail, "Email", (u) => u.email],
          [IconUser, "Name", (u) => userName(u, { hideEmail: true })],
          [
            IconLink,
            "Identities",
            (u) =>
              u.identities ? (
                <div className="flex gap-3">
                  {u.identities.map((i) =>
                    isKeyOf(loginIcons, i) ? (
                      <img
                        key={i}
                        src={loginIcons[i]}
                        alt={i}
                        className="size-5"
                      />
                    ) : (
                      <span key={i}>{i}</span>
                    ),
                  )}
                </div>
              ) : undefined,
          ],
        ],
      ]}
    />
  );
};
