import { useMatch } from "@tanstack/react-router";

import { ActionsMatch } from "../../components";
import { CommentDeleteContent } from "../comments";
import { RoleAddContent, RoleDeleteContent, RoleEditContent } from "../role";
import { TankDownloadContent } from "./download";
import { TankEditContent } from "./edit";
import { TankAlertsContent } from "./edit.alerts";

export const TankActionPanel = () => {
  const actions = useMatch({
    from: "/_user/tanks/$tankId",
    select: (s) => {
      const props = {
        tankId: s.params.tankId,
        commentId: s.search.comment,
        roleId: s.search.role || "",
        entityId: s.params.tankId,
        entityType: "tank" as const,
      };

      switch (s.search.action) {
        case "add":
          return { Slide: RoleAddContent, props };
        case "delete":
          if (s.search.comment) return { Modal: CommentDeleteContent, props };
          if (s.search.role) return { Modal: RoleDeleteContent, props };
          return { props };
        case "download":
          return { Modal: TankDownloadContent, props };
        case "edit":
          if (s.search.role) return { Slide: RoleEditContent, props };
          return { Slide: TankEditContent, props };
        case "alerts":
          return { Slide: TankAlertsContent, props };
      }

      return { props };
    },
  });

  return <ActionsMatch actions={actions} />;
};
