import { useMemo } from "react";

import { ActionsMatch } from "../../components";
import { useGlobalPanel } from "../../hooks";
import { FeedbackContent } from "./feedback";

export const GlobalPanel = () => {
  const { panel, setPanel } = useGlobalPanel();

  const actions = useMemo(() => {
    const props = {};

    switch (panel) {
      case "feedback":
        return { Modal: FeedbackContent, props };
    }

    return { props };
  }, [panel]);

  return <ActionsMatch actions={actions} onClose={() => setPanel(undefined)} />;
};
