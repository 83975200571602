import { formatDate } from "date-fns";
import {
  DependencyList,
  ReactNode,
  isValidElement,
  useCallback,
  useMemo,
} from "react";

import { isNullOrUndefined, specialChars } from "@joy/shared-utils";

import { CheckVariant, IndicatorProps, SeriesProps } from "../components";
import { TeamPermission, useAuth } from "../data";

type ChartData = { id: string; updatedAt: string | Date };

type AccessorFn<TData extends ChartData, TValue = unknown> = (
  data: TData,
) => TValue;

type ElementDef<TData extends ChartData, TValue = unknown> = {
  label: string;
  default?: boolean;
  teamPermission?: TeamPermission;
  className?: string;
  variant: CheckVariant;
  type?: "line" | "area";
  value: AccessorFn<TData, TValue>;
  format: (value: TValue, data: TData) => ReactNode;
  labelFormat?: (value: TValue, data: TData) => ReactNode;
  fallback?: ReactNode;
  chartValue?: (value: TValue) => number | undefined;
  icon: ReactNode | undefined | ((value: TValue) => ReactNode | undefined);
};

type TValueFromAccessor<
  TData extends ChartData,
  TAccessor extends AccessorFn<TData>,
> = TAccessor extends AccessorFn<TData, infer TReturn> ? TReturn : never;

type ElementHelper<TData extends ChartData> = {
  accessor: <TAccessor extends AccessorFn<TData>>(
    accessor: TAccessor,
    element: Omit<
      ElementDef<TData, TValueFromAccessor<TData, TAccessor>>,
      "value"
    >,
  ) => ElementDef<TData, TValueFromAccessor<TData, TAccessor>>;
};

const createElementHelper = <
  TData extends ChartData,
>(): ElementHelper<TData> => ({
  accessor: (value, element) => ({
    ...element,
    value: value as AccessorFn<TData, TValueFromAccessor<TData, typeof value>>,
  }),
});

export const useChart = <TData extends ChartData>({
  data,
  latest,
  elements,
  deps,
}: {
  data: TData[] | undefined;
  latest: TData | undefined;
  elements: (c: ElementHelper<TData>) => ElementDef<TData, any>[];
  deps?: DependencyList;
}) => {
  const { hasTeamPermission } = useAuth();
  const defs = useMemo(
    () => elements(createElementHelper<TData>()),
    [...(deps || [])],
  );

  const indicators = useMemo(
    (): (IndicatorProps | undefined)[] =>
      defs.map((def) => {
        if (!def.icon) return undefined;
        const value = latest ? def.value(latest) : undefined;
        if (isNullOrUndefined(value) && !def.fallback) return undefined;

        const icon = isValidElement(def.icon)
          ? def.icon
          : typeof def.icon === "function"
            ? def.icon(value)
            : undefined;

        return {
          label: def.label,
          icon,
          value: latest ? def.format(value, latest) : def.fallback,
        };
      }),
    [defs, latest],
  );

  const series = useMemo(
    (): (SeriesProps<TData> | undefined)[] =>
      defs.map((def) => {
        if (!def.default && !data?.some((d) => def.value(d))) return undefined;
        if (def.teamPermission && !hasTeamPermission(def.teamPermission))
          return undefined;

        return {
          key: def.label,
          label: def.label,
          variant: def.variant,
          default: !!def.default,
          type: def.type || "line",
          xAccessor: (d) => new Date(d.updatedAt),
          yAccessor: (d) => {
            const value = def.value(d);
            return def.chartValue ? def.chartValue(value) : value;
          },
        };
      }),
    [defs, hasTeamPermission, data],
  );

  const tooltip = useCallback(
    ({ item }: { item: TData }): ReactNode => (
      <>
        <p className="font-semibold">
          {item.updatedAt
            ? formatDate(item.updatedAt, "LLLL d, haaa")
            : specialChars.endash}
        </p>
        {defs.map((def) => {
          if (def.teamPermission && !hasTeamPermission(def.teamPermission))
            return null;

          const value = def.value(item);
          if (isNullOrUndefined(value)) return null;

          return (
            <p key={def.label} className={def.className}>
              {def.labelFormat ? (
                def.labelFormat(value, item)
              ) : (
                <span className="font-semibold">{def.label}: </span>
              )}
              {def.format(value, item)}
            </p>
          );
        })}
      </>
    ),
    [defs],
  );

  return {
    indicators,
    series,
    tooltip,
  };
};
