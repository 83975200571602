import { IconAlertCircle, IconChevronDown } from "@tabler/icons-react";
import {
  RowData,
  Table as TanstackTable,
  flexRender,
} from "@tanstack/react-table";
import clsx from "clsx";
import { Ref } from "react";

import { specialChars, tw } from "@joy/shared-utils";

import {
  EmptyTable,
  EmptyTableProps,
  ErrorTable,
  cellKinds,
  cellVariants,
  tableParts,
} from "./cells";
import { Progress } from "./navigation";
import { text } from "./scheme";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string;
  }
}

export const tableVariants = {
  card: tw`rounded-xs bg-white ring-1 shadow-sm ring-black/5 sm:rounded-lg`,
  slide: tw`-mx-6`,
};

export type TableProps<I> = {
  table: TanstackTable<I>;
  container: {
    ref: Ref<HTMLDivElement | null>;
    itemRef: Ref<HTMLDivElement | null>;
    className?: string;
  };
  error?: Error | null;
  variant?: keyof typeof tableVariants;
  updating?: boolean;
  loading?: boolean;
  empty?: EmptyTableProps;
};

export const Table = <I,>({
  table,
  error,
  container,
  updating,
  loading,
  empty,
  variant = "card",
}: TableProps<I>) => (
  <div
    ref={container.ref}
    className={clsx(tableVariants[variant], container.className)}
  >
    <table className={tableParts.table}>
      <thead className={tableParts.head}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            <th className="w-5">
              <span className="absolute inset-x-0 bottom-0 border-b border-b-gray-300">
                <Progress
                  show={updating}
                  position="absolute bottom-0"
                  background="bg-gradient-to-t from-sky-600/40"
                />
              </span>
            </th>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                id={header.column.id}
                title={header.column.columnDef.header?.toString()}
                onClick={header.column.getToggleSortingHandler()}
                aria-sort={
                  (
                    {
                      asc: "ascending",
                      desc: "descending",
                      none: undefined,
                    } as const
                  )[header.column.getIsSorted() || "none"]
                }
                className={clsx(
                  text,
                  cellKinds.title,
                  header.column.columnDef.meta?.className,
                  header.column.getCanSort() && "cursor-pointer",
                )}
              >
                <span className="flex items-center justify-start gap-3">
                  <span className="min-w-0 truncate">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </span>
                  {header.column.getCanSort() && (
                    <IconChevronDown
                      className="size-5 flex-none rounded-xs p-0.5 opacity-0 transition-all group-hover:opacity-100 group-aria-desc:rotate-180 group-aria-sorted:bg-gray-100 group-aria-sorted:opacity-100"
                      stroke={2.4}
                    />
                  )}
                </span>
              </th>
            ))}
            <th className="w-5" />
          </tr>
        ))}
      </thead>
      <tbody>
        {!!error && (
          <tr>
            <td />
            <td colSpan={table.getAllLeafColumns().length}>
              <span className="flex flex-col items-center justify-center px-2 py-8">
                <ErrorTable icon={IconAlertCircle} error={error} />
              </span>
            </td>
            <td />
          </tr>
        )}
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            onClick={row.getToggleSelectedHandler()}
            className={clsx("relative", row.getCanSelect() && "cursor-pointer")}
          >
            <td />
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={clsx(
                  cellVariants.base,
                  cellVariants.padded,
                  cell.column.columnDef.meta?.className,
                )}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
            <td />
          </tr>
        ))}
        <tr ref={container.itemRef as Ref<HTMLTableRowElement>} />
        {loading &&
          Array.from({ length: 3 }, (_, i) => (
            <tr key={`loading-${i}`} className="relative">
              <td />
              {table.getAllLeafColumns().map((column) => (
                <td
                  key={column.id}
                  className={clsx(
                    cellVariants.base,
                    cellVariants.padded,
                    column.columnDef.meta?.className,
                  )}
                >
                  <span className="block animate-pulse rounded-md bg-gray-200 text-transparent">
                    {specialChars.nbsp}
                  </span>
                </td>
              ))}
              <td />
            </tr>
          ))}
        {!loading && !!empty && !error && table.getRowCount() === 0 && (
          <tr>
            <td />
            <td colSpan={table.getAllLeafColumns().length}>
              <span className="flex flex-col items-center justify-center px-2 py-8">
                <EmptyTable {...empty} />
              </span>
            </td>
            <td />
          </tr>
        )}
      </tbody>
      {table
        .getFooterGroups()
        .map((group) =>
          group.headers.map((header) => header.column.columnDef.footer),
        )
        .flat()
        .filter(Boolean).length ? (
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              <th />
              {footerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={clsx(
                    text,
                    cellKinds.footer,
                    header.column.columnDef.meta?.className,
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
              <th />
            </tr>
          ))}
        </tfoot>
      ) : null}
    </table>
  </div>
);
