import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";

import { request } from "./base";
import {
  FairbanksTanksDocument,
  FairbanksUsersDocument,
} from "./operations.generated";

export const fairbanksUsersQuery = () =>
  infiniteQueryOptions({
    queryKey: ["fairbanks-users"],
    queryFn: ({ pageParam }) =>
      request(FairbanksUsersDocument, {
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.fairbanksUsers.next,
    initialPageParam: "",
    select: (data) =>
      data.pages
        .flatMap((p) => p.fairbanksUsers.users)
        .map((value) => ({ value })),
  });

export const fairbanksTanksQuery = (userId?: string | null) =>
  queryOptions({
    queryKey: ["fairbanks-tanks", userId],
    queryFn: () => request(FairbanksTanksDocument, { userId: userId || "" }),
    staleTime: 1000 * 60 * 60,
    enabled: !!userId,
    select: (data) =>
      data.fairbanksTanks.map((tank) => ({
        id: tank.id,
        volume: tank.items[0]?.volume,
      })),
  });
