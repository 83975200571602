import {
  IconBriefcase,
  IconCalendar,
  IconFileInvoice,
  IconHistory,
  IconRefresh,
  IconSquareRoundedPlus,
  IconTrashX,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { format } from "date-fns";

import {
  Entity,
  EntityCreated,
  EntityUpdated,
  ExternalLink,
  LinkButton,
} from "../../components";
import { invoiceQuery } from "../../data";

export const InvoiceInfoPanel = ({ invoiceId }: { invoiceId: string }) => {
  const { data } = useSuspenseQuery(invoiceQuery(invoiceId));

  return (
    <Entity
      title="Invoice Info"
      item={data}
      details={[
        [
          [
            IconCalendar,
            "Month",
            (c) => format(new Date(c.year, c.month - 1), "MMMM yyyy"),
          ],
          [
            IconBriefcase,
            "Customer",
            (c) => (
              <LinkButton
                kind="link"
                variant="standard"
                to="/customers/$customerId"
                params={{ customerId: c.customer?.id || "" }}
                text={c.customer?.name}
              />
            ),
          ],
          [
            IconFileInvoice,
            "Number",
            (c) =>
              c.linkedInvoice && (
                <ExternalLink
                  kind="link"
                  variant="standard"
                  target="_blank"
                  href={c.linkedInvoice.link}
                  text={`${c.linkedInvoice.number} (${c.linkedInvoice.status})`}
                />
              ),
          ],
        ],
        [
          [IconSquareRoundedPlus, "Created at", EntityCreated],
          [IconHistory, "Updated", EntityUpdated],
        ],
      ]}
      actions={[
        {
          kind: "link",
          variant: "action",
          to: "/invoices/$monthYear/$invoiceId",
          search: { action: "regenerate" },
          replace: true,
          icon: IconRefresh,
          text: "Regenerate Invoice",
        },
        {
          kind: "link",
          variant: "danger",
          to: "/invoices/$monthYear/$invoiceId",
          search: { action: "delete" },
          replace: true,
          icon: IconTrashX,
          text: "Delete Invoice",
        },
      ]}
    />
  );
};
