import { ComboboxProps } from "@headlessui/react";
import { IconGlobe } from "@tabler/icons-react";
import { FieldApi } from "@tanstack/react-form";
import {
  UnusedSkipTokenInfiniteOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
import clsx from "clsx";
import { useState } from "react";

import { useDebounce } from "../../hooks";
import { ComboBase } from "./combo";
import { FieldError } from "./error";
import { Field, Label, fieldKinds } from "./parts";
import { TextInputProps, inputParts } from "./text";

type LookupInputProps<D> = TextInputProps & {
  id?: string;
  className?: string;
  invalid?: boolean;
  lookup: (
    search: string,
  ) => UnusedSkipTokenInfiniteOptions<any, any, NonNullable<D>[], any, any>;
  optionKey?: (option: NonNullable<D>) => string;
  optionLabel?: (option: NonNullable<D>) => string;
};

export const LookupInput = <D,>({
  lookup,
  optionKey,
  optionLabel,
  ...props
}: LookupInputProps<D> & ComboboxProps<D, false>) => {
  const [query, setQuery] = useState("");
  const debounced = useDebounce(() => query, 200, [query]);
  const { data, isLoading } = useInfiniteQuery(lookup(debounced));

  return (
    <ComboBase
      {...props}
      setQuery={setQuery}
      query={query}
      filtered={data || []}
      openButton={false}
      optionKey={optionKey}
      optionLabel={optionLabel}
      loading={isLoading}
      suffix={
        <IconGlobe
          className={clsx(
            inputParts.icon,
            isLoading ? "animate-pulse" : "opacity-40",
          )}
        />
      }
    />
  );
};

export const LookupInputField = <D,>({
  field,
  ...props
}: {
  field: FieldApi<any, any, any, any, D>;
} & LookupInputProps<D> &
  ComboboxProps<D, false>) => (
  <LookupInput
    id={field.name}
    name={field.name}
    value={field.state.value}
    onChange={(e) => field.handleChange(e as D)}
    invalid={field.state.meta.errors.length > 0}
    {...props}
  />
);

export const LookupField = <D,>({
  field,
  label,
  fieldKind,
  ...rest
}: {
  field: FieldApi<any, any, any, any, D>;
  label: string;
  fieldKind?: keyof typeof fieldKinds;
} & LookupInputProps<D> &
  ComboboxProps<D, false>) => (
  <Field kind={fieldKind}>
    <Label htmlFor={field.name.toString()}>{label}</Label>
    <LookupInputField field={field} {...rest} />
    <FieldError field={field} />
  </Field>
);
