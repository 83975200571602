import { Transition } from "@headlessui/react";
import clsx from "clsx";

import { transitions } from "../transitions";

export const Progress = ({
  show,
  position = "absolute top-0",
  background = "bg-gradient-to-b from-sky-600/40",
}: {
  show?: boolean;
  position?: string;
  background?: string;
}) => (
  <Transition show={show || false}>
    <div
      className={clsx("h-1 w-full overflow-hidden", transitions.fade, position)}
    >
      <div
        className={clsx("animate-indeterminate absolute inset-0", background)}
      />
    </div>
  </Transition>
);
