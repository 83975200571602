import {
  IconArrowNarrowRight,
  IconEdit,
  IconTrashX,
  IconUserScreen,
} from "@tabler/icons-react";
import { useNavigate } from "@tanstack/react-router";

import { toTitle, tw } from "@joy/shared-utils";

import { TablePage, menuCell, stackCell } from "../../components";
import {
  account,
  customer,
  permissionFields,
  tank,
  usePrefetchRole,
  userRolesQuery,
} from "../../data";
import { useTable } from "../../hooks";
import { permissionColumns } from "../role";

export const UserRolesPanel = ({ userId }: { userId: string }) => {
  const navigate = useNavigate();
  const prefetchRole = usePrefetchRole();
  const roles = useTable(userRolesQuery(userId), {
    columnDefs: (c) => [
      c.accessor("entityType", {
        header: "",
        meta: { className: tw`w-1` },
        cell: (v) => {
          const { icon: Icon } = { tank, account, customer }[v.getValue()];
          return <Icon className="mx-auto" />;
        },
      }),
      c.accessor(
        "entity.name",
        stackCell({
          header: "Item",
          always: true,
          detail: (v) => {
            switch (v.entity.__typename) {
              case "Customer":
                return [];
              case "Account":
                return [v.entity.customer?.name];
              case "Tank":
                return [
                  [v.entity.account?.name, v.entity.account?.customer?.name],
                ];
            }
          },
          smallDetail: (data) => [
            permissionFields
              .filter((f) => data.permissions.includes(f.name))
              .map((f) => f.label),
          ],
        }),
      ),
      ...permissionColumns(c),
      c.display(
        menuCell({
          onClick: ({ id }) => prefetchRole(id),
          items: [
            {
              icon: IconArrowNarrowRight,
              variant: "standard",
              text: (r) => `View ${toTitle(r.entityType)}`,
              onClick: (r) => {
                switch (r.entityType) {
                  case "tank":
                    return navigate({
                      to: "/tanks/$tankId",
                      params: { tankId: r.entityId },
                    });
                  case "account":
                    return navigate({
                      to: "/accounts/$accountId",
                      params: { accountId: r.entityId },
                    });
                  case "customer":
                    return navigate({
                      to: "/customers/$customerId",
                      params: { customerId: r.entityId },
                    });
                }
              },
            },
            {
              icon: IconEdit,
              text: "Update Permissions",
              variant: "standard",
              onClick: (r) =>
                navigate({
                  to: "/users/$userId",
                  params: { userId },
                  search: { action: "edit", role: r.id },
                  replace: true,
                }),
            },
            {
              icon: IconTrashX,
              text: "Remove Role",
              variant: "danger",
              onClick: (r) =>
                navigate({
                  to: "/users/$userId",
                  params: { userId },
                  search: { action: "delete", role: r.id },
                  replace: true,
                }),
            },
          ],
        }),
      ),
    ],
    create: {
      to: "/users/$userId",
      params: { userId },
      search: { action: "add", entity: "role" },
      replace: true,
    },
    statLocation: "filters",
    word: {
      icon: IconUserScreen,
      article: "a",
      singular: "permission",
      plural: "permissions",
    },
  });

  return <TablePage {...roles.page} />;
};
