import { useMatch } from "@tanstack/react-router";

import { ActionsMatch } from "../../components";
import { ApiKeyCreateContent, ApiKeyDeleteContent } from "../api-keys";
import { RoleAddContent, RoleDeleteContent, RoleEditContent } from "../role";
import { UserDeleteContent } from "./delete";
import { UserEditContent } from "./edit";
import { UserImpersonateContent } from "./impersonate";

export const UserActionPanel = () => {
  const actions = useMatch({
    from: "/_admin/users/$userId",
    select: (s) => {
      const props = {
        userId: s.params.userId,
        roleId: s.search.role || "",
        apiKeyId: s.search.apiKey || "",
        apiKeyEntityId: s.params.userId,
        apiKeyEntityType: "user" as const,
      };

      switch (s.search.action) {
        case "add":
          if (s.search.entity === "apiKey")
            return { Slide: ApiKeyCreateContent, props };
          if (s.search.entity === "role")
            return { Slide: RoleAddContent, props };
          return { props };
        case "delete":
          if (s.search.apiKey) return { Modal: ApiKeyDeleteContent, props };
          if (s.search.role) return { Modal: RoleDeleteContent, props };
          return { Modal: UserDeleteContent, props };
        case "edit":
          if (s.search.role) return { Slide: RoleEditContent, props };
          return { Slide: UserEditContent, props };
        case "impersonate":
          return {
            Modal: UserImpersonateContent,
            props,
          };
      }

      return { props };
    },
  });

  return <ActionsMatch actions={actions} />;
};
