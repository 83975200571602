import { IconBriefcase } from "@tabler/icons-react";
import { standardSchemaValidator, useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { useValidators } from "../../hooks";
import { Word } from "../helpers";
import { request, requestFn } from "./base";
import {
  BrandingThemesDocument,
  CreateCustomerDocument,
  CustomerAccountsDocument,
  CustomerDocument,
  CustomerInvoicesDocument,
  CustomersDocument,
  DeleteCustomerDocument,
  LinkedContactsDocument,
  UpdateCustomerDocument,
} from "./operations.generated";

export const customer: Word = {
  icon: IconBriefcase,
  article: "a",
  singular: "customer",
  plural: "customers",
};

export const customersQuery = (enable = true) =>
  infiniteQueryOptions({
    queryKey: ["customers"],
    queryFn: ({ pageParam }) =>
      request(CustomersDocument, { limit: 50, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.customers.next,
    initialPageParam: "",
    enabled: enable,
    select: (data) => data.pages.flatMap((p) => p.customers.customers),
  });

export const customerQuery = (id: string) =>
  queryOptions({
    queryKey: ["customer", id],
    queryFn: () => request(CustomerDocument, { id }),
    select: (data) => data.customer!,
  });

export const linkedContactsQuery = (search: string) =>
  infiniteQueryOptions({
    queryKey: ["linked-contacts", search],
    queryFn: ({ pageParam }) =>
      request(LinkedContactsDocument, {
        search,
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.linkedContacts.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.linkedContacts.contacts),
    enabled: search.length >= 3,
  });

export const brandingThemesQuery = () =>
  queryOptions({
    queryKey: ["branding-themes"],
    queryFn: () => request(BrandingThemesDocument),
    select: (data) => data.brandingThemes,
  });

export const customerAccountsQuery = (id?: string) =>
  infiniteQueryOptions({
    queryKey: ["customer-accounts", id],
    queryFn: ({ pageParam }) =>
      request(CustomerAccountsDocument, {
        id: id || "",
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.customerAccounts.next,
    initialPageParam: "",
    select: (data) =>
      data.pages.flatMap((p) => p.customerAccounts.accounts || []),
    enabled: !!id,
  });

export const customerInvoicesQuery = (id?: string) =>
  infiniteQueryOptions({
    queryKey: ["customer-invoices", id],
    queryFn: ({ pageParam }) =>
      request(CustomerInvoicesDocument, {
        id: id || "",
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.customer?.invoices.next,
    initialPageParam: "",
    select: (data) =>
      data.pages.flatMap((p) => p.customer?.invoices.invoices || []),
    enabled: !!id,
  });

const validation = {
  name: z.string().min(1, "Please enter a customer name"),
  interval: z.number().int().min(1).max(12),
};

const createCustomerFn = requestFn(CreateCustomerDocument);

export const useCreateCustomer = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: createCustomerFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        customerQuery(result.createCustomer.id).queryKey,
        {
          __typename: "Query",
          customer: result.createCustomer,
        },
      );
      await navigate({
        to: "/customers/$customerId",
        params: { customerId: result.createCustomer.id },
      });
      queryClient.invalidateQueries(customersQuery());
    },
  });

  const form = useForm({
    defaultValues: {
      name: "",
      interval: 1,
      linkedContact: null,
      brandingTheme: null,
    },
    onSubmit: ({ value: { name, interval, linkedContact, brandingTheme } }) =>
      mutateAsync({
        id: crypto.randomUUID(),
        fields: { name, interval, linkedContact, brandingTheme },
      }),
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { error, form, validators };
};

const updateCustomerFn = requestFn(UpdateCustomerDocument);

export const useUpdateCustomer = (customerId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(customerQuery(customerId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateCustomerFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        customerQuery(result.updateCustomer.id).queryKey,
        {
          __typename: "Query",
          customer: result.updateCustomer,
        },
      );
      queryClient.setQueryData(customersQuery().queryKey, (existing) => {
        if (!existing) return undefined;

        return {
          ...existing,
          pages: existing.pages.map((p) => ({
            ...p,
            customers: {
              ...p.customers,
              customers: p.customers.customers.map((u) => {
                if (u.id === result.updateCustomer?.id)
                  return result.updateCustomer;
                return u;
              }),
            },
          })),
        };
      });
      await navigate({ to: "/customers/$customerId", params: { customerId } });
    },
  });

  const form = useForm({
    defaultValues: {
      name: data.name || "",
      interval: data.interval,
      linkedContact: data.linkedContact,
      brandingTheme: data.brandingTheme,
    },
    onSubmit: async ({
      value: { name, interval, linkedContact, brandingTheme },
    }) => {
      await mutateAsync({
        id: customerId,
        fields: { name, interval, linkedContact, brandingTheme },
      });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

const deleteCustomerFn = requestFn(DeleteCustomerDocument);

export const useDeleteCustomer = (customerId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(customerQuery(customerId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteCustomerFn,
    onSuccess: async (result, { id }) => {
      if (result.deleteCustomer) {
        queryClient.setQueryData(customersQuery().queryKey, (existing) => {
          if (!existing) return undefined;

          return {
            ...existing,
            pages: existing.pages.map((p) => ({
              ...p,
              customers: {
                ...p.customers,
                customers: p.customers.customers.filter((u) => u.id !== id),
              },
            })),
          };
        });

        await navigate({ to: "/customers" });
        queryClient.removeQueries(customerQuery(id));
      }
    },
  });

  return {
    data,
    error,
    isPending,
    onDelete: () => mutateAsync({ id: customerId }),
  };
};
