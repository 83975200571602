import { IconEye, IconEyeOff, IconSearch } from "@tabler/icons-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Link, createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { z } from "zod";

import { pluralize } from "@joy/shared-utils";

import {
  Button,
  List,
  ListInput,
  TablePage,
  TextInput,
  Time,
  Tree,
  pageParts,
} from "../../../components";
import { log, logGroupsQuery, logsQuery } from "../../../data";
import { useDebounce, useTable } from "../../../hooks";

const validateSearch = z.object({
  group: z.string().optional(),
  hoursAgo: z.number().optional().default(1),
  filter: z.string().optional(),
});

export const Route = createFileRoute("/_god/dev/logs")({
  validateSearch,
  component: Component,
  head: () => ({ meta: [{ title: "Log Search" }] }),
});

const levelColours: Record<string, string> = {
  INFO: "bg-sky-100",
  ERROR: "bg-red-100",
  WARN: "bg-yellow-100",
  DEBUG: "bg-blue-100",
  INIT_START: "bg-orange-100",
  START: "bg-orange-100",
  END: "bg-orange-100",
  REPORT: "bg-orange-100",
};

export function Component() {
  const logGroups = useInfiniteQuery(logGroupsQuery());
  const navigate = Route.useNavigate();
  const { group, hoursAgo, filter } = Route.useSearch();

  const queryOptions = useDebounce(() => ({ hoursAgo, filter }), 500, [
    hoursAgo,
    filter,
  ]);
  const logs = useTable(logsQuery(group, queryOptions), {
    columnDefs: (c) => [
      c.accessor("timestamp", {
        meta: {
          className: "text-xs flex-1",
        },
        cell: (v) => <Time date={v.getValue()} />,
      }),
      c.accessor("requestId", {
        meta: {
          className: "text-xs",
        },
      }),
      c.accessor("ipAddress", {
        meta: {
          className: "text-xs",
        },
      }),
      c.accessor("level", {
        meta: {
          className: "text-xs",
        },
        filterFn: "arrIncludesSome",
        cell: (v) => {
          const value = v.getValue() || "INFO";
          return (
            <div
              className={clsx(
                "rounded-full px-3 py-0.5 tracking-wide",
                levelColours[value] || levelColours.INFO,
              )}
            >
              {value}
            </div>
          );
        },
      }),
      c.accessor("parsed", {
        meta: {
          className: "w-full",
        },
        cell: (v) =>
          v.getValue()?.map((item, idx) => (
            <Tree
              key={idx}
              className="text-sm"
              data={item}
              renderers={{
                input: (v) => (
                  <Link
                    to="/dev/parse"
                    search={{ type: "tektcp", data: v }}
                    target="_blank"
                    className="text-wrap break-all whitespace-pre-line underline decoration-dotted"
                  >
                    {v}
                  </Link>
                ),
              }}
            />
          )),
      }),
      c.accessor("content", {
        meta: {
          className: "w-full",
        },
        cell: (v) => {
          const [show, setShow] = useState(false);
          return (
            v.getValue() && (
              <div>
                <span
                  className={clsx(
                    "font-mono text-sm text-wrap break-all whitespace-pre-line",
                    show ? "" : "line-clamp-1",
                  )}
                >
                  {v.getValue()}
                </span>
                <Button
                  className="text-xs"
                  kind="menu"
                  icon={show ? IconEyeOff : IconEye}
                  text={show ? "Hide Log" : "Show Log"}
                  onClick={() => setShow(!show)}
                />
              </div>
            )
          );
        },
      }),
    ],
    view: {
      components: { list: List },
      options: z.enum(["list"]),
      sortable: {},
    },
    statLocation: "filters",
    canFilter: false,
    word: log,
  });

  const levelOptions = useMemo(
    () =>
      Array.from(
        logs.table.getColumn("level")?.getFacetedUniqueValues().keys() || [],
      ),
    [logs.table.getColumn("level")?.getFacetedUniqueValues()],
  );

  return (
    <div className={pageParts.page}>
      <div className={pageParts.menu}>
        <div className={pageParts.actions}>
          <ListInput
            key="group"
            kind="menu"
            className="min-w-80"
            options={logGroups.data || []}
            onChange={(v) =>
              navigate({ search: { group: v, filter, hoursAgo } })
            }
            value={group}
          />
          <ListInput
            key="hoursAgo"
            className="min-w-40"
            options={[1, 24, 168, 720]}
            optionLabel={(o) =>
              o > 24 && o % 24 === 0
                ? `Last ${pluralize(o / 24, "day")}`
                : `Last ${pluralize(o, "hour")}`
            }
            value={hoursAgo}
            onChange={(o) =>
              navigate({
                search: { filter, group, hoursAgo: o },
                replace: true,
              })
            }
          />
        </div>
      </div>
      {group ? (
        <TablePage
          {...logs.page}
          actions={[
            ...logs.page.actions,
            <ListInput
              key="levels"
              kind="menu"
              className="min-w-52"
              options={levelOptions}
              placeholder="Log level"
              empty="No log levels"
              onChange={(o) =>
                logs.columnFilter.onChange(
                  o.length ? [{ id: "level", value: o }] : [],
                )
              }
              value={
                (logs.columnFilter.value.find((f) => f.id === "level")?.value ||
                  []) as string[]
              }
              multiple
              maxDisplay={2}
            />,
          ]}
          filters={[
            <TextInput
              key="filter"
              kind="menu"
              className="w-80 max-w-full"
              icon={IconSearch}
              placeholder="Search logs..."
              value={filter}
              onChange={(e) =>
                navigate({
                  search: { filter: e.currentTarget.value, group, hoursAgo },
                })
              }
            />,
            ...logs.page.filters,
          ]}
        />
      ) : null}
    </div>
  );
}
