import { lazy } from "react";

import { buildDynamicImportError } from "../data";

export const Locations = lazy(async () => {
  try {
    const { Locations } = await import("./locations.lazy");
    return { default: Locations };
  } catch (error) {
    throw buildDynamicImportError(error);
  }
});
