export const roundFloat = (
  value: number,
  options?: { precision?: number; rounding?: "round" | "ceil" | "floor" },
) => {
  const precision = options?.precision ?? 2;
  const rounding = options?.rounding ?? "round";

  return (
    Math[rounding]((value + Number.EPSILON) * 10 ** precision) / 10 ** precision
  );
};
