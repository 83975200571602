import { FC } from "react";

import { SlideContent } from "../../components";
import {
  GaugeConfigContentProps,
  GaugeProduct,
  useUpdateGaugeConfig,
} from "../../data";
import { CippusContent } from "./config.cippus";
import { TekADCContent } from "./config.tekadc";
import { TekRadarContent } from "./config.tekradar";
import { TekUltrasonicContent } from "./config.tekultrasonic";

const configContent: Record<GaugeProduct, FC<GaugeConfigContentProps>> = {
  Cippus: CippusContent,
  TekADC: TekADCContent,
  TekRadar: TekRadarContent,
  TekUltrasonic: TekUltrasonicContent,
  Unregistered: () => <></>,
};

export const GaugeConfigContent = ({ gaugeId }: { gaugeId: string }) => {
  const { data, error, form, validators } = useUpdateGaugeConfig(gaugeId);

  const Fields = configContent[data.product];

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the gauge config below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <Fields form={form} validators={validators} />
    </SlideContent>
  );
};
