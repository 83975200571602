export const convertPrice = (amount: string | null | undefined) =>
  typeof amount !== "string"
    ? undefined
    : Math.round(parseFloat(amount) * 100 + Number.EPSILON);

export const unconvertPrice = (amount: number | null | undefined) =>
  typeof amount !== "number" ? "" : (amount / 100).toFixed(2);

export const clampValue = (
  value: number | null | undefined,
  [min, max]: [number, number],
) =>
  typeof value !== "number" ? undefined : Math.min(Math.max(value, min), max);

export type NumberRange<
  Start extends number,
  End extends number,
  Result extends number[] = [],
> = Result["length"] extends End
  ? Result[number]
  : NumberRange<Start, End, [...Result, Result["length"]]>;
