import { userName } from "@joy/shared-utils";

import {
  CheckInput,
  Field,
  Label,
  SlideContent,
  fieldParts,
} from "../../components";
import { permissionFields, useAuth, useUpdateRole } from "../../data";

export const RoleEditContent = ({ roleId }: { roleId: string }) => {
  const { data, error, form } = useUpdateRole(roleId);
  const { user } = useAuth();
  const selfError =
    data.user?.email === user?.email
      ? new Error("You cannot update your own access.")
      : undefined;

  return (
    <SlideContent
      title={`Update permissions for ${data.entityType}`}
      description={`Set the permissions for ${userName(data.user)}'s access to ${data.entity.name}`}
      error={error || selfError}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        {
          text: "Update",
          type: "submit",
          variant: "action",
          disabled: !!selfError,
        },
      ]}
    >
      <Field>
        <Label>Permissions</Label>
        <form.Field
          name="permissions"
          children={(field) => (
            <div className={fieldParts.checks}>
              {permissionFields
                .filter(({ visible }) => visible.includes(data.entityType))
                .map(({ name, label, description, disabled, includes }) => (
                  <CheckInput
                    key={name}
                    label={label}
                    description={description(data.entityType)}
                    disabled={disabled(field.state.value)}
                    checked={!!field.state.value[name]}
                    onChange={(checked) => {
                      const updated = { ...field.state.value };
                      if (checked) {
                        updated[name] = true;
                        for (const include of includes) {
                          updated[include] = true;
                        }
                      } else {
                        updated[name] = false;
                      }
                      field.setValue(updated);
                    }}
                  />
                ))}
            </div>
          )}
        />
      </Field>
    </SlideContent>
  );
};
