import { StrapTable, strappingCalculate, strappingMax } from "./strapping.js";

const measurementFactor = {
  mm: 1,
  b: 1,
  cm: 10,
  m: 1,
} as const;

export type TekMTBOptions = {
  detail: {
    unit: "mm" | "cm" | "b" | "m";
  };
  config: {
    mountToBottom: number;
    offsetVolume: number;
  };
  strappingTable: StrapTable;
};

export const tekMTBHeight = (
  reading: number,
  { config: { mountToBottom }, detail: { unit } }: TekMTBOptions,
) => mountToBottom - reading * measurementFactor[unit];

export const tekMTBVolume = (reading: number, options: TekMTBOptions) => {
  const volume = strappingCalculate(
    options.strappingTable,
    tekMTBHeight(reading, options),
    "mm",
  );

  return volume ? volume + options.config.offsetVolume : undefined;
};

export const tekMTBReverse = (volume: number, options: TekMTBOptions) => {
  const height = strappingCalculate(
    options.strappingTable,
    volume - options.config.offsetVolume,
    "l",
  );
  return height
    ? (options.config.mountToBottom - height) /
        measurementFactor[options.detail.unit]
    : undefined;
};

export const tekMTBLimit = (percent: number, options: TekMTBOptions) => {
  const max = strappingMax(options.strappingTable, "l");
  if (!max) return undefined;
  const volume = (percent / 100) * max;
  const reading = tekMTBReverse(volume, options);
  if (reading === undefined || reading < 0) return undefined;

  return Math.round(reading);
};

export const tekMTBRate = (
  percent: number,
  period: number,
  options: TekMTBOptions,
) => {
  const range = options.config.mountToBottom;
  const rate = (percent / 100) * range;
  return Math.max(Math.floor(rate / (period / 15)), 63);
};
