import { useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import { TablePage } from "../../components";
import { tankQuery, useAuth, usePrefetchRole, user } from "../../data";
import { rolesQuery } from "../../data/api/roles";
import { useTable } from "../../hooks";
import { roleColumns } from "../role";

export const TankUsersPanel = ({ tankId }: { tankId: string }) => {
  const navigate = useNavigate();
  const prefetchRole = usePrefetchRole();
  const { hasTeamPermission } = useAuth();
  const { data } = useSuspenseQuery(tankQuery(tankId));

  const roles = useTable(rolesQuery(tankId, "tank"), {
    columnDefs: roleColumns({
      prefetchRole,
      hasTeamPermission,
      navigate,
      current: { to: "/tanks/$tankId", params: { tankId } },
      permissions: data.permissions,
    }),
    create: data.permissions.includes("manage")
      ? {
          to: "/tanks/$tankId",
          params: { tankId },
          search: { action: "add" },
        }
      : undefined,
    statLocation: "filters",
    word: user,
  });

  return <TablePage {...roles.page} />;
};
