import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { userName } from "@joy/shared-utils";

import { brandIcons } from "../../../assets";
import {
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  imageCell,
  stackCell,
} from "../../../components";
import { account, accountsQuery, listLoader, useAuth } from "../../../data";
import { useTable } from "../../../hooks";

const validateSearch = z.object({
  action: z.enum(["create"]).optional(),
});

export const Route = createFileRoute("/_user/accounts/")({
  validateSearch,
  loader: async ({ context }) =>
    listLoader(context.queryClient, accountsQuery()),
  component: Component,
});

export function Component() {
  const { hasTeamPermission } = useAuth();
  const accounts = useTable(accountsQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "product",
        imageCell({
          header: "",
          icons: brandIcons,
        }),
      ),
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: (a) => [[a.customer?.name, a.plan?.code]],
        }),
      ),
      c.accessor("customer.name", {
        header: "Customer",
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor("plan.code", {
        header: "Plan",
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor(
        "updatedAt",
        dateCell({
          header: "Updated",
        }),
      ),
      c.accessor("updatedBy", {
        header: "By",
        meta: { className: cellKinds.appears },
        cell: (v) => userName(v.getValue()),
      }),
      c.display(
        actionCell({
          select: (account) => ({
            to: "/accounts/$accountId",
            params: { accountId: account.id },
          }),
        }),
      ),
    ],
    select: (accountId) => ({
      to: "/accounts/$accountId",
      params: { accountId },
    }),
    initialVisibility: {
      customer_name: hasTeamPermission("admin"),
      plan_code: hasTeamPermission("admin"),
    },
    statLocation: "header",
    create: hasTeamPermission("admin")
      ? { to: "/accounts", search: { action: "create" }, replace: true }
      : undefined,
    word: account,
  });

  return <TablePage {...accounts.page} />;
}
