import { lazy } from "react";

import { buildDynamicImportError } from "../../data";

export const Editor = lazy(async () => {
  try {
    const { Editor } = await import("./editor.lazy");
    return { default: Editor };
  } catch (error) {
    throw buildDynamicImportError(error);
  }
});

export const EditorDisplay = lazy(async () => {
  try {
    const { EditorDisplay } = await import("./editor.lazy");
    return { default: EditorDisplay };
  } catch (error) {
    throw buildDynamicImportError(error);
  }
});

export const convertLexical = async (
  content: string | undefined | null,
  format: "html" | "text",
) => {
  const { convertLexicalToHtml, convertLexicalToText } = await import(
    "./editor.lazy"
  );

  switch (format) {
    case "html":
      return convertLexicalToHtml(content);
    case "text":
      return convertLexicalToText(content);
  }
};
