import clsx from "clsx";

export const TankFill = ({
  height,
  colour,
  markers,
}: {
  height: string;
  colour: string;
  markers?: string[];
}) => (
  <>
    <div className="h-3 bg-slate-200" />
    <div className="relative flex-1 overflow-hidden">
      <svg
        viewBox="0 0 200 200"
        preserveAspectRatio="xMidYMin slice"
        className={clsx("absolute inset-x-0 bottom-0 w-full opacity-30")}
        style={{ height, color: colour }}
      >
        <path
          d="M0,200 L0,5.551 Q60,-2.775 120,5.551 L120,200 z"
          fill="currentColor"
          opacity="0.5"
        />
        <path
          d="M0,200 L0,2.775 Q60,8.326 110,2.775 Q160,-2.775 200,2.775 L200,200 z"
          fill="currentColor"
        />
      </svg>
      {markers?.map((m, idx) => (
        <div
          key={idx}
          className="absolute h-0 w-full border-t border-dashed border-slate-400"
          style={{ bottom: m }}
        />
      ))}
    </div>
  </>
);
