import { useMatch } from "@tanstack/react-router";

import { ActionsMatch } from "../../components";
import { PlanCreateContent } from "./create";

export const PlansActionPanel = () => {
  const actions = useMatch({
    from: "/_admin/library/plans/",
    select: (s) => {
      const props = {};

      switch (s.search.action) {
        case "create":
          return { Slide: PlanCreateContent, props };
      }

      return { props };
    },
  });

  return <ActionsMatch actions={actions} />;
};
