import { IconDots } from "@tabler/icons-react";
import { ReactNode } from "react";

import { DisplayUser, userName } from "@joy/shared-utils";

import { useAuth } from "../data";
import { Avatar } from "./avatar";
import { ButtonProps } from "./button";
import { Menu } from "./menu";
import { Time } from "./time";

export type ActivityProps<I> = {
  title: string;
  action?: ReactNode;
  items: I[];
  content: (item: I) => ReactNode;
  actions?: (item: I) => ButtonProps[] | undefined;
};

export const Activity = <
  I extends { id: string; createdAt: string | Date; createdBy: DisplayUser },
>({
  title,
  action,
  items,
  content,
  actions,
}: ActivityProps<I>) => {
  const { user } = useAuth();

  return (
    <div className="min-w-0 pb-6">
      <h2 className="m-2 flex items-center justify-between px-2 text-sm lg:px-3">
        <span className="font-semibold">{title}</span>
      </h2>
      <div className="flex flex-col-reverse gap-2">
        {items.map((item) => (
          <div key={item.id} className="group relative flex gap-3">
            <div className="flex flex-col items-center gap-3 py-1">
              <Avatar user={item.createdBy} small />
              <div className="w-px flex-1 bg-gray-200 group-first:opacity-0" />
            </div>
            <div className="mb-2 flex-1">
              <div className="flex items-center justify-between gap-6">
                <div className="py-0.5 text-xs leading-6 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {userName(item.createdBy)}
                  </span>{" "}
                  commented
                </div>
                <Time
                  className="text-xs text-gray-500"
                  date={item.createdAt}
                  options={{ format: "1" }}
                />
              </div>
              <div className="flex items-start gap-2">
                <div className="flex-1">{content(item)}</div>
                <Menu
                  anchor="bottom end"
                  button={{
                    icon: IconDots,
                    kind: "menu",
                    variant: "standard",
                    className:
                      "opacity-0 group-hover:opacity-100 data-open:opacity-100",
                  }}
                  items={actions?.(item)}
                  className="text-sm"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      {action && (
        <div className="mt-2 flex gap-3">
          <Avatar user={user} small />
          <div className="flex-1">{action}</div>
        </div>
      )}
    </div>
  );
};
