import { format } from "date-fns";

import { displayPrice, specialChars } from "@joy/shared-utils";

import { TablePage, stackCell } from "../../components";
import { customerInvoicesQuery, invoice } from "../../data";
import { useTable } from "../../hooks";

export const CustomerInvoicesPanel = ({
  customerId,
}: {
  customerId: string;
}) => {
  const invoices = useTable(customerInvoicesQuery(customerId), {
    columnDefs: (c) => [
      c.accessor(
        "date",
        stackCell({
          header: "Invoice",
          value: (data) => format(data.date, "MMMM yyyy"),
          detail: (data) => [
            [
              data.linkedInvoice?.number || specialChars.endash,
              data.linkedInvoice?.status,
            ],
          ],
        }),
      ),
      c.accessor((c) => ({ count: c.fees.fees.length, more: !!c.fees.next }), {
        id: "count",
        header: "Line Items",
        cell: (c) => `${c.getValue().count}${c.getValue().more ? "+" : ""}`,
      }),
      c.accessor(
        (c) => ({
          total: c.fees.fees.reduce(
            (agg, f) =>
              agg +
              f.amount * (f.quantity - (f.prepaid || 0)) -
              (f.discount || 0),
            c.adjustment || 0,
          ),
          more: !!c.fees.next,
        }),
        {
          id: "total",
          header: "Total",
          cell: (c) =>
            `${c.getValue().more ? "Over " : ""}${displayPrice(c.getValue().total)}`,
        },
      ),
    ],
    select: (invoiceId, { month, year }) => ({
      to: "/invoices/$monthYear/$invoiceId",
      params: { monthYear: { month, year }, invoiceId },
    }),
    statLocation: "filters",
    word: invoice,
  });

  return <TablePage {...invoices.page} />;
};
