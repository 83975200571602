import { IconUsers } from "@tabler/icons-react";
import { standardSchemaValidator, useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { z } from "zod";

import { Permission, permissions, userName } from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { useAuth } from "../auth";
import { Word } from "../helpers";
import { request, requestFn } from "./base";
import {
  CreateUserDocument,
  DeleteUserDocument,
  ImpersonateUserDocument,
  UpdateUserDocument,
  UserDocument,
  UsersDocument,
} from "./operations.generated";

export const user: Word = {
  icon: IconUsers,
  article: "a",
  singular: "user",
  plural: "users",
};

export const usersQuery = (enable = true) =>
  infiniteQueryOptions({
    queryKey: ["users"],
    queryFn: ({ pageParam }) =>
      request(UsersDocument, { limit: 100, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.users.next,
    initialPageParam: "",
    enabled: enable,
    select: (data) =>
      data.pages
        .flatMap((p) => p.users.users)
        .map((u) => ({ name: userName(u, { hideEmail: true }), ...u })),
  });

export const userQuery = (id = "") =>
  queryOptions({
    queryKey: ["user", id],
    queryFn: () => request(UserDocument, { id }),
    select: (data) => data.user,
    enabled: !!id,
  });

const validation = {
  email: z.string().email("Please enter a valid email address"),
  firstName: z.string().min(1, "Please enter your first name"),
  lastName: z.string().min(1, "Please enter your last name"),
  jlteam: z.enum(permissions),
};

const createUserFn = requestFn(CreateUserDocument);

export const useCreateUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: createUserFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(userQuery(result.createUser.id).queryKey, {
        __typename: "Query",
        user: result.createUser,
      });
      await navigate({
        to: "/users/$userId",
        params: { userId: result.createUser.id },
      });
      queryClient.invalidateQueries(usersQuery());
    },
  });

  const [confirming, setConfirming] = useState(false);
  const form = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      jlteam: "user" as Permission,
      confirmed: false,
    },
    onSubmit: async ({
      value: { email, firstName, lastName, jlteam, confirmed },
    }) => {
      if (jlteam !== "user" && !confirmed) {
        setConfirming(true);
        return;
      }

      await mutateAsync({
        email,
        fields: {
          firstName,
          lastName,
          jlteam: jlteam === "user" ? undefined : jlteam,
        },
      });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { confirming, setConfirming, error, form, validators };
};

const updateUserFn = requestFn(UpdateUserDocument);

export const useUpdateUser = (userId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(userQuery(userId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateUserFn,
    onSuccess: async (result) => {
      if (result.updateUser) {
        queryClient.setQueryData(userQuery(result.updateUser.id).queryKey, {
          __typename: "Query",
          user: result.updateUser,
        });
        queryClient.setQueryData(usersQuery().queryKey, (existing) => {
          if (!existing) return undefined;

          return {
            ...existing,
            pages: existing.pages.map((p) => ({
              ...p,
              users: {
                ...p.users,
                users: p.users.users.map((u) => {
                  if (u.id === result.updateUser?.id) return result.updateUser;
                  return u;
                }),
              },
            })),
          };
        });
      }

      await navigate({ to: "/users/$userId", params: { userId } });
    },
  });

  const [confirming, setConfirming] = useState(false);
  const form = useForm({
    defaultValues: {
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      jlteam: (data.jlteam || "user") as Permission,
      confirmed: false,
    },
    onSubmit: async ({ value: { confirmed, jlteam, ...fields } }) => {
      if (jlteam !== "user" && jlteam !== data.jlteam && !confirmed) {
        setConfirming(true);
        return;
      }

      await mutateAsync({
        id: userId,
        fields: {
          ...fields,
          jlteam: jlteam === "user" ? null : jlteam,
        },
      });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { confirming, setConfirming, data, error, form, validators };
};

export const useUpdateUserProfile = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { data } = useSuspenseQuery(userQuery(user?.id || ""));
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateUserFn,
    onSuccess: async (result) => {
      if (result.updateUser) {
        queryClient.setQueryData(userQuery(result.updateUser.id).queryKey, {
          __typename: "Query",
          user: result.updateUser,
        });
        await navigate({ to: "/profile" });
      }
    },
  });

  const form = useForm({
    defaultValues: {
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
    },
    onSubmit: async ({ value }) => {
      await mutateAsync({
        id: data.id,
        fields: {
          ...value,
        },
      });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

const deleteUserFn = requestFn(DeleteUserDocument);

export const useDeleteUser = (userId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(userQuery(userId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteUserFn,
    onSuccess: async (result, { id }) => {
      if (result.deleteUser) {
        queryClient.setQueryData(usersQuery().queryKey, (existing) => {
          if (!existing) return undefined;

          return {
            ...existing,
            pages: existing.pages.map((p) => ({
              ...p,
              users: {
                ...p.users,
                users: p.users.users.filter((u) => u.id !== id),
              },
            })),
          };
        });

        await navigate({ to: "/users" });
        queryClient.removeQueries(userQuery(id));
      }
    },
  });

  return {
    data,
    error,
    isPending,
    onDelete: () => mutateAsync({ id: userId }),
  };
};

const impersonateUserFn = requestFn(ImpersonateUserDocument);

export const useImpersonateUser = (userId: string) => {
  const navigate = useNavigate();
  const { impersonate } = useAuth();
  const { data } = useSuspenseQuery(userQuery(userId));
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: impersonateUserFn,
    onSuccess: async (result) => {
      if (result.impersonateUser) {
        impersonate({
          token: result.impersonateUser.token,
          user: result.impersonateUser.user,
          expires: new Date(result.impersonateUser.expires),
        });
        navigate({ to: "/" });
      }
    },
  });

  return {
    data,
    error,
    isPending,
    onImpersonate: () =>
      mutateAsync({ id: userId, audience: window.location.hostname }),
  };
};
