import { displayPercent } from "@joy/shared-utils";

import { TablePage, dateCell } from "../../components";
import { alert, tankAlertsQuery } from "../../data";
import { useTable } from "../../hooks";

const scenarioText = {
  level_above: "Above",
  level_below: "Below",
  rate_rising: "Rose more than",
  rate_falling: "Fell more than",
};

export const TankAlertsPanel = ({ tankId }: { tankId: string }) => {
  const alerts = useTable(tankAlertsQuery(tankId), {
    canFilter: false,
    columnDefs: (c) => [
      c.accessor("scenario", {
        header: "Alert",
        cell: (c) =>
          `${scenarioText[c.getValue()]} ${displayPercent(c.row.original.expected)}`,
      }),
      c.accessor("actual", {
        header: "Value",
        cell: (c) => displayPercent(c.getValue()),
      }),
      c.accessor("updatedAt", dateCell({ header: "Occurred" })),
    ],
    initialSort: [{ id: "updatedAt", desc: true }],
    statLocation: "filters",
    word: alert,
  });

  return <TablePage {...alerts.page} />;
};
