import { createFileRoute } from "@tanstack/react-router";
import { VolumeUnits } from "convert-units/definitions/volume";
import { z } from "zod";

import { specialChars, sumVolume } from "@joy/shared-utils";

import { Grid, TablePage } from "../../components";
import { apiLevelsQuery, listLoader, tank } from "../../data";
import { useTable } from "../../hooks";
import { tankColumns } from "../../panels";

const validateSearch = z.object({
  code: z.string({ required_error: "Code not found" }),
});

export const Route = createFileRoute("/_public/qr")({
  validateSearch,
  loaderDeps: ({ search }) => {
    return { code: search.code };
  },
  loader: async ({ context, deps }) =>
    listLoader(context.queryClient, apiLevelsQuery(deps.code)),
  component: Component,
});

export function Component() {
  const deps = Route.useLoaderDeps();
  const tanks = useTable(apiLevelsQuery(deps.code), {
    columnDefs: tankColumns,
    initialSort: [{ id: "level_updatedAt", desc: true }],
    statFns: [
      (filtered) => ({
        label: "Volume",
        value:
          sumVolume(filtered, (i) => [i.level?.value, i.unit as VolumeUnits]) ||
          specialChars.endash,
      }),
      (filtered) => ({
        label: "Ullage",
        value:
          sumVolume(filtered, (i) => [
            i.level ? i.level.maximum - i.level.value : undefined,
            i.unit as VolumeUnits,
          ]) || specialChars.endash,
      }),
    ],
    initialVisibility: {
      customer: false,
      latitude: false,
      longitude: false,
    },
    statLocation: "header",
    view: {
      components: { grid: Grid },
      options: z.enum(["grid"]),
      sortable: { grid: true },
    },
    word: tank,
  });

  return <TablePage {...tanks.page} />;
}
