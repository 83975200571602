import { ReactNode } from "react";

import { useDisplayError } from "../../data";

export const ErrorTable = ({
  icon: Icon,
  error,
}: {
  icon: (props: { className: string; stroke: number }) => ReactNode;
  error: Error;
}) => {
  const { message, code } = useDisplayError(error);

  return (
    <>
      <Icon className="size-12 text-gray-400" stroke={1.6} />
      <p className="mt-1 text-sm text-gray-500">
        <span className="font-semibold">Error {code}:</span> {message}
      </p>
    </>
  );
};
