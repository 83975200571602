import { QueryClient } from "@tanstack/react-query";
import posthog from "posthog-js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { includesTeamPermission } from "@joy/shared-utils";

import { AuthClient, AuthContextValue, AuthUser } from "./context";
import { setupLiveAuth } from "./live";
import { setupLocalAuth } from "./local";

let tokenGetter: () => Promise<string | undefined> = () =>
  Promise.resolve(undefined);

export const getAuthToken = () => tokenGetter();

export const useSetupAuth = (
  queryClient: QueryClient,
): {
  loading: boolean;
  context: AuthContextValue | undefined;
} => {
  const initialized = useRef(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<AuthUser>();
  const [client, setClient] = useState<AuthClient>();
  const [impersonating, setImpersonating] = useState<boolean>(false);

  const initialize = useCallback(async () => {
    const init = is_local ? await setupLocalAuth() : await setupLiveAuth();

    if (init.user) {
      tokenGetter = init.token;
      posthog.identify(init.user.id, {
        email: init.user.email,
        jlteam: init.user.jlteam || "user",
      });
    }

    setClient(init);
    setUser(init.user);

    try {
      const impersonating = JSON.parse(
        sessionStorage.getItem("impersonating") || "",
      );
      if (impersonating) {
        setImpersonating(true);
        setUser(impersonating.user);
        tokenGetter = () => Promise.resolve(impersonating.token);
      }
    } catch {
      /* empty */
    }
  }, []);

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    const timeout = setTimeout(() => {
      setLoading(true);
    }, 1000);

    initialize()
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        clearTimeout(timeout);
        setTimeout(() => setLoading(false), 700);
      });
  }, []);

  const impersonate = useCallback(
    (props: { token: string; user: AuthUser } | undefined) => {
      sessionStorage.setItem("impersonating", JSON.stringify(props));

      if (props) {
        setImpersonating(true);
        setUser(props.user);
        tokenGetter = () => Promise.resolve(props.token);
      } else {
        setImpersonating(false);
        setUser(client?.user);
        if (client) tokenGetter = client.token;
      }

      queryClient.clear();
    },
    [client, setImpersonating],
  );

  const context = useMemo<AuthContextValue | undefined>(() => {
    if (!client) return undefined;

    return {
      user,
      impersonating,
      hasTeamPermission: includesTeamPermission(user?.jlteam),
      impersonate,
      login: async (params) => {
        await client.login(params);
      },
      logout: async () => {
        setImpersonating(false);
        sessionStorage.removeItem("impersonating");
        await client.logout();
      },
    };
  }, [client, user, impersonating, impersonate]);

  return { loading, context };
};
