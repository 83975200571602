import { IconBox, IconHistory, IconKey, IconUsers } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { brandIcons } from "../../../assets";
import {
  EntityUpdated,
  ItemHeader,
  NotFound,
  Tabs,
  pageParts,
} from "../../../components";
import { accountQuery, singleItemLoader, useAuth } from "../../../data";
import {
  AccountActionPanel,
  AccountInfoPanel,
  AccountTanksPanel,
  AccountUsersPanel,
  ApiKeysPanel,
  ChangeHistoryPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["add", "edit", "config", "schedule", "delete"]).optional(),
  entity: z.enum(["role", "apiKey"]).optional(),
  comment: z.string().optional(),
  role: z.string().optional(),
  apiKey: z.string().optional(),
});

export const Route = createFileRoute("/_user/accounts/$accountId")({
  validateSearch,
  component: Component,
  notFoundComponent,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, accountQuery(params.accountId)),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData?.name,
      },
    ],
  }),
});

export function notFoundComponent() {
  const { hasTeamPermission } = useAuth();

  return hasTeamPermission("admin") ? (
    <NotFound
      item="Account"
      action={{ to: "/accounts", search: { action: "create" } }}
      actionLabel="Add account"
    />
  ) : (
    <NotFound
      item="Account"
      fix="Head back to the home page to find what you're looking for"
      action={{ to: "/" }}
      actionLabel="Go to home page"
    />
  );
}

export function Component() {
  const accountId = Route.useParams({ select: (p) => p.accountId });
  const { data } = useSuspenseQuery(accountQuery(accountId));
  const { hasTeamPermission } = useAuth();

  return (
    <div className={pageParts.page}>
      <ItemHeader
        image={brandIcons[data.product]}
        title={data.name}
        subtitle={<EntityUpdated {...data} />}
      />
      <Tabs
        name="account"
        tabs={[
          {
            name: "Tanks",
            icon: IconBox,
            panel: <AccountTanksPanel accountId={accountId} />,
          },
          {
            name: "Users",
            icon: IconUsers,
            hidden: !data.permissions.includes("manage"),
            panel: <AccountUsersPanel accountId={accountId} />,
          },
          {
            name: "Keys & Codes",
            icon: IconKey,
            panel: (
              <ApiKeysPanel
                to="/accounts/$accountId"
                entityId={accountId}
                entityType="account"
              />
            ),
          },
          {
            icon: IconHistory,
            name: "History",
            hidden: !hasTeamPermission("admin"),
            panel: (
              <ChangeHistoryPanel entityType="account" entityId={accountId} />
            ),
          },
        ]}
        info={<AccountInfoPanel accountId={accountId} />}
      />
      <AccountActionPanel />
    </div>
  );
}
