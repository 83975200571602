import { infiniteQueryOptions } from "@tanstack/react-query";
import ky from "ky";
import { z } from "zod";

import { TankItem } from "../api/tanks.js";
import { config } from "../config.js";

const levelsSchema = z.object({
  tanks: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      unit: z.enum(["l", "gal"]).optional(),
      location: z
        .object({
          display: z.string(),
          latitude: z.number(),
          longitude: z.number(),
        })
        .optional(),
      alertLevels: z
        .array(
          z.object({
            threshold: z.number(),
            polarity: z.enum(["above", "below"]),
          }),
        )
        .optional(),
      createdAt: z.coerce.date(),
      updatedAt: z.coerce.date(),
      account: z.object({
        id: z.string(),
        name: z.string(),
        product: z.enum([
          "Colibri",
          "JoyGauge",
          "ProGauge",
          "RMSDelta",
          "SmartFill",
        ]),
      }),
      product: z
        .object({
          id: z.string(),
          label: z.string(),
          colour: z.string(),
        })
        .optional()
        .nullable(),
      level: z
        .object({
          id: z.string(),
          value: z.number(),
          maximum: z.number(),
          percent: z.number(),
          voltage: z.number().optional().nullable(),
          temperature: z.number().optional().nullable(),
          signal: z.number().optional().nullable(),
          updatedAt: z.coerce.date().or(z.string()),
        })
        .optional(),
    }),
  ),
  next: z.string().optional(),
});

export const loadLevels = async (
  code: string,
  { limit, cursor }: { limit: number; cursor: string | null },
) => {
  const url = `${config.apiUrl}/levels`;
  const result = await ky
    .get(url, {
      searchParams: {
        limit,
        ...(cursor ? { cursor } : {}),
      },
      headers: { "x-api-key": code },
    })
    .json();
  const levels = levelsSchema.parse(result);

  return levels;
};

export const apiLevelsQuery = (code: string) =>
  infiniteQueryOptions({
    queryKey: ["api-levels", code],
    queryFn: ({ pageParam }) =>
      loadLevels(code, { limit: 100, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: "",
    select: (data): TankItem[] =>
      data.pages.flatMap((p) =>
        p.tanks.map((tank) => ({
          ...tank,
          level: tank.level,
          tankProduct: tank.product,
          accountProduct: tank.account.product,
          customerId: "",
          updatedBy: {
            id: "",
            email: "",
          },
          levels: { levels: [] },
        })),
      ),
  });
