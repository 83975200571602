import { Transition } from "@headlessui/react";
import clsx from "clsx";

import { transitions } from "../transitions";

const paths = {
  infinity:
    "M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z",
  chart:
    "M15,133 C15,133 88,58 94,58 C100,58 159,95 164,95 C169,95 236,25 241,25 C246,25 283,57 283,57",
};

export type LoadingProps = {
  show: boolean;
  text?: string;
  size?: string;
  path?: keyof typeof paths;
};

export const Loading = ({
  show,
  text,
  size = "w-20",
  path = "infinity",
}: LoadingProps) => (
  <Transition show={show}>
    <div
      className={clsx(
        "absolute inset-0 z-10 flex flex-col items-center justify-center bg-gray-50/70 backdrop-blur-xs",
        transitions.fade,
      )}
    >
      <div className="flex-1" />
      <div className="flex flex-none flex-col items-center gap-4">
        <svg viewBox="0 0 300 150" className={size}>
          <path
            className="animate-pulse duration-75"
            fill="none"
            stroke="#61C270"
            strokeWidth="24"
            strokeDashoffset="0"
            d={paths[path]}
          />
          <path
            fill="none"
            stroke="#327F3E"
            strokeLinecap="round"
            strokeWidth="24"
            strokeDasharray="300 385"
            strokeDashoffset="0"
            d={paths[path]}
          >
            <animate
              attributeName="stroke-dashoffset"
              calcMode="spline"
              dur="6"
              values="685;-685"
              keySplines="0 0 1 1"
              repeatCount="indefinite"
            ></animate>
          </path>
        </svg>
        {text && (
          <p className="text-sm font-extrabold tracking-widest text-gray-500 uppercase">
            {text}
          </p>
        )}
      </div>
      <div className="flex-3" />
    </div>
  </Transition>
);

export const PendingComponent = () => <Loading key="pending" show />;
