export const isNullOrUndefined = <E>(
  e: E | null | undefined,
): e is null | undefined => e === null || e === undefined;

export const isNotNullOrUndefined = <E>(
  e: E | null | undefined,
): e is NonNullable<E> => e !== null && e !== undefined;

export const isNotNullOrUndefinedOrError = <E>(
  e: E | Error | null | undefined,
): e is E => e !== null && e !== undefined && !(e instanceof Error);

export const chunk = <T>(array: T[] | readonly T[], size: number): T[][] => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const unique = <T>(array: T[]): T[] => Array.from(new Set(array));

export const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

export const mapObject = <K extends string, V, R>(
  obj: Record<K, V>,
  fn: (value: V, key: K) => R,
): Record<K, R> => {
  const result = {} as Record<K, R>;
  for (const key in obj) {
    result[key] = fn(obj[key], key);
  }

  return result;
};

export const keys = <K extends string>(obj: Record<K, unknown>) => {
  return Object.keys(obj) as K[];
};

export const isKeyOf = <O extends object>(obj: O, key: any): key is keyof O =>
  key in obj;

export const isValueOf = <O extends object>(
  obj: O,
  value: any,
): value is O[keyof O] => Object.values(obj).includes(value);

export const isElementOf = <T>(array: T[], element: any): element is T =>
  array.includes(element);

export const entries = <K extends string, V>(obj: Record<K, V>) => {
  return Object.entries(obj) as [K, V][];
};

export const chunkString = (input: string, chunkSize: number) => {
  const length = Math.ceil(input.length / chunkSize);
  return Array.from({ length }, (_, i) =>
    input.substring(i * chunkSize, i * chunkSize + chunkSize),
  );
};

export const mapOrEmpty = <I, R, F>(
  array: I[] | null | undefined,
  fn: (i: I, idx: number) => R,
  fallback: F,
) => (array?.length ? array.map(fn) : fallback);

export const join = (
  array: string[],
  separator: string,
  finalSeparator?: string,
) => {
  if (array.length === 0) return "";
  if (array.length === 1) return array[0] || "";
  return (
    array.slice(0, -1).join(separator) +
    (finalSeparator || separator) +
    array[array.length - 1]
  );
};

export const groupBy = <T, K extends string>(
  array: T[],
  fn: (item: T) => K,
) => {
  const result = {} as Record<K, T[]>;
  for (const item of array) {
    const key = fn(item);
    result[key] = result[key] || [];
    result[key].push(item);
  }
  return result;
};

export const partition = <T>(
  array: T[] | readonly T[],
  fn: (item: T) => boolean,
) => {
  const result = [[], []] as [T[], T[]];
  for (const item of array) {
    result[fn(item) ? 0 : 1].push(item);
  }
  return result;
};
