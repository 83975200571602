import { Button, Transition } from "@headlessui/react";
import { useState } from "react";

import { diffKeys, toTitle, userName } from "@joy/shared-utils";

import { TablePage, Tree, dateCell } from "../../components";
import { ChangeEntity, change, changesQuery } from "../../data";
import { useTable } from "../../hooks";

export const ChangeHistoryPanel = ({
  entityType,
  entityId,
}: {
  entityType: ChangeEntity;
  entityId: string;
}) => {
  const changes = useTable(changesQuery(entityType, entityId), {
    canFilter: false,
    columnDefs: (c) => [
      c.accessor("action", {
        header: "Changes",
        meta: {
          className: "w-full",
        },
        cell: (c) => {
          const [display, setDisplay] = useState(false);
          const action = c.getValue();

          const label =
            action === "update"
              ? `Updated ${diffKeys(c.row.original.previous || {}, c.row.original.current || {}, ["updatedAt", "updatedBy"]).join(", ")}`
              : `${toTitle(action)}d`;
          const data =
            action === "delete"
              ? c.row.original.previous
              : c.row.original.current;

          return (
            <div>
              <Button
                className="underline decoration-dotted"
                onClick={() => setDisplay(!display)}
              >
                {label}
              </Button>
              <Transition show={display}>
                <Tree className={"mt-3"} data={data} />
              </Transition>
            </div>
          );
        },
      }),
      c.accessor(
        "updatedAt",
        dateCell({
          header: "Changed",
          suffix: (v) => `by ${userName(v.updatedBy)}`,
        }),
      ),
    ],
    initialSort: [{ id: "updatedAt", desc: true }],
    statLocation: "filters",
    word: change,
  });
  return <TablePage {...changes.page} />;
};
