import { SlideContent, TextField } from "../../components";
import { useUpdateUserProfile } from "../../data";

export const ProfileEditContent = () => {
  const { error, form, validators } = useUpdateUserProfile();

  return (
    <SlideContent
      title="Update Profile"
      description="Update your profile information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="firstName"
        validators={validators.firstName}
        children={(field) => (
          <TextField field={field} label="First name" autoFocus />
        )}
      />
      <form.Field
        name="lastName"
        validators={validators.lastName}
        children={(field) => <TextField field={field} label="Last name" />}
      />
    </SlideContent>
  );
};
