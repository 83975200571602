import { useMatch } from "@tanstack/react-router";

import { ActionsMatch } from "../../components";
import { AccountCreateContent } from "../account/create";
import { ProductCreateContent } from "../product/create";
import { ProductDeleteContent } from "../product/delete";
import { ProductEditContent } from "../product/edit";
import { RoleAddContent, RoleDeleteContent, RoleEditContent } from "../role";
import { CustomerDeleteContent } from "./delete";
import { CustomerEditContent } from "./edit";

export const CustomerActionPanel = () => {
  const actions = useMatch({
    from: "/_admin/customers/$customerId",
    select: (s) => {
      const props = {
        customerId: s.params.customerId,
        roleId: s.search.role || "",
        productId: s.search.product || "",

        entityId: s.params.customerId,
        entityType: "customer" as const,
      };

      switch (s.search.action) {
        case "add":
          if (s.search.entity === "user")
            return { Slide: RoleAddContent, props };
          if (s.search.entity === "account")
            return { Slide: AccountCreateContent, props };
          if (s.search.entity === "product")
            return { Slide: ProductCreateContent, props };
          break;
        case "delete":
          if (s.search.role) return { Modal: RoleDeleteContent, props };
          if (s.search.product) return { Modal: ProductDeleteContent, props };
          return { Modal: CustomerDeleteContent, props };
        case "edit":
          if (s.search.role) return { Slide: RoleEditContent, props };
          if (s.search.product) return { Slide: ProductEditContent, props };
          return { Slide: CustomerEditContent, props };
      }

      return { props };
    },
  });

  return <ActionsMatch actions={actions} />;
};
