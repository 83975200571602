import isEqual from "lodash/isEqual";
import { ReactNode } from "react";

import { specialChars, tw } from "@joy/shared-utils";

import { Box } from "./box";
import { LinkButtonProps } from "./button";

export const settingParts = {
  sentence: tw`my-1 text-sm`,
};

export type SettingDisplayProps<TFrom extends string, TTo extends string> = {
  title: string;
  edit?: LinkButtonProps<TFrom, TTo>;
  className?: string;
  left: ReactNode;
  right: ReactNode;
};

export const SettingDisplay = <TFrom extends string, TTo extends string>({
  title,
  edit,
  className,
  left,
  right,
}: SettingDisplayProps<TFrom, TTo>) => (
  <Box header={{ title, edit }}>
    <div className="grid grid-cols-1 gap-3 px-2 py-3 text-sm sm:grid-cols-2">
      <div className={className}>{left}</div>
      <div className={className}>{right}</div>
    </div>
  </Box>
);

export type SettingProps<D, TFrom extends string, TTo extends string> = {
  title: string;
  edit?: LinkButtonProps<TFrom, TTo>;
  className?: string;
  left: D | null | undefined;
  right: D | null | undefined;
  render: (item: D) => ReactNode;
  fallback?: ReactNode;
};

const SettingFallback = () => (
  <div className={settingParts.sentence}>{specialChars.endash}</div>
);

export const Setting = <D, TFrom extends string, TTo extends string>({
  title,
  edit,
  className,
  left,
  right,
  render,
  fallback = <SettingFallback />,
}: SettingProps<D, TFrom, TTo>) => (
  <SettingDisplay
    title={title}
    edit={edit}
    className={className}
    left={left ? render(left) : fallback}
    right={right && !isEqual(left, right) ? render(right) : null}
  />
);
