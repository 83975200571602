import {
  IconCloudDownload,
  IconSearch,
  IconSortAscendingSmallBig,
  IconSortDescendingSmallBig,
} from "@tabler/icons-react";
import {
  DefaultError,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
import {
  NavigateOptions,
  RegisteredRouter,
  useNavigate,
} from "@tanstack/react-router";
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnHelper,
  SortingState,
  Table,
  VisibilityState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ReactNode, useMemo } from "react";
import { ZodEnum, ZodType, z } from "zod";

import { isNotNullOrUndefined, specialChars, toTitle } from "@joy/shared-utils";

import {
  Button,
  ButtonContentProps,
  LinkButton,
  LinkNavigateProps,
  ListInput,
  StatProps,
  TablePageProps,
  TextInput,
  ViewComponent,
  tableParts,
} from "../components";
import { Word } from "../data";
import { useDebounce } from "./debounce";
import { filterSchema, fuzzyFilter, sortSchema } from "./filter";
import { useStorage } from "./storage";
import { useVisibleItem } from "./visible";

const emptyArray: never[] = [];

export const useTable = <
  TQueryFnData,
  TFrom extends string,
  TTo extends string,
  TSelectFrom extends string,
  TSelectTo extends string,
  TError = DefaultError,
  TData extends { id: string } = { id: string },
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
  TView extends string = "table",
  TColumnDefs extends (
    c: ColumnHelper<TData>,
    viewMode: TView,
  ) => ColumnDef<TData, any>[] = (
    c: ColumnHelper<TData>,
    viewMode: TView,
  ) => ColumnDef<TData>[],
>(
  query: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData[],
    TQueryFnData,
    TQueryKey,
    TPageParam
  >,
  {
    columnDefs,
    initialSort = [],
    initialVisibility = {},
    canFilter = true,
    select,
    download,
    create,
    statLocation,
    statFns,
    view,
    word,
  }: {
    columnDefs: TColumnDefs;
    initialSort?: SortingState;
    initialVisibility?: VisibilityState;
    canFilter?: boolean;
    select?: (
      id: string,
      data: TData,
    ) => NavigateOptions<RegisteredRouter, TSelectFrom, TSelectTo>;
    download?: (filtered: TData[]) => void;
    create?: LinkNavigateProps<TFrom, TTo> & ButtonContentProps;
    statLocation: "header" | "filters";
    statFns?: ((
      filtered: TData[],
      original: TData[] | undefined,
    ) => StatProps)[];
    view?: {
      components?: Record<TView, ViewComponent<TData>>;
      options: ZodEnum<[TView, ...TView[]]>;
      optionLabel?: (view: TView) => ReactNode;
      sortable: Partial<Record<TView, boolean>>;
    };
    word: Word;
  },
): {
  table: Table<TData>;
  page: TablePageProps<TData, TView>;
  columnFilter: {
    value: ColumnFiltersState;
    onChange: (value: ColumnFiltersState) => void;
  };
} => {
  const navigate = useNavigate();
  const {
    data,
    error,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(query);
  const [filterValue, setFilterValue] = useStorage({
    defaultValue: "",
    key: `${query.queryKey}-filter`,
    schema: filterSchema,
    storage: "session",
  });
  const [columnFilter, setColumnFilter] = useStorage({
    defaultValue: [],
    key: `${query.queryKey}-column-filter`,
    schema: z.array(
      z.object({
        id: z.string(),
        value: z.unknown(),
      }),
    ) as ZodType<ColumnFiltersState>,
    storage: "session",
  });
  const [columnSort, setColumnSort] = useStorage({
    defaultValue: initialSort,
    key: `${query.queryKey}-sort`,
    schema: sortSchema,
    storage: "local",
  });
  const [viewMode, setViewMode] = useStorage({
    defaultValue: view?.options._def.values[0] || ("table" as TView),
    key: `${query.queryKey}-view`,
    schema: view?.options || z.enum(["table"] as [TView]),
    storage: "local",
  });
  const columns = useMemo(
    () => columnDefs(createColumnHelper<TData>(), viewMode),
    [viewMode],
  );
  const table = useReactTable<TData>({
    columns,
    data: data || emptyArray,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id,
    enableRowSelection: !!select,
    globalFilterFn: fuzzyFilter,
    onSortingChange: setColumnSort,
    onGlobalFilterChange: setFilterValue,
    onColumnFiltersChange: setColumnFilter,
    onRowSelectionChange: (selected) => {
      if (select) {
        const value = typeof selected === "function" ? selected({}) : selected;
        const id = Object.keys(value)[0];
        if (id) navigate(select(id, table.getRow(id).original));
      }
    },
    state: {
      rowSelection: {},
      sorting: columnSort,
      columnVisibility: initialVisibility,
      globalFilter: filterValue,
      columnFilters: columnFilter,
    },
  });
  const sortable = useMemo(() => {
    if (!view?.sortable[viewMode]) return undefined;

    const columns = table.getAllLeafColumns().filter((c) => c.getCanSort());
    return {
      headers: Object.fromEntries(
        columns.map((column) => [
          column.id,
          column.columnDef.header?.toString(),
        ]),
      ),
      options: columns.map((c) => c.id),
    };
  }, [table.getAllLeafColumns(), view?.sortable[viewMode]]);

  const { ref, itemRef } = useVisibleItem(
    () => {
      if (hasNextPage && !isFetchingNextPage) fetchNextPage();
    },
    {
      rootMargin: "100px",
      threshold: 0,
    },
    [hasNextPage, isFetchingNextPage],
  );
  const stats = useDebounce(
    () => {
      let countText = specialChars.infinity;
      if (Array.isArray(data)) {
        let filterText = "";
        const visibleRows = table.getRowCount();
        if (visibleRows !== data.length) filterText = `${visibleRows}/`;
        const more = hasNextPage ? "+" : "";
        countText = `${filterText}${data.length}${more}`;
      }

      const filteredRows = table.getRowModel().flatRows.map((r) => r.original);

      return [
        {
          label: toTitle(word.plural),
          value: countText,
          delta: download && (
            <Button
              kind="link"
              title={`Download ${word.plural}`}
              icon={IconCloudDownload}
              onClick={() => download(filteredRows)}
            />
          ),
        },
        ...(statFns?.map((fn) => fn(filteredRows, data)) || []),
      ];
    },
    50,
    [
      Array.isArray(data) && data.length,
      hasNextPage,
      filterValue,
      columnFilter,
    ],
  );

  return {
    table,
    columnFilter: {
      value: columnFilter,
      onChange: setColumnFilter,
    },
    page: {
      actions: [
        sortable ? (
          <ListInput
            key="sort_column"
            kind="menu"
            className="min-w-44"
            onChange={(v) =>
              setColumnSort((existing) => [
                { id: v, desc: !!existing?.[0]?.desc },
              ])
            }
            options={sortable.options}
            optionLabel={(id) => sortable.headers[id]}
            value={columnSort[0]?.id}
          />
        ) : undefined,
        sortable ? (
          <ListInput
            key="sort_direction"
            kind="menu"
            onChange={(v) =>
              setColumnSort((existing) => [
                { id: existing?.[0]?.id || "", desc: v === "desc" },
              ])
            }
            options={["asc", "desc"]}
            optionLabel={(o) => {
              const Icon = {
                asc: IconSortAscendingSmallBig,
                desc: IconSortDescendingSmallBig,
              }[o];
              return <Icon className="size-6 py-0.5" aria-label={o} />;
            }}
            value={columnSort[0]?.desc ? ("desc" as const) : ("asc" as const)}
          />
        ) : undefined,
        view && view.options._def.values.length > 1 ? (
          <ListInput
            key="view"
            kind="menu"
            onChange={(v) => setViewMode(v)}
            options={view.options._def.values || []}
            optionLabel={view.optionLabel}
            value={viewMode}
          />
        ) : undefined,
        create ? (
          <LinkButton
            key="create"
            icon={word.icon}
            text={`Add ${word.singular}`}
            className="my-0.5"
            collapseText
            {...(create as any)}
          />
        ) : undefined,
      ].filter(isNotNullOrUndefined),
      filters: [
        canFilter ? (
          <TextInput
            key="filter"
            kind="menu"
            className="w-80 max-w-full"
            icon={IconSearch}
            placeholder={`Filter ${word.plural}...`}
            value={filterValue}
            onChange={(e) => setFilterValue(e.currentTarget.value)}
          />
        ) : undefined,
      ].filter(isNotNullOrUndefined),
      table: {
        table,
        error: error as Error | null,
        container: { ref, itemRef, className: tableParts.card },
        updating: isFetching,
        loading: isFetchingNextPage || isLoading,
        empty: {
          icon: word.icon,
          title: `No ${word.plural} found.`,
          description: create && (
            <>
              To get started,{" "}
              <LinkButton
                {...(create as any)}
                kind="link"
                variant="standard"
                text={
                  create.text?.toLocaleLowerCase() ||
                  `add ${word.article} ${word.singular}`
                }
              />
              .
            </>
          ),
        },
      },
      view: viewMode,
      views: view?.components,
      stats,
      statLocation,
    },
  };
};
