import posthog from "posthog-js";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import { App } from "./app";
import { config } from "./data";

if (!is_local) {
  posthog.init(config.trackingKey, {
    autocapture: true,
    capture_exceptions: true,
  });
}

const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
