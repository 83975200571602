export const teamPermissions = ["admin", "super", "god"] as const;

export type TeamPermission = (typeof teamPermissions)[number];

export const permissions = ["user", ...teamPermissions] as const;

export type Permission = (typeof permissions)[number];

export const includesTeamPermission =
  (current: TeamPermission | null | undefined) => (needed: TeamPermission) => {
    if (!current) return false;
    return teamPermissions.indexOf(current) >= teamPermissions.indexOf(needed);
  };

export const isTeamPermission = (value: any): value is TeamPermission =>
  teamPermissions.includes(value);

export const highestTeamPermission = (
  available: string[],
): TeamPermission | null =>
  teamPermissions.findLast((p) => available.indexOf(p) >= 0) || null;
