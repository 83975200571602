import { CellContext, SortingColumnDef } from "@tanstack/react-table";

import { specialChars } from "@joy/shared-utils";

import { Time } from "../time";
import { cellKinds } from "./parts";

export const CellDate = ({
  value,
  prefix,
  suffix,
}: {
  value: string | Date | null | undefined;
  prefix?: string;
  suffix?: string;
}) =>
  value ? (
    <>
      <Time
        className="hidden sm:inline"
        prefix={prefix}
        suffix={suffix}
        date={value}
      />
      <Time
        className="sm:hidden"
        prefix={prefix}
        suffix={suffix}
        date={value}
        options={{ format: "1" }}
      />
    </>
  ) : (
    specialChars.endash
  );

export const dateCell = <TData,>({
  header,
  kind = "squash",
  suffix,
  sortUndefined,
}: {
  header: string;
  kind?: keyof typeof cellKinds;
  suffix?: (v: TData) => string;
  sortUndefined?: SortingColumnDef<TData>["sortUndefined"];
}) => ({
  header,
  sortUndefined,
  meta: { className: cellKinds[kind] },
  cell: (v: CellContext<TData, string | Date | null | undefined>) => (
    <CellDate
      value={v.getValue()}
      suffix={suffix ? suffix(v.row.original) : undefined}
    />
  ),
});
