import { z } from "zod";

export const addressSchema = z
  .object({
    display: z.string({ required_error: "Please enter address" }),
    latitude: z.number({ required_error: "Please enter latitude" }),
    longitude: z.number({ required_error: "Please enter longitude" }),
  })
  .passthrough();

export type Address = z.infer<typeof addressSchema>;

export type AddressResult = { type: "result"; id: string } & Address;

export type AddressSuggestion = {
  type: "suggestion";
  display: string;
  id: string;
  session: string;
  latitude: number;
  longitude: number;
};

export type AddressLookup = AddressResult | AddressSuggestion;

export const currentLocation = () =>
  new Promise<{ latitude: number; longitude: number }>((resolve, reject) => {
    if (!navigator.geolocation) reject(new Error(`Geolocation not available`));
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        reject(new Error(error.message));
      },
      { maximumAge: 1000 * 60 * 10, enableHighAccuracy: true },
    );
  });
